import General from "configs/General"
import Loader from "engine/__Shared/Objects/Loader"

import ChangePageLimit from "engine/__Shared/Actions/onChangePageLimit"
import SearchItemByText from "engine/__Shared/Actions/onSearch"
import SortItems from "engine/__Shared/Actions/onSort"
import FilterItems from "engine/__Shared/Actions/onFilter"
import MultipleSelectItems from "engine/__Shared/Actions/onSelect"
import ChangeItemListPage from "engine/__Shared/Actions/onChangePage"
// import ChangeColumnVisiblity from "engine/__Shared/Actions/onChangeColumnVisiblity"
import ShowSoftDeletedItems from "engine/__Shared/Actions/onShowDeleted"
import onConfirmDeleteItem from "engine/__Shared/Actions/onConfirmDelete"
import Modal from "engine/__Shared/Objects/Modal"
import ObjectSubmitButton from "engine/__Shared/Objects/SubmitButton"

//** Edit Item */
import EditItemStoreChanges from "engine/DataGridTable/EditItem/StoreChanges"
import EditItemOnEscape from "engine/DataGridTable/EditItem/OnEscape"
import EditItemResetModal from "engine/DataGridTable/EditItem/ResetModal"
import EditItemConfirm from "engine/DataGridTable/EditItem/Confirm"
import EditItemCancel from "engine/DataGridTable/EditItem/Cancel"
import DeleteItems from "engine/DataGridTable/DeleteItem"

//** Add Item */
import AddItemStoreChanges from "engine/DataGridTable/AddItem/StoreChanges"
import AddItemOnEscape from "engine/DataGridTable/AddItem/OnEscape"
import AddItemResetModal from "engine/DataGridTable/AddItem/ResetModal"
import AddItemConfirm from "engine/DataGridTable/AddItem/Confirm"
import AddItemCancel from "engine/DataGridTable/AddItem/Cancel"

const ngn = {}

ngn.source = ""

ngn.collection = {
  limit: General.dataTablePageLimit,
  total: 0,
  page: 1,
  sort: [],
  filter: [],
  selected_rows: [],
  search_text: "",
  fetching: true,
  show_deleted: false,

  setCollection: null,
  setCurrentPage: null,
  setSelectedItems: null,
  loader: Loader(),
  fetchData: null,

  onChangePageLimit: ChangePageLimit.bind(ngn),
  onSearch: SearchItemByText.bind(ngn),
  onSort: SortItems.bind(ngn),
  onFilter: FilterItems.bind(ngn),
  onSelect: MultipleSelectItems.bind(ngn),
  onChangePage: ChangeItemListPage.bind(ngn),
  onShowDeleted: ShowSoftDeletedItems.bind(ngn),
  onDeleteConfirm: onConfirmDeleteItem.bind(ngn),
  // onColumnVisiblityChange: ChangeColumnVisiblity.bind(ngn),

  delete: {
    selected: null,
    onSubmit: DeleteItems.bind(ngn)
  },

  // ** callback useful for every crud operation, like to reload & fetch parent categories for options after insert new.
  callback: null
}

ngn.editItem = {
  selected_rows: [],
  loader: Loader(),
  confirm: EditItemConfirm.bind(ngn),
  onEscape: EditItemOnEscape.bind(ngn),
  cancel: EditItemCancel.bind(ngn),
  resetEditItemModal: EditItemResetModal.bind(ngn),
  storeChanges: EditItemStoreChanges.bind(ngn),
  submitButton: ObjectSubmitButton(),
  resetForm: null,
  modal: Modal(),
  setErrors : null
}

ngn.addItem = {
  selected_rows: [],
  loader: Loader(),
  confirm: AddItemConfirm.bind(ngn),
  onEscape: AddItemOnEscape.bind(ngn),
  cancel: AddItemCancel.bind(ngn),
  resetAddItemModal: AddItemResetModal.bind(ngn),
  storeChanges: AddItemStoreChanges.bind(ngn),
  submitButton: ObjectSubmitButton(),
  resetForm: null,
  modal: Modal(),
  setErrors : null
}

export default ngn