// ** Icons Imports
import { Button, Grid, Typography } from "@mui/material"
import CheckCircle from "mdi-material-ui/CheckCircle"
import { useSelector } from "react-redux"
import Store from "store"
import { ImportDataGlobalReset, ImportDataSetStep } from "store/actions"

const ImportSuccess = () => {
  const success_rows_count = useSelector(state => state.ImportData.success_rows_count)
  const handleRestartProcess = () => {
    Store.dispatch(ImportDataSetStep(0))
    Store.dispatch(ImportDataGlobalReset())
  }
  return (
    <Grid container spacing={0}>
      <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
        <CheckCircle sx={{ width: 50, height: 50, color: "success.main" }} />
        <Typography variant="h5" sx={{ mt: 3 }}>
          Import Completed
        </Typography>
        <Typography variant="body" sx={{ mt: 3, mb: 5 }}>
          Import completed! <strong>{success_rows_count}</strong> records are imported from uploaded CSV file.
        </Typography>
        <Button variant="outlined" onClick={handleRestartProcess} sx={{ mb: 5 }}>Restart the Process</Button>
      </Grid>
    </Grid>
  )
}
export default ImportSuccess