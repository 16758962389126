import Req from "requests"
import Store from "store"
import { SetPermissionCollection } from "store/actions"
import Togglers from "store/togglers"

const StoreChanges = function ({ category, access }) {
  this.addPermission.submitButton.setDisabled()
  this.addPermission.loader.showLoader()
  Req.AddPermission({ category, access })
    .then((response) => {
      this.addPermission.submitButton.setEnabled()
      let old_permissions = Object.values(
        Store.getState().Permissions.collection
      )
      const new_permissions = Togglers.PermissionsByCategory(response)
      const permissions = [...new_permissions, ...old_permissions]
      Store.dispatch(SetPermissionCollection(permissions))
      this.addPermission.resetAddPermissionModal()
      this.addPermission.loader.showLoader()
    })
    .catch((error) => {
      this.addPermission.setErrors(error)
      this.addPermission.submitButton.setEnabled()
      this.addPermission.loader.hideLoader()
    })
}

export default StoreChanges
