// ** Icon imports
import HomeOutline from "mdi-material-ui/HomeOutline"
import AccountOutline from "mdi-material-ui/AccountOutline"
import LockOutline from "mdi-material-ui/LockOutline"
import CloudUploadOutline from "mdi-material-ui/CloudUploadOutline"
import StoreMarkerOutline from "mdi-material-ui/StoreMarkerOutline"
import CogOutline from "mdi-material-ui/CogOutline"
import FileTreeOutline from "mdi-material-ui/FileTreeOutline"

import Uri from "services/Uri"

const navigation = () => {
  return [
    {
      sectionTitle: "Menus"
    },
    {
      title: "Dashboard",
      icon: HomeOutline,
      path: Uri.uDashboard()
    },
    {
      title: "Users",
      icon: AccountOutline,
      path: Uri.uUsers(),
      action: "read",
      subject: "users"
    },
    {
      title: "Stores",
      icon: StoreMarkerOutline,
      action: "read",
      subject: "stores",
      path: Uri.uStores()
    },
    {
      title: "Roles & Permissions",
      icon: LockOutline,
      children: [
        {
          title: "Roles",
          path: Uri.uRoles(),
          action: "read",
          subject: "roles",
        },
        {
          title: "Permissions",
          path: Uri.uPermissions(),
          action: "read",
          subject: "permissions",
        }
      ]
    },
    {
      title: "Import & Rollback",
      icon: CloudUploadOutline,
      children: [
        {
          title: "Import Data",
          path: Uri.uImportData(),
          action: "read",
          subject: "import",
        },
        {
          title: "Rollback Imports",
          path: Uri.uRollbackImport(),
          action: "read",
          subject: "import",
        }
      ]
    },
    {
      title: "Control Panel",
      icon: CogOutline,
      children: [
        {
          title: "Experience points",
          path: Uri.uExperiencePoints(),
          action: "read",
          subject: "experience-points"
        },
        {
          title: "Scorer ranks",
          path: Uri.uScorerRank(),
          action: "read",
          subject: "scorer-rank-settings"
        },
        {
          title: "Reservation deals",
          path: Uri.uReservationDeals(),
          action: "read",
          subject: "reservation-deal-settings"
        }
      ]
    },
    {
      title: "Categories",
      icon: FileTreeOutline,
      children: [
        {
          title: "Common Categories",
          path: Uri.uCommonCategory(),
          action: "read",
          subject: "categories"
        },
        {
          title: "Store Categories",
          path: Uri.uStoreCategory(),
          action: "read",
          subject: "categories"
        },
        {
          title: "Product Categories",
          path: Uri.uProductCategory(),
          action: "read",
          subject: "categories"
        },
        {
          title: "Blog Categories",
          path: Uri.uBlogCategory(),
          action: "read",
          subject: "categories"
        },
      ]
    },
  ]
}

export default navigation
