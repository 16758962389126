const Confirm = function (data) {
  // console.dir(this)

  // Close the modal and reset the form
  // this.addRole.resetAddRoleModal()

  // Store changes
  this.addRole.storeChanges(data)
}

export default Confirm
