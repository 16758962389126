import Req from "requests"
import Store from "store"
import { SharedModalConfirmActionHide, UsersEditDetails } from "store/actions"

const StoreChanges = function () {
  const {data, fields} = this.multipleUserEdit.multipleEditConfirm.selectedData
  Req.MultipleUserUpdate({ data, fields })
    .then(() => {
      for (let u = 0; u < data.length; u++) {
        Store.dispatch(UsersEditDetails(data[u]))
      }
      this.multipleUserEdit.resetMultipleUserEditModal()
      Store.dispatch(SharedModalConfirmActionHide())
    }).catch((error) => {
      this.multipleUserEdit.setErrors(error)
      Store.dispatch(SharedModalConfirmActionHide())
    })
}

export default StoreChanges
