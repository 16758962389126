const Confirm = function (data) {
  // console.dir(this)

  // Get the values from the form
  // this.editItem.resetEditItemModal()

  // Store changes
  this.editItem.storeChanges(data)
}

export default Confirm
