import Uri from "services/Uri"
import Comm from "services/Comm"
import Catch401 from "middlewares/Catch401"

const EnterImpersonate = async function ({id}) {

  let result = {}
  let error = {
    code: 0,
    message: ""
  }

  await Comm.request({
    url: Uri.impersonate({id}),
    method: "post"
  }).then((res) => {
    // console.dir(res)
    result.data = res.data.data
  }).catch(Catch401((err) => {
    // console.dir(err)
    error.code = err.response.status
    error.message = err.response.data.message
  }, error))

  if (error.code) {
    return Promise.reject(error)
  }

  return Promise.resolve(result)
}

export default EnterImpersonate
