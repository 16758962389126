// ** React Imports

// ** MUI Imports
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import Card from "@mui/material/Card"
import Tooltip from "@mui/material/Tooltip"
import IconButton from "@mui/material/IconButton"
import Divider from "@mui/material/Divider"
import Typography from "@mui/material/Typography"
import CardContent from "@mui/material/CardContent"
import CardActions from "@mui/material/CardActions"

// ** Icons Imports
import Check from "mdi-material-ui/Check"
import StarOutline from "mdi-material-ui/StarOutline"
import AccountConvert from "mdi-material-ui/AccountConvert"
import SquareEditOutline from "mdi-material-ui/SquareEditOutline"
import FilePlusOutline from "mdi-material-ui/FilePlusOutline"
import Email from "mdi-material-ui/Email"
import Delete from "mdi-material-ui/Delete"

// ** Custom Components
import CustomChip from "components/__Shared/Chip"
import CustomAvatar from "components/__Shared/Avatar"

// ** Utils Import
import { useSelector } from "react-redux"

import UserAvatar from "components/UserArea/Users/__Shared/UserAvatar"

import RoleColors from "configs/RoleColors"
import { useNavigate } from "react-router-dom"
import ConfirmActionModal from "components/__Shared/Modals/ConfirmActionModal"
import ModalEditUser from "components/UserArea/Users/ModalEditUser"
import { useContext } from "react"
import { AbilityContext } from "context/Can"

const GeneralInfo = ({ ngn }) => {
  // ** Hooks
  const users = useSelector((state) => state.Users)
  const userDetail = users.selected
  const navigate = useNavigate()
  const ability = useContext(AbilityContext)

  let selectedUser = {
    first_name: userDetail.first_name,
    last_name: userDetail.last_name,
    email: userDetail.email,
    gender: userDetail.gender,
    date_of_birth: new Date(userDetail.date_of_birth),
    address: userDetail.address,
    id: userDetail.id,
    roles: userDetail.roles,
    role: userDetail.roles.map((role) => role.id),
    status: userDetail.status,
    phone: userDetail.phone,
  }

  const handleEdit = () => {
    ngn.editUser.showModal(selectedUser)
  }

  const handleDelete = (id) => {
    ngn.collection.onDeleteConfirm({ id })
  }

  if (userDetail) {
    return (
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <ConfirmActionModal />
            <ModalEditUser
              ngn={ngn.editUser}
              onEscape={ngn.editUser.onEscape}
            />
            <CardContent
              sx={{
                pt: 15,
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <UserAvatar
                variant="rounded"
                marginRight={0}
                avatar={userDetail.avatar}
                fontSize={3}
                full_name={userDetail.full_name}
                id={userDetail.id}
                size={120}
              />
              <Typography variant="h6" sx={{ mb: 4 }}>
                {userDetail.full_name}
              </Typography>
              <Box>
                {userDetail.roles.map((role) => {
                  return (
                    <CustomChip
                      key={role.id}
                      skin="light"
                      size="small"
                      label={role.name}
                      color={RoleColors[role.name]}
                      sx={{
                        borderRadius: "4px",
                        ml: 1,
                        fontSize: "0.875rem",
                        textTransform: "capitalize",
                        "& .MuiChip-label": { mt: -0.25 },
                      }}
                    />
                  )
                })}
              </Box>
            </CardContent>

            <CardContent sx={{ my: 1 }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box sx={{ mr: 6, display: "flex", alignItems: "center" }}>
                  <CustomAvatar
                    skin="light"
                    variant="rounded"
                    sx={{ mr: 4, width: 35, height: 35 }}
                  >
                    <Check />
                  </CustomAvatar>
                  <Box>
                    <Typography variant="h6" sx={{ lineHeight: 1.3 }}>
                      {userDetail.experience_points}
                    </Typography>
                    <Typography variant="body2">Exp. Point</Typography>
                  </Box>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <CustomAvatar
                    skin="light"
                    variant="rounded"
                    sx={{ mr: 4, width: 35, height: 35 }}
                  >
                    <StarOutline />
                  </CustomAvatar>
                  <Box>
                    <Typography variant="h6" sx={{ lineHeight: 1.3 }}>
                      {userDetail.rank}
                    </Typography>
                    <Typography variant="body2">Rank</Typography>
                  </Box>
                </Box>
              </Box>
            </CardContent>
            <Divider sx={{ mt: 4 }} />
            <CardActions
              sx={{ display: "flex", justifyContent: "center", mb: 2 }}
            >
              {ability?.can("read", "impersonate") && (
                <Tooltip
                  title="Impersonate User"
                  placement="top"
                  sx={{ mr: 1 }}
                >
                  <IconButton
                    color="secondary"
                    aria-label="add"
                    onClick={() =>
                      ngn.collection.onImpersonate(navigate, userDetail.id)
                    }
                  >
                    <AccountConvert />
                  </IconButton>
                </Tooltip>
              )}
              {ability?.can("update", "users") && (
                <Tooltip title="Edit" placement="top" sx={{ mr: 2 }}>
                  <IconButton
                    color="primary"
                    aria-label="edit"
                    size="medium"
                    onClick={() => handleEdit()}
                  >
                    <SquareEditOutline />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip
                title="Request for Extra Information"
                placement="top"
                sx={{ mr: 2 }}
              >
                <IconButton
                  color="warning"
                  aria-label="edit"
                  size="medium"
                  onClick={() => ngn.requestUserMeta.showModal(userDetail)}
                >
                  <FilePlusOutline />
                </IconButton>
              </Tooltip>
              <Tooltip title="Resend Invitation" placement="top" sx={{ mr: 2 }}>
                <IconButton color="info" aria-label="mail" size="medium">
                  <Email />
                </IconButton>
              </Tooltip>
              <>
                {ability?.can("delete", "users") && (
                  <Tooltip title="Delete" placement="top">
                    <IconButton
                      color="error"
                      aria-label="delete"
                      size="medium"
                      onClick={() => handleDelete(userDetail.id)}
                    >
                      <Delete />
                    </IconButton>
                  </Tooltip>
                )}
              </>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    )
  } else {
    return null
  }
}

export default GeneralInfo
