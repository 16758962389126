// ** MUI Imports
import Grid from "@mui/material/Grid"
import DataGridTable from "components/UserArea/__Shared/DataGridTable"
import { AbilityContext } from "context/Can"
import { useContext } from "react"
import Uri from "services/Uri"

// ** Third Party Imports
import * as yup from "yup"

const ProductCategory = () => {

  const fields = [
    //{ value: "id", label: "Id" },
    { flex: 1, field: "name", headerName: "Name", schema: yup.string().required("Name field is required"), fieldType: "string"  },
  ]

  // ** Hooks
  const ability = useContext(AbilityContext)

  return (
    <Grid container>
      <Grid item xs={12}>
        <DataGridTable
          fields={fields}
          title="Product Categories"
          subtitle="Product Categories are hierarchical, you can create sub-categories. Each category can have child categories. By selecting parent category become a child category of selected parent category."
          source={Uri.productCategory()}
          canEdit={ability?.can("update", "categories")}
          canDelete={ability?.can("delete", "categories")}
          canAdd={ability?.can("create", "categories")}
        />
      </Grid>
    </Grid>
  )
}
export default ProductCategory
