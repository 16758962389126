const RawUriCollection = {

  // [START] ERRORS
  "notAuthorized": "/not-authorized",

  // [START] USER
  "u": "/u",
  "uID": "/u/:id",

  "uDefault": "/u/:id/dashboard",
  "default": "/dashboard",

  "uDashboard": "/u/dashboard",
  "dashboard": "/dashboard",

  "users": "/users",
  "uUsers": "/u/users",
  "uUserId": "/u/users/:id",

  "roles": "/roles",
  "uRoles": "/u/roles",

  "permissions": "/permissions",
  "uPermissions": "/u/permissions",

  "stores": "/stores",
  "uStores": "/u/stores",

  "importData": "/import-data",
  "uImportData": "/u/import-data",

  "rollback": "/rollback",
  "rollbackImport": "/rollback-import",
  "uRollbackImport": "/u/rollback-import",
  "rollbackData": "/rollback/rollback-data",
  "multiUpdateRollBack": "/users/multi-update/rollback",

  // settings

  "uExperiencePoints": "/u/settings/experience-points",
  "experiencePoints": "/settings/experience-points",

  "uScorerRank": "/u/settings/scorer-rank",
  "scorerRank": "/settings/scorer-rank",

  "uReservationDeals": "/u/settings/reservation-deal",
  "reservationDeals": "/settings/reservation-deal",

  // categories
  "uStoreCategory": "/u/categories/store",
  "storeCategory": "/categories/store",

  "uCommonCategory": "/u/categories/common",
  "commonCategory": "/categories/common",

  "uProductCategory": "/u/categories/product",
  "productCategory": "/categories/product",

  "uBlogCategory": "/u/categories/blog",
  "blogCategory": "/categories/blog",

  // [START] PUBLIC
  "home": "/",

  "passwordResets": "/password-resets",
  "passwordResetsToken": "/password-resets/:token",
  "resetPassword": "/reset-password",
  "resetPasswordSuccess": "/reset-password/success",

  "signOut": "/sign-out",
  "signUp": "/sign-up",
  "signIn": "/sign-in",

  "offline": "/offline",

  "info": "/info",

  // [START] API

  "session": "/session",
  "sessionAuth": "/session/auth",
  //"tokens": "/tokens",
  //"tokensID": "/tokens/:id",

  "usersID": "/users/:id",
  "impersonate": "/users/impersonate/:id",
  "leaveImpersonate": "/users/leave-impersonate",
  "addUser": "/users/create",
  "multiUserUpdate": "/users/multi-update",

  "addUserMeta": "/user-meta-data/create",
  "updateUserMeta": "/user-meta-data/update",
  "deleteUserMeta": "/user-meta-data/:id",

  "blobUserPhotos": "/blob/users/photos",

  "importsGetColumns": "/imports/csv-columns",
  "importsParseCSV": "/imports/parse-csv",
  "importsProcessCSV": "/imports/import-csv",

  "addPermission": "/permissions/create",
  "deletePermission": "/permissions/delete",

  "addRole": "/roles/create",
  "uEditUserID": "/u/edit/:id",
  "editUserID": "edit/:id",
  "deleteMultipleUsers": "/users/delete-multiple",
  "role": "/roles/:id",
  "storesID": "stores/:id",

  "addStore": "/stores/create"

}

export default RawUriCollection
