import { useState, useEffect } from "react"
import { Grid, Box, Button, Dialog, DialogContent, DialogTitle, Typography, CircularProgress } from "@mui/material"

// ** Third Party Imports
import * as yup from "yup"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { Input } from "components/__Shared/FormControls"
import ngn from "engine/DataGridTable"
import Submit from "components/__Shared/Input/Submit"

const ModalAddSetting = function ({ title, loading, open = false, close, fields = [], submit, addErrors }) {

  //** Init default Schema for Validation
  const [validationSchema, setValidationSchema] = useState({})

  // ** React Form Hooks
  const {
    reset,
    control,
    setError,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: {},
    mode: "onBlur",
    resolver: yupResolver(yup.object().shape(validationSchema))
  })

  useEffect(() => {
    if (open) {
      let validation = {}
      for (let f = 0; f < fields.length; f++) {
        validation[fields[f].value] = yup.string().required(`${fields[f].label} field is required`)
        if (fields[f].type === "number") {
          validation[fields[f].value] = yup.number().typeError(`${fields[f].label} field must be a number`).required(`${fields[f].label} field is required`)
        }
      }
      setValidationSchema(validation)

      // Set Errors
      if (addErrors) {
        Object.keys(addErrors).forEach((f) => {
          setError(f, { type: "manual", message: addErrors[f][0] })
        })
      }
    }
  }, [open, fields, reset, addErrors, setError])


  const onConfirm = function (data) {
    submit(data)
  }

  const onCancel = () => {
    reset()
    setValidationSchema({})
    close()
  }

  return (
    <Dialog
      scroll="body"
      fullWidth
      maxWidth="sm"
      onClose={onCancel}
      open={open}
      disableEscapeKeyDown
    >
      <DialogTitle sx={{ pt: 12, mx: "auto", textAlign: "center" }}>
        <Typography variant="h4" component="span" sx={{ mb: 2 }}>
          Add New {title}
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ pb: 12 }}>
        {loading ? (
          <Box
            sx={{
              justifyContent: "center",
              alignContent: "center",
              display: "flex",
              mt: 3,
            }}
          >
            <CircularProgress sx={{ ml: 3, mr: 3 }} size={40} />
          </Box>
        ) : (
          <Box
            component="form"
            noValidate
            autoComplete="off"
            sx={{ mt: 4 }}
            onSubmit={handleSubmit(onConfirm)}
          >
            <Grid container spacing={5}>
              {fields.map((f, i) => {
                return (
                  <Grid item xs={12} sm={12} key={f.value}>
                    <Input
                      label={f.label}
                      name={f.value}
                      control={control}
                      error={errors[f.value]}
                    />
                  </Grid>
                )
              })}
            </Grid>
            <Box
              className="demo-space-x"
              sx={{
                "&>:last-child": { mr: 0 },
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mt: 3,
              }}
            >
              <Submit ngn={ngn.addItem.submitButton} title="Submit" />
              <Button
                size="large"
                variant="outlined"
                color="secondary"
                onClick={onCancel}
              >
                Discard
              </Button>
            </Box>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default ModalAddSetting