// ** MUI Imports
import { Box, Button } from "@mui/material"
import { GridToolbarContainer } from "@mui/x-data-grid"
import { GridToolbarExport, GridToolbarColumnsButton, GridToolbarFilterButton } from "@mui/x-data-grid"
import SearchTextBox from "./SearchTextBox"
import { styled } from "@mui/material/styles"
import { useState } from "react"

// ** Icons Import
import { DeleteOutline, DeleteOffOutline } from "mdi-material-ui"

const MuiGridToolbarContainer = styled(GridToolbarContainer)(({ theme }) => ({
  paddingRight: "10px !important"
}))

const TableHeaderToolbar = ({ ngn }) => {

  const [showDeleted, setShowDeleted] = useState(ngn.collection.show_deleted)
  const onShowDeleted = () => {
    setShowDeleted(!showDeleted)
    ngn.collection.onShowDeleted(!showDeleted)
  }

  // ** Props
  return (
    <Box sx={{ display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "space-between" }}>
      <Box sx={{ display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "flex-start" }}>
        <MuiGridToolbarContainer sx={{ pt: 5, pb: 3, display: "flex", flexWrap: "wrap", alignItems: "center" }}>
          <GridToolbarColumnsButton size="small" color="secondary" variant="outlined" />
          <GridToolbarFilterButton size="small" color="secondary" variant="outlined" sx={{ ml: 2 }} />
          {/* <GridToolbarDensitySelector size="medium" color="secondary" variant="outlined" sx={{ ml: 2 }} /> */}
          <GridToolbarExport size="small" color="secondary" variant="outlined" sx={{ ml: 2 }} />
        </MuiGridToolbarContainer>
        <Box sx={{ p: 5, pb: 3, pl: 0 }}>
          <Button size="small" color="secondary" startIcon={showDeleted ? <DeleteOffOutline /> : <DeleteOutline />} onClick={onShowDeleted} variant="outlined">
            {`${showDeleted ? "Hide" : "Show"} Deleted`}
          </Button>
        </Box>
      </Box>
      <SearchTextBox ngn={ngn} />
    </Box>
  )
}

export default TableHeaderToolbar
