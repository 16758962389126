import { yupResolver } from "@hookform/resolvers/yup"
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
} from "@mui/material"
import * as yup from "yup"
import { useForm } from "react-hook-form"
import { Box } from "@mui/system"
import { useSelector } from "react-redux"
import "react-datepicker/dist/react-datepicker.css"
import {
  Input,
  RadioButtons,
  Datepicker,
  Select,
} from "components/__Shared/FormControls"
import { useEffect, useMemo, useState } from "react"
import DateFormatYMD from "utils/DateFormatYMD"
import Submit from "components/__Shared/Input/Submit"

const showErrors = (field, valueLen, min) => {
  if (valueLen === 0) {
    return `${field} field is required`
  } else if (valueLen > 0 && valueLen < min) {
    return `${field} must be at least ${min} characters`
  } else {
    return ""
  }
}

const gender_fields = [
  {
    value: "male",
    label: "Male",
  },
  {
    value: "female",
    label: "Female",
  },
]

const statusValues = [
  {
    value: "active",
    label: "Active",
  },
  {
    value: "inactive",
    label: "Inactive",
  },
  {
    value: "blocked",
    label: "Blocked",
  },
  {
    value: "on-hold",
    label: "On-Hold",
  },
]

let defaultValues = {
  first_name: "",
  last_name: "",
  phone: "",
  date_of_birth: "",
  gender: "",
  address: "",
  status: "",
  role: [],
}

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const schema = yup.object().shape({
  first_name: yup
    .string()
    .min(2, (obj) => showErrors("First name", obj.value.length, obj.min)),
  last_name: yup
    .string()
    .min(2, (obj) => showErrors("Last name", obj.value.length, obj.min)),
  phone: yup
    .string()
    .matches(phoneRegExp, "Contact number is not valid")
    .required((obj) => showErrors("Contact number", obj.value.length, obj.min)),
  date_of_birth: yup
    .string()
    .nullable(),
  address: yup
    .string()
    .nullable(),
  role: yup
    .array()
    .min(1, "At least one Role must be selected")
    .required("required"),
  status: yup.string().required("required"),
})

const ModalEditUser = function ({ ngn, onEscape }) {
  const [data, setData] = useState(null)
  const [open, setOpened] = useState(false)
  const [loading, setLoading] = useState(false)
  const allRoles = useSelector((state) => state.Roles.collection)
  const roleOptions = Object.values(allRoles).map((x) => {
    return { label: x.name, value: x.id }
  })

  const {
    reset,
    setValue,
    setError,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: useMemo(() => {
      return data
    }, [data]),
    mode: "onBlur",
    resolver: yupResolver(schema),
  })

  ngn.showModal = function (user) {
    setData(user)
    reset(user)
    setOpened(true)
  }

  useEffect(() => {
    if (open) {
      window.addEventListener("keydown", onEscape)
    } else {
      window.removeEventListener("keydown", onEscape)
    }
  }, [open, onEscape])

  ngn.resetForm = function () {
    reset()
    ngn.submitButton.reset()
  }

  ngn.setErrors = function (error) {
    if (error.code > 0) {
      const { message, data } = error
      Object.keys(data).forEach((f) => {
        setError(f, {
          type: "manual",
          message: message,
        })
      })
      ngn.submitButton.setDisabled()
    }
  }

  ngn.loader.showLoader = function () {
    setLoading(true)
  }

  ngn.loader.hideLoader = function () {
    setLoading(false)
  }

  ngn.hideModal = function () {
    setOpened(false)
  }

  const onConfirm = function (data) {
    data.date_of_birth = DateFormatYMD(data.date_of_birth)
    ngn.confirm(data)
  }

  const onCancel = function (ev) {
    // console.dir(ev)
    ngn.cancel()
  }

  return (
    <Dialog
      scroll="body"
      fullWidth
      maxWidth="sm"
      onClose={onCancel}
      open={open}
      disableEscapeKeyDown
    >
      <DialogTitle sx={{ pt: 12, mx: "auto", textAlign: "center" }}>
        <Typography variant="h4" component="span" sx={{ mb: 2 }}>
          Edit User
        </Typography>
        <Typography variant="body2">
          Update user's Account details and Personal Info.
        </Typography>
      </DialogTitle>
      <DialogContent>
        {loading ? (
          <Box
            sx={{
              justifyContent: "center",
              alignContent: "center",
              display: "flex",
              mt: 3,
            }}
          >
            <CircularProgress sx={{ ml: 3, mr: 3 }} size={40} />
          </Box>
        ) : (
          <Box
            component="form"
            noValidate
            autoComplete="off"
            sx={{ mt: 4 }}
            onSubmit={handleSubmit(onConfirm)}
          >
            <Grid container spacing={5}>
              <Grid item xs={12}>
                <Typography variant="body2" sx={{ fontWeight: 600 }}>
                  1. Account Details
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Input
                  label="Email"
                  name="email"
                  placeholder="Enter Email"
                  defaultValue={defaultValues.email}
                  control={control}
                  error={errors.email}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Select
                  label="Roles"
                  name="role"
                  defaultValue={data ? data.role : []}
                  multiple={true}
                  setValue={setValue}
                  control={control}
                  error={errors.role}
                  options={roleOptions}
                  placeholder="Select Role"
                />
              </Grid>
              <Grid item xs={12}>
                <Select
                  label="Status"
                  name="status"
                  defaultValue={data?.status}
                  multiple={false}
                  setValue={setValue}
                  control={control}
                  error={errors.status}
                  options={statusValues}
                />
              </Grid>
              <Grid item xs={12}>
                <Divider sx={{ mb: 0 }} />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" sx={{ fontWeight: 600 }}>
                  2. Personal Info
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Input
                  label="First Name"
                  name="first_name"
                  setValue={setValue}
                  control={control}
                  error={errors.first_name}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Input
                  label="Last Name"
                  name="last_name"
                  control={control}
                  error={errors.last_name}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Datepicker
                  label="Date of Birth"
                  name="date_of_birth"
                  control={control}
                  error={errors.date_of_birth}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <RadioButtons
                  label="Gender"
                  name="gender"
                  control={control}
                  error={errors.gender}
                  options={gender_fields}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Input
                  label="Contact Number"
                  placeholder="Enter Contact Number"
                  name="phone"
                  control={control}
                  error={errors.phone}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Input
                  multiline={true}
                  rows={2}
                  label="Address"
                  name="address"
                  control={control}
                  error={errors.address}
                />
              </Grid>
            </Grid>
            <Box
              className="demo-space-x"
              sx={{
                "&>:last-child": { mr: 0 },
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mt: 3,
              }}
            >
              <Submit ngn={ngn.submitButton} title="Edit Role" />
              <Button
                size="large"
                variant="outlined"
                color="secondary"
                onClick={onCancel}
              >
                Discard
              </Button>
            </Box>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default ModalEditUser
