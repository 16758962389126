// ** MUI Imports
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  Divider,
  Paper,
  IconButton,
  CardHeader,
} from "@mui/material"

import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"

// ** Custom Components
import CustomChip from "components/__Shared/Chip"
import ActivityLogColumns from "components/UserArea/Users/__Shared/Tables/ActivityLogColumns"

// ** Hooks Import
import { useSelector } from "react-redux"

import StatusColors from "configs/StatusColors"
import { DatabaseRefresh } from "mdi-material-ui"
import ngn from "engine/Users"
import ConfirmActionModal from "components/__Shared/Modals/ConfirmActionModal"
import Time2TimeAgo from "utils/Time2TimeAgo"
import GetActivityMessage from "./GetActivityMessage"

const UserDetails = () => {
  // ** Hooks
  const user = useSelector((state) => state.Users)
  const userDetail = user.selected
  //const [userMetaData, setUserMetaData] = useState([])
  const handleRollBack = (id, model_id) => {
    ngn.collection.onMultipleUpdateRollBackConfirm({ id, model_id})
  }

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <Card>
          <CardHeader title="Personal Details" titleTypographyProps={{ variant: "h6" }} />
          <Divider sx={{ m: 0 }} />
          <CardContent>
            <ConfirmActionModal />
            <Box sx={{ pt: 0, pb: 1 }}>
              <Box sx={{ display: "flex", mb: 2.7 }}>
                <Typography
                  variant="subtitle2"
                  sx={{ mr: 2, color: "text.primary" }}
                >
                  Email:
                </Typography>
                <Typography variant="body2">{userDetail.email}</Typography>
              </Box>
              <Box sx={{ display: "flex", mb: 2.7 }}>
                <Typography
                  variant="subtitle2"
                  sx={{ mr: 2, color: "text.primary" }}
                >
                  Gender:
                </Typography>
                <Typography variant="body2" sx={{ textTransform: "capitalize" }}>{userDetail.gender}</Typography>
              </Box>
              <Box sx={{ display: "flex", mb: 2.7 }}>
                <Typography
                  variant="subtitle2"
                  sx={{ mr: 2, color: "text.primary" }}
                >
                  Date of Birth:
                </Typography>
                <Typography variant="body2">{userDetail.date_of_birth}</Typography>
              </Box>
              <Box sx={{ display: "flex", mb: 2.7 }}>
                <Typography
                  variant="subtitle2"
                  sx={{ mr: 2, color: "text.primary" }}
                >
                  Status:
                </Typography>
                <CustomChip
                  skin="light"
                  size="small"
                  label={userDetail.status}
                  color={StatusColors[userDetail.status]}
                  sx={{
                    height: 20,
                    fontSize: "0.75rem",
                    fontWeight: 500,
                    borderRadius: "5px",
                    textTransform: "capitalize",
                  }}
                />
              </Box>
              <Box sx={{ display: "flex", mb: 2.7 }}>
                <Typography
                  sx={{ mr: 2, fontWeight: 500, fontSize: "0.875rem" }}
                >
                  Address:
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ textTransform: "capitalize" }}
                >
                  {userDetail.address}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", mb: 0 }}>
                <Typography
                  sx={{ mr: 2, fontWeight: 500, fontSize: "0.875rem" }}
                >
                  Contact:
                </Typography>
                <Typography variant="body2">{userDetail.phone}</Typography>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      {
        userDetail.meta_data.length > 0 && <Grid item xs={12}>
          <Card>
            <CardHeader title="User's Extra Information" titleTypographyProps={{ variant: "h6" }} />
            <Divider sx={{ m: 0 }} />
            <CardContent>
              <Box sx={{ pt: 0, pb: 1 }}>
                {userDetail.meta_data.map((meta_data, index) => {
                  return (
                    <Box key={meta_data.id} sx={{ display: "flex", flexDirection: "column", mb: userDetail.meta_data.length === (index + 1) ? 0 : 2.7 }}>
                      <Typography
                        variant="subtitle2"
                        sx={{ mr: 2, color: "text.primary" }}
                      >
                        {meta_data.meta_title}
                      </Typography>
                      {meta_data.meta_value && <Typography variant="body2">{meta_data.meta_value}</Typography>}
                      {!meta_data.meta_value && <Typography variant="body2"><em>Not answered</em></Typography>}
                    </Box>
                  )
                })}
              </Box>
            </CardContent>
          </Card>
        </Grid>
      }
      <Grid item xs={12}>
        <Card>
          <CardHeader title="Activity Logs" titleTypographyProps={{ variant: "h6" }} />
          <Divider sx={{ m: 0 }} />

          <TableContainer component={Paper}>
            <Table size="small" sx={{ width: "100vd" }}>
              <TableHead>
                <TableRow>
                  {ActivityLogColumns.map((activity) => {
                    return (
                      <TableCell key={activity.field} sx={{ width: `${activity.minWidth}` }}>
                        {activity.headerName}
                      </TableCell>
                    )
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {userDetail.activity_log.map((row) => {
                  return (
                    <TableRow key={row.id}>
                      <TableCell key={row.old_value}>
                        {GetActivityMessage(row)}
                      </TableCell>
                      <TableCell key={row.created_at}>
                        {Time2TimeAgo(new Date(row.created_at).getTime())}
                      </TableCell>
                      <TableCell key={row.model_id}>
                        <IconButton color="error" onClick={() => handleRollBack(row.id, row.model_id)}>
                          <DatabaseRefresh />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </Grid>
    </Grid>
  )
}
export default UserDetails
