import { Button } from "@mui/material"
import { useState } from "react"

const Submit = function ({ ngn, title = "", fullWidth = false }) {

  const [disabled, setDisabled] = useState(false)

  ngn.setDisabled = () => {
    setDisabled(true)
  }
  ngn.setEnabled = () => {
    setDisabled(false)
  }
  ngn.reset = () => {
    setDisabled(false)
  }

  return (
    <Button disabled={disabled} fullWidth={fullWidth} size="large" type="submit" variant="contained">
      {title}
    </Button>
  )
}


export default Submit
