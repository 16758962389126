import { ReactComponent as SplashLogo } from "images/alltrue-logo.svg"
import CircularProgress from "@mui/material/CircularProgress"
import BlankLayout from "components/__Shared/BlankLayout"

// ** MUI Components
import {
  Box,
} from "@mui/material"

const InitLoading = function () {
  return (
    <BlankLayout>
      <Box className="content-center">
        <Box sx={{ mb: 8, display: "flex", flexDirection: "column", height: "100%", alignItems: "center", justifyContent: "center" }}>
          <SplashLogo className="logo-sign" />
          <br />
          <CircularProgress size={40} />
        </Box>
      </Box>
    </BlankLayout>
  )
}

export default InitLoading
