import React, { useState } from "react"
import Proptype from "prop-types"
import { FormControl, IconButton, InputAdornment, TextField } from "@mui/material"
import { Controller } from "react-hook-form"
import FormFieldMessage from "components/__Shared/FormFieldMessage"

// ** Icons Imports
import EyeOutline from "mdi-material-ui/EyeOutline"
import EyeOffOutline from "mdi-material-ui/EyeOffOutline"

function Input(props) {
  const [visible, setVisible] = useState(false)
  const toggleVisible = () => setVisible(!visible)
  const {
    label,
    name,
    control,
    required,
    error,
    placeholder,
    change,
    blur,
    type,
    rows,
    multiline,
    isIcon,
    position,
    iconStart,
    inputProps,
    helperText,
    variant
  } = props

  return (
    <FormControl fullWidth>
      <Controller
        name={name}
        control={control}
        rules={{ required: required }}
        render={({ field: { value, onChange } }) => {
          return <TextField
            type={
              type === "password" ? (visible === false ? "password" : "text") : type
            }
            rows={rows}
            multiline={multiline}
            variant={variant}
            value={value ? value : ""}
            helperText={helperText}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={type === "password" ? {
              autoComplete: "new-password",
              form: {
                autoComplete: "off",
              },
            } : inputProps}
            label={label}
            InputProps={{
              [position === "start" ? "startAdornment" : "endAdornment"]:
                type === "password" && !isIcon ? (
                  <IconButton
                    edge="end"
                    onMouseDown={e => e.preventDefault()}
                    onClick={toggleVisible}
                  >
                    {visible ? <EyeOutline /> : <EyeOffOutline />}
                  </IconButton>
                ) : isIcon ? (
                  <InputAdornment position={position}>
                    {props.children}
                  </InputAdornment>
                ) : (
                  <></>
                ),
              startAdornment: (
                <InputAdornment position="start">{iconStart}</InputAdornment>
              ),
            }}
            onBlur={blur}
            onChange={(e) => {
              if (change) {
                change(e)
              }
              return onChange(e)
            }}
            error={Boolean(error)}
            placeholder={placeholder}
          />
        }}
      />
      <FormFieldMessage color="error.main" error={error} />
    </FormControl>
  )
}

Input.defaultProps = {
  label: "",
  name: "input-text",
  control: {},
  required: false,
  error: null,
  placeholder: "",
  variant: "outlined",
  disable: false,
  change: () => { },
  blur: () => { },
  position: "end",
  defaultValue: ""
}

Input.prototype = {
  label: Proptype.string,
  name: Proptype.string,
  control: Proptype.object,
  required: Proptype.bool,
  error: Proptype.object,
  placeholder: Proptype.string,
  onChange: Proptype.func,
  rows: Proptype.string,
  helperText: Proptype.string,
  type: Proptype.oneOf(["number", "text", "password", "textarea"]),
  disable: Proptype.bool,
  variant: Proptype.oneOf(["standard", "outlined", "filled"]),
  inputProps: Proptype.any,
  defaultValue: Proptype.string,
}

export default Input
