import { RequestsSetAction } from "store/actions"
import Store from "store"
import Togglers from "store/togglers"

const OnImpersonate = function (navigate, user_id = null) {
  if(user_id === null){
    user_id = this.collection.selected_rows[0]
  }
  Togglers.ImpersonateUserReset()
  Store.dispatch(RequestsSetAction("ImpersonateUser", {id: user_id, navigate: navigate}))
}
export default OnImpersonate