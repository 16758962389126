import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormHelperText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material"
import { useEffect, useState } from "react"

// ** Third Party Imports
import * as yup from "yup"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { InformationOutline } from "mdi-material-ui"
import { useSelector } from "react-redux"
import StringFn from "utils/StringFn"
import { Input } from "components/__Shared/FormControls"
import Submit from "components/__Shared/Input/Submit"

// ** Default Values
const defaultValues = {
  name: "",
  permissions: [],
}

const schema = yup.object().shape({
  name: yup.string().required(),
  permissions: yup.array()
})

const ModalAddRole = function ({ ngn, onEscape }) {
  const [open, setOpened] = useState(false)
  const [loading, setLoading] = useState(false)
  const [permissionAccess, setPermissionAccess] = useState([])

  const permissionsData = useSelector((state) => state.Permissions)

  // ** Hooks
  const {
    reset,
    setValue,
    setError,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
    mode: "onBlur",
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    if (open) {
      window.addEventListener("keydown", onEscape)
    } else {
      window.removeEventListener("keydown", onEscape)
    }
  }, [open, onEscape])

  ngn.resetForm = function () {
    reset()
    setPermissionAccess([])
    ngn.submitButton.reset()
  }

  ngn.loader.showLoader = function () {
    setLoading(true)
  }

  ngn.loader.hideLoader = function () {
    setLoading(false)
  }

  ngn.setErrors = function (errors) {
    if(errors && Object.keys(errors).length > 0){
      Object.keys(errors).forEach((e)=>{
        setError(e, {type:"manual", message: errors[e]})
      })
    }
  }

  ngn.showModal = function () {
    setOpened(true)
  }

  ngn.hideModal = function () {
    setOpened(false)
  }

  const onConfirm = function (data) {
    ngn.confirm(data)
  }

  const onCancel = function (ev) {
    // console.dir(ev)
    ngn.cancel()
  }

  const handleChangeAccess = (checkedAccess) => {
    const newAccesses = permissionAccess?.includes(checkedAccess)
      ? permissionAccess?.filter((name) => name !== checkedAccess)
      : [...(permissionAccess ?? []), checkedAccess]
    setPermissionAccess(newAccesses)
    return newAccesses
  }

  const handleSelectAll = (event) => {
    let access = []
    if (event.target.checked) {
      Object.values(permissionsData).forEach((permission) => {
        Object.values(permission).forEach((permission) => {
          permission.permission_ids.forEach((id) => access.push(id))
        })
      })
    } else {
      access = []
    }
    setPermissionAccess(access)
    setValue("permissions", access)
  }

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      onClose={onCancel}
      open={open}
      disableEscapeKeyDown
    >
      <DialogTitle sx={{ pt: 12, mx: "auto", textAlign: "center" }}>
        <Typography variant="h4" component="span" sx={{ mb: 2 }}>
          Add Role
        </Typography>
        <Typography variant="body2">Set Role Permissions</Typography>
      </DialogTitle>
      <DialogContent sx={{ p: { xs: 6, sm: 12 } }}>
        {loading ? (
          <Box
            sx={{
              justifyContent: "center",
              alignContent: "center",
              display: "flex",
              mt: 3,
            }}
          >
            <CircularProgress sx={{ ml: 3, mr: 3 }} size={40} />
          </Box>
        ) : (
          <Box
            component="form"
            sx={{ mt: 4 }}
            onSubmit={handleSubmit(onConfirm)}
          >
            <Box sx={{ my: 4 }}>
              <Input
                label="Role Name"
                placeholder="Enter Role Name"
                name="name"
                control={control}
                error={errors.name}
              />
            </Box>
            <Typography variant="h6">Role Permissions</Typography>
            {errors.permissions && (
              <FormHelperText sx={{ color: "error.main" }}>
                Please select at least one permission
              </FormHelperText>
            )}
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ pl: "0 !important" }}>
                      <Box
                        sx={{
                          display: "flex",
                          fontSize: "0.875rem",
                          alignItems: "center",
                          textTransform: "capitalize",
                        }}
                      >
                        Administrator Access
                        <Tooltip
                          placement="top"
                          title="Allows a full access to the system"
                        >
                          <InformationOutline
                            sx={{ ml: 1, fontSize: "1rem" }}
                          />
                        </Tooltip>
                      </Box>
                    </TableCell>
                    <TableCell colSpan={3}>
                      <FormControlLabel
                        label="Select All"
                        control={
                          <Checkbox onChange={handleSelectAll} size="small" />
                        }
                        sx={{
                          "& .MuiTypography-root": {
                            textTransform: "capitalize",
                          },
                        }}
                      />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.values(permissionsData.collection).map(
                    (permission, index) => {
                      return (
                        <TableRow
                          key={index}
                          sx={{
                            "& .MuiTableCell-root:first-of-type": { pl: 0 },
                          }}
                        >
                          <TableCell
                            sx={{
                              fontWeight: 600,
                              color: (theme) =>
                                `${theme.palette.text.primary} !important`,
                            }}
                          >
                            {
                              new StringFn(permission.category)
                                .removeHyphen()
                                .capitalize().result
                            }
                          </TableCell>
                          {permission.access.map((access, index) => {
                            let permission_id = permission.permission_ids[index]
                            return (
                              <TableCell
                                key={index}
                                colSpan={
                                  index === permission.access.length - 1
                                    ? 5 - permission.access.length
                                    : 0
                                }
                              >
                                <FormControlLabel
                                  control={
                                    <Controller
                                      name="permissions"
                                      render={({ field: { onChange } }) => {
                                        return (
                                          <Checkbox
                                            checked={permissionAccess.includes(
                                              permission_id
                                            )}
                                            onChange={() =>
                                              onChange(
                                                handleChangeAccess(
                                                  permission_id
                                                )
                                              )
                                            }
                                          />
                                        )
                                      }}
                                      control={control}
                                    />
                                  }
                                  label={
                                    new StringFn(access).capitalize().result
                                  }
                                />
                              </TableCell>
                            )
                          })}
                        </TableRow>
                      )
                    }
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Box
              className="demo-space-x"
              sx={{
                "&>:last-child": { mr: 0 },
                display: "flex",
                justifyContent: "center",
                mt: 8,
              }}
            >
              <Submit ngn={ngn.submitButton} title="Create Role" />
              <Button
                size="large"
                variant="outlined"
                color="secondary"
                onClick={onCancel}
              >
                Discard
              </Button>
            </Box>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  )
}
export default ModalAddRole
