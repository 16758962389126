import SortRoles from "engine/__Shared/Actions/onSort"
import SearchRoles from "engine/__Shared/Actions/onSearch"
import onConfirmDeleteRole from "engine/RolesAndPermission/Roles/Actions/onConfirmDelete"
import EditRole from "engine/__Shared/Actions/onEdit"
import GetRoles from "engine/RolesAndPermission/Roles/GetRoles"
import DeleteRole from "engine/RolesAndPermission/Roles/DeleteRole"

//** Add Role */
import AddRoleStoreChanges from "engine/RolesAndPermission/Roles/AddRole/StoreChanges"
import AddRoleResetModal from "engine/RolesAndPermission/Roles/AddRole/ResetModal"
import AddRoleConfirm from "engine/RolesAndPermission/Roles/AddRole/Confirm"
import AddRoleCancel from "engine/RolesAndPermission/Roles/AddRole/Cancel"
import AddRoleOnEscape from "engine/RolesAndPermission/Roles/AddRole/OnEscape"

//** Edit Role */
import EditRoleStoreChanges from "engine/RolesAndPermission/Roles/EditRole/StoreChanges"
import EditRoleResetModal from "engine/RolesAndPermission/Roles/EditRole/ResetModal"
import EditRoleConfirm from "engine/RolesAndPermission/Roles/EditRole/Confirm"
import EditRoleCancel from "engine/RolesAndPermission/Roles/EditRole/Cancel"
import EditRoleOnEscape from "engine/RolesAndPermission/Roles/EditRole/OnEscape"

import ObjectSubmitButton from "engine/__Shared/Objects/SubmitButton"
import Loader from "engine/__Shared/Objects/Loader"

const ngn = {}

ngn.collection = {
  search_text: "",
  sort: [],
  fetching: false,

  onSort: SortRoles.bind(ngn),
  onEdit: EditRole.bind(ngn),
  onDeleteConfirm: onConfirmDeleteRole.bind(ngn),
  onSearch: SearchRoles.bind(ngn),

  fetchData: GetRoles.bind(ngn),

  delete: {
    selected: null,
    onSubmit: DeleteRole.bind(ngn)
  }
}

ngn.addRole = {
  loader: Loader(),
  storeChanges: AddRoleStoreChanges.bind(ngn),
  resetAddRoleModal: AddRoleResetModal.bind(ngn),
  confirm: AddRoleConfirm.bind(ngn),
  cancel: AddRoleCancel.bind(ngn),
  onEscape: AddRoleOnEscape.bind(ngn),
  submitButton: ObjectSubmitButton(),
  resetForm: null,
  showModal: null,
  hideModal: null,
  setErrors: null
}

ngn.editRole = {
  selected: null,
  loader: Loader(),
  storeChanges: EditRoleStoreChanges.bind(ngn),
  resetEditRoleModal: EditRoleResetModal.bind(ngn),
  confirm: EditRoleConfirm.bind(ngn),
  cancel: EditRoleCancel.bind(ngn),
  onEscape: EditRoleOnEscape.bind(ngn),
  submitButton: ObjectSubmitButton(),
  resetForm: null,
  showModal: null,
  hideModal: null,
  setErrors: null
}

export default ngn