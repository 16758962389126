import Store from "store"
import { UsersSetPermissions } from "store/actions"
import GroupBy from "utils/GroupBy"
import StringFn from "utils/StringFn"

const UserPermissionsByCategory = function () {

  const user = Store.getState().User

  const permission_group = GroupBy(user.permissions, "category")

  let acl_permissions = []
  for (var permission_category in permission_group) {
    const permissions = permission_group[permission_category].map((permission) => {
      return permission.name.slice(0, permission.name.indexOf("-"))
    })
    const permission_name = new StringFn(permission_category).removeHyphen().capitalize().result
    acl_permissions.push({
      category_name: permission_name,
      category: permission_category,
      access: permissions
    })
  }
  Store.dispatch(UsersSetPermissions(acl_permissions))
}

export default UserPermissionsByCategory
