import { useEffect, useState } from "react"
import General from "configs/General"
import Alert from "@mui/material/Alert"

const AlertMessage = function ({ type = "success", message = null }) {

  const [showMessage, setShowMessage] = useState(true)

  useEffect(function () {
    setTimeout(function () {
      setShowMessage(false)
    }, General.statusMessageLifeCycle)
  }, [])

  if (!showMessage || message === null) {
    return null
  }

  return (
    <Alert sx={{ mb: 8 }} severit={type}>{message}</Alert>
  )
}

export default AlertMessage
