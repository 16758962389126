import Req from "requests"
import Store from "store"
import { SharedModalConfirmActionHide } from "store/actions"

const RollbackImport = function () {
  const {import_type, id} = this.collection.rollback.selected

  Req.RollbackImport({ import_type, id })
    .then((response) => {
      this.collection.fetchData()
      Store.dispatch(SharedModalConfirmActionHide())
    })
    .catch(function (err) {
      Store.dispatch(SharedModalConfirmActionHide())
    })
}
export default RollbackImport
