import Req from "requests"
import Store from "store"
import { SetRolesCollection, SharedModalErrorMessageShow } from "store/actions"

const GetRoles = function () {
  const search = this.collection.search_text
  const sort = this.collection.sort

  this.collection.fetching = true
  Req.GetRoles({ search, sort })
    .then((response) => {
      Store.dispatch(SetRolesCollection(response.collection))
      this.collection.fetching = false
    })
    .catch((error) => {
      Store.dispatch(SharedModalErrorMessageShow(error.message))
      this.collection.fetching = false
    })
}

export default GetRoles