import * as actionType from "store/actions/actionTypes"
import StoreTemplate from "store/StoreTemplate"

import DefaultToObject from "store/defaults/DefaultToObject"
import DefaultToArray from "store/defaults/DefaultToArray"

const container = DefaultToObject()

const defaultState = function () {
  return {
    collection: defaultUsers(),
    selected: defaultUser()
  }
}

const defaultUser = function () {
  return null
}

const defaultUsers = function () {
  return DefaultToArray()
}

const Users = StoreTemplate(defaultState(), container)

container[actionType.ACTION_USERS_SET_COLLECTION] = function (state, payload) {
  // const collection = payload.collection
  // for (let index in payload.collection) {
  //   collection[`${payload.collection[index].id}`] = payload.collection[index]
  // }
  state.collection = payload.collection

  return {
    ...state
  }
}

container[actionType.ACTION_USERS_SET_SELECTED_USER] = function (state, payload) {

  state.selected = payload
  state.collection = defaultUsers()

  return {
    ...state
  }
}

container[actionType.ACTION_USERS_SET_SELECTED_USER_METADATA] = function (state, payload) {

  state.selected.meta_data = payload.meta_data

  return {
    ...state
  }
}

container[actionType.ACTION_USERS_EDIT_DETAILS] = function (state, payload) {

  state.collection = state.collection.map(user => user.id === payload.details.id ? { ...user, ...payload.details } : user)

  return {
    ...state,
  }

}

container[actionType.ACTION_USERS_REMOVE_USER] = function (state, payload) {
  // if (state.collection[payload.id]) {
  //   delete state.collection[payload.id]
  // }
  state.collection = state.collection.filter(x => x.id !== payload.id)
  return {
    ...state
  }
}

container[actionType.ACTION_USERS_REMOVE_MULTIPLE_USERS] = function (state, payload) {

  /** Updating Collection */
  payload.ids.forEach((id) => {
    state.collection = state.collection.filter(x => x.id !== id)
  })

  return {
    ...state
  }
}

container[actionType.ACTION_USERS_ADD_NEW_USER] = function (state, payload) {
  state.collection.unshift(payload.user)
  return {
    ...state
  }
}

container[actionType.ACTION_USERS_EDIT_USER] = function (state, payload) {
  state.collection = Object.values(state.collection).map(user => {
    let newObj = user
    if (user.id === payload.id) {
      newObj = { ...user, ...payload }
    }
    return newObj
  })

  return {
    ...state
  }
}

container[actionType.ACTION_USERS_MULTIPLE_UPDATE_ROLLBACK] = function(state, payload) {

  state.collection = Object.values(state.collection).map(user => {
    let newObj = user
    if (user.id === payload.user.id) {
      newObj = { ...user, ...payload }
    }
    return newObj
  })

  return {
    ...state
  }
}

container[actionType.ACTION_USERS_GLOBAL_RESET] = function () {
  return defaultState()
}

export default Users
