import Req from "requests"
import Store from "store"
import { SharedModalConfirmActionHide, SharedModalErrorMessageShow, UsersRemoveUser } from "store/actions"

const DeleteUser = function () {
  const { id } = this.collection.delete.selected
  this.collection.fetching = true
  Req.DeleteUser({ id })
    .then(() => {
      Store.dispatch(UsersRemoveUser(id))
      Store.dispatch(SharedModalConfirmActionHide())
      this.collection.selected_rows = []
      this.collection.fetching = false
    }).catch((error) => {
      Store.dispatch(SharedModalConfirmActionHide())
      Store.dispatch(SharedModalErrorMessageShow(error.message))
      this.collection.fetching = false
    })
}

export default DeleteUser