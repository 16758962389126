import Comm from "services/Comm"
import Auth from "services/Auth"
import Uri from "services/Uri"
import Store from "store"
import { AuthSetSigned } from "store/actions"
import Submit from "components/__Shared/Input/Submit"

// ** MUI Components
import { Box, Checkbox, FormControl, Grid } from "@mui/material"
import MuiFormControlLabel from "@mui/material/FormControlLabel"
import { styled } from "@mui/material/styles"

// ** Third Party Imports
import * as yup from "yup"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

import { Link } from "react-router-dom"
import { Input } from "components/__Shared/FormControls"

// ** Styled Components
const FormControlLabel = styled(MuiFormControlLabel)(({ theme }) => ({
  "& .MuiFormControlLabel-label": {
    fontSize: "0.875rem",
    color: theme.palette.text.secondary,
  },
}))

// ** Default Values
const defaultValues = {
  password: "",
  email: "",
  remember_me: false,
}

const schema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().min(5).required(),
})

const Form = function ({ ngn }) {
  const {
    control,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
    mode: "onBlur",
    resolver: yupResolver(schema),
  })

  const onSubmitHandler = (data) => {
    const { email, password, remember_me } = data
    ngn.submit.setDisabled()
    // api request
    Comm.request({
      url: Uri.sessionAuth(),
      method: "post",
      data: {
        email,
        password,
        remember_me,
      },
    })
      .then((res) => {
        // console.dir(res)
        if (res.data?.data?.user_id) {
          Auth.setUserID(res.data.data.user_id)
          Store.dispatch(AuthSetSigned())
        }
      })
      .catch((error) => {
        // console.dir(error)
        if (error.response?.data?.errors) {
          const errors = error.response.data.errors
          if (errors.email) {
            setError("email", {
              type: "manual",
              message: errors.email[0],
            })
          }
          if (errors.password) {
            setError("password", {
              type: "manual",
              message: errors.password[0],
            })
          }
        } else if (error.response?.data?.message) {
          setError("password", {
            type: "manual",
            message: error.response.data.message,
          })
        }
        ngn.submit.setEnabled()
      })
  }

  return (
    <form
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit(onSubmitHandler)}
    >
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {/* Email Field  */}
          <Input
            label="Email"
            name="email"
            placeholder="admin.alltrue@gmail.com"
            control={control}
            error={errors.email}
          />
        </Grid>
        <Grid item xs={12}>
          {/* Password Field  */}
          <Input
            type="password"
            label="Password"
            name="password"
            placeholder="Enter Password"
            control={control}
            error={errors.password}
          />
        </Grid>
        <Grid item xs={12}>
          {/* Remember me, Forgot password link & Login button  */}
          <Box
            sx={{
              mb: 4,
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            <FormControl>
              <Controller
                name="remember_me"
                control={control}
                rules={{ required: false }}
                render={({ field: { value, onChange, onBlur } }) => (
                  <FormControlLabel
                    label="Remember Me"
                    control={
                      <Checkbox
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                      />
                    }
                    sx={{
                      "& .MuiFormControlLabel-label": { color: "text.primary" },
                    }}
                  />
                )}
              />
            </FormControl>
            <Link
              to={Uri.passwordResets()}
              variant="body2"
              sx={{ color: "primary.main" }}
            >
              Forgot Password?
            </Link>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Submit fullWidth ngn={ngn.submit} title="Log In" />
        </Grid>
      </Grid>
    </form>
  )
}

export default Form
