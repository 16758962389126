import Req from "requests"
import Store from "store"
import { ImportDataSetColumns } from "store/actions"
import Togglers from "store/togglers"

const GetDatabaseColumns = function (type) {
  this.type = type
  this.execute.loading.showLoader()
  Req.GetColumnList({ type }).then((response) => {
    this.execute.loading.hideLoader()
    const columns = Togglers.RenameColumnImportData(type, response.data)
    Store.dispatch(ImportDataSetColumns(columns))
  }).catch((err) => {
    // console.log(err)
    this.execute.loading.hideLoader()
  })
}

export default GetDatabaseColumns