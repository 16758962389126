import General from "configs/General"

import SortStores from "engine/__Shared/Actions/onSort"
import FilterStores from "engine/__Shared/Actions/onFilter"
import SearchStoresByText from "engine/__Shared/Actions/onSearch"
import ChangeStoreListPageLimit from "engine/__Shared/Actions/onChangePageLimit"
import ChangeStoreListPage from "engine/__Shared/Actions/onChangePage"
import onConfirmDeleteStore from "engine/Stores/Actions/onConfirmDelete"
import ShowSoftDeletedStores from "engine/Stores/Actions/onShowDeleted"
import GetStoresCollection from "engine/Stores/Collection/GetStoreCollection"
import DeleteStore from "engine/Stores/DeleteStore"

//** Add new User */
import AddStoreChanges from "engine/Stores/AddStore/StoreChanges"
import AddStoreResetModal from "engine/Stores/AddStore/ResetModal"
import AddStoreConfirm from "engine/Stores/AddStore/Confirm"
import AddStoreCancel from "engine/Stores/AddStore/Cancel"
import AddStoreOnEscape from "engine/Stores/AddStore/OnEscape"

import Loader from "engine/__Shared/Objects/Loader"
import ObjectSubmitButton from "engine/__Shared/Objects/SubmitButton"

const ngn = {}

ngn.collection = {
  limit: General.dataTablePageLimit,
  total: 0,
  page: 1,
  sort: [],
  filter: [],
  selected_rows: [],
  search_text: "",
  fetching: true,
  show_deleted: false,

  setCurrentPage: null,

  onSort: SortStores.bind(ngn),
  onShowDeleted: ShowSoftDeletedStores.bind(ngn),
  onFilter: FilterStores.bind(ngn),
  onChangePageLimit: ChangeStoreListPageLimit.bind(ngn),
  onChangePage: ChangeStoreListPage.bind(ngn),
  onSearch: SearchStoresByText.bind(ngn),
  onDeleteConfirm: onConfirmDeleteStore.bind(ngn),

  delete: {
    selected: null,
    onSubmit: DeleteStore.bind(ngn)
  },

  fetchData: GetStoresCollection.bind(ngn),
}

ngn.addStore = {
  loader: Loader(),
  storeChanges: AddStoreChanges.bind(ngn),
  resetAddStoreModal: AddStoreResetModal.bind(ngn),
  confirm: AddStoreConfirm.bind(ngn),
  cancel: AddStoreCancel.bind(ngn),
  onEscape: AddStoreOnEscape.bind(ngn),
  setErrors: null,
  submitButton: ObjectSubmitButton(),
  resetForm: null,
  showModal: null,
  hideModal: null,
}

export default ngn
