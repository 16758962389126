import Store from "store"
import { SharedModalConfirmActionShow } from "store/actions"

const onConfirmRollback = function(selected) {
  this.collection.rollback.selected = selected
  Store.dispatch(
    SharedModalConfirmActionShow(
      "Import will be rollback. Are you sure?",
      this.collection.rollback
    )
  )
}
export default onConfirmRollback