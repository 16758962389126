import * as actionType from "store/actions/actionTypes"
import StoreTemplate from "store/StoreTemplate"
import DefaultToObject from "store/defaults/DefaultToObject"

const container = {}

const defaultState = function () {
  return {
    collection: defaultRoles(),
  }
}

const defaultRoles = function () {
  return DefaultToObject()
}

const Roles = StoreTemplate(defaultState(), container)

container[actionType.ACTION_ROLES_SET_DATA] = function (state, payload) {
  const collection = {}
  for(let index in payload.collection){
    collection[`${payload.collection[index].id}`] = payload.collection[index]
  }

  state.collection = {
    ...collection
  }

  return {
    ...state
  }
}

container[actionType.ACTION_ROLES_EDIT_ROLE] = function (state, payload) {

  state.collection = Object.values(state.collection).map(r => {
    let newObj = r
    if (r.id === payload.id) {
      newObj = { ...r, ...payload }
    }
    return newObj
  })

  return {
    ...state
  }
}

container[actionType.ACTION_ROLES_GLOBAL_RESET] = function () {
  return defaultState()
}

export default Roles
