import Catch401 from "middlewares/Catch401"
import Comm from "services/Comm"
import Uri from "services/Uri"

const UpdateUser = async function ({
  id,
  first_name,
  last_name,
  phone,
  date_of_birth,
  gender,
  address,
  status,
  role,
}) {
  let result = {}
  let error = {
    code: 0,
    message: `User ${id} couldn't be updated`,
  }
  await Comm.request({
    url: Uri.usersID({ id }),
    method: "patch",
    data: {
      first_name,
      status,
      last_name,
      phone,
      date_of_birth,
      gender,
      address,
      role,
    },
  })
    .then((res) => {
      result = res.data.data
    })
    .catch(
      Catch401((err) => {
        error.code = err.response.status
        error.message = err.response.data.message
        error.data = err.response.data.errors
        console.log(err)
      })
    )
  if (error.code > 0) {
    return Promise.reject(error)
  }
  return Promise.resolve(result)
}

export default UpdateUser
