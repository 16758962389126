import Store from "store"
import { SharedModalConfirmActionShow } from "store/actions"

const onConfirmMultipleEditRollBack = function({id, model_id}) {
  this.collection.multipleEditRollBack.selected.id = id
  this.collection.multipleEditRollBack.selected.model_id = model_id
  Store.dispatch(
    SharedModalConfirmActionShow(
      `Change No. ${id} will be rollbacked for user ${model_id}. Are you sure?`,
      this.collection.multipleEditRollBack
    )
  )
}
export default onConfirmMultipleEditRollBack