const RevertRenameColumnImportData = function (type, column) {
  if (type === "stores") {
    if (column === "category_id") {
      return "category"
    }
    if (column === "subcategory_id") {
      return "subcategory"
    }
  }
  return column
}
export default RevertRenameColumnImportData