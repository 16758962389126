import Comm from "services/Comm"
import Uri from "services/Uri"
import Catch401 from "middlewares/Catch401"

const DeleteUserMeta = async function ({ id }) {
  let result = {}
  let error = {
    code: 0,
    message: "User meta couldn't be deleted!",
  }

  await Comm.request({
    url: Uri.deleteUserMeta({id}),
    method: "delete",
  })
    .then((res) => {
      // console.dir(res)
      if (res.status === 204) {
        // ...
      } else {
        error.code = 404
      }
    })
    .catch(
      Catch401((err) => {
        // console.dir(err)
        error.code = 404
        error.message = err.response.data.message
      }, error)
    )

  if (error.code > 0) {
    return Promise.reject(error)
  }

  return Promise.resolve(result)
}

export default DeleteUserMeta
