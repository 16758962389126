import { useEffect } from "react"

// ** MUI Imports
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"

// ** Third Party Components
import toast from "react-hot-toast"
import { useSelector } from "react-redux"
import { useTheme } from "@mui/material/styles"
import UserAvatar from "components/__Shared/UserAvatar"
import { RequestsSetAction } from "store/actions"
import Store from "store"
import Togglers from "store/togglers"
import { useNavigate } from "react-router-dom"

const ImpersonateToastLayout = ({ id }) => {
  const user_details = useSelector(state => state.User)
  const navigate = useNavigate()
  const handleCloseToast = () => {
    toast.dismiss(id)
    Togglers.ImpersonateUserReset()
    Store.dispatch(RequestsSetAction("LeaveImpersonateUser", { navigate: navigate }))
  }
  return (
    <Box sx={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
      <Box sx={{ display: "flex", alignItems: "center", mr: 1 }}>
        <UserAvatar size={40} marginRight={2} variant="" color="error" avatar={user_details.avatar} id={user_details.id} full_name={`${user_details.first_name} ${user_details.last_name}`} />
        <Box>
          <Typography sx={{ fontWeight: 500 }}>Caution!</Typography>
          <Typography variant="caption">You are impersonating <strong>{user_details.full_name}</strong>!</Typography>
        </Box>
      </Box>
      <Button size="small" color="error" onClick={handleCloseToast}>
        Stop
      </Button>
    </Box>
  )
}

const ImpersonateToast = () => {

  const impersonate = useSelector(state => state.Auth.impersonate)
  const theme = useTheme()

  useEffect(() => {
    if (impersonate === true) {
      toast.dismiss()
      setTimeout(function () {
        toast(
          t => (
            <ImpersonateToastLayout id={t.id} />
          ),
          {
            style: {
              minWidth: "400px",
              color: theme.palette.primary.main,
              border: `1px solid ${theme.palette.error.main}`
            },
            duration: Infinity,
            position: "bottom-center",
            id: "impersonate-toast"
          }
        )
      }, 100)
    }
  }, [impersonate, theme])
  return null
}

export default ImpersonateToast
