// ** React Import
import { useEffect, useRef, useState } from "react"
import { styled } from "@mui/material/styles"
import Box from "@mui/material/Box"
// ** Components
import AppBar from "components/UserArea/__Shared/Layout/AppBar"

// ** Theme Config Import
import themeConfig from "configs/themeConfig"
import Navigation from "./Navigation"

// ** Navigation Imports
import VerticalNavItems from "components/UserArea/MainMenu"

// ** Styled Component
const VerticalLayoutWrapper = styled("div")({
  height: "100%",
  display: "flex"
})

const MainContentWrapper = styled(Box)({
  flexGrow: 1,
  minWidth: 0,
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column"
})

const ContentWrapper = styled("main")(({ theme }) => ({
  flexGrow: 1,
  width: "100%",
  padding: theme.spacing(6),
  transition: "padding .25s ease-in-out",
  [theme.breakpoints.down("sm")]: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4)
  }
}))

const Layout = props => {
  // ** Props
  const { hidden, children, settings, saveSettings } = props

  // ** Vars
  const { skin, navHidden, contentWidth } = settings
  const { navigationSize, collapsedNavigationSize } = themeConfig
  const navWidth = navigationSize
  const navigationBorderWidth = skin === "bordered" ? 1 : 0
  const collapsedNavWidth = collapsedNavigationSize

  // ** States
  const [navHover, setNavHover] = useState(false)
  const [navVisible, setNavVisible] = useState(false)

  // ** Ref
  const isCollapsed = useRef(settings.navCollapsed)

  // ** Toggle Functions
  const toggleNavVisibility = () => setNavVisible(!navVisible)

  useEffect(() => {
    if (hidden) {
      if (settings.navCollapsed) {
        saveSettings({ ...settings, navCollapsed: false, layout: "vertical" })
        isCollapsed.current = true
      } else {
        saveSettings({ ...settings, layout: "vertical" })
      }
    } else {
      if (isCollapsed.current) {
        saveSettings({ ...settings, navCollapsed: true, layout: settings.lastLayout })
        isCollapsed.current = false
      } else {
        saveSettings({ ...settings, layout: settings.lastLayout })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hidden])

  return (
    <VerticalLayoutWrapper>
      {
        navHidden && themeConfig.layout === "vertical" && !(navHidden && settings.lastLayout === "horizontal") ? null : (
          <Navigation
            navWidth={navWidth}
            navHover={navHover}
            navVisible={navVisible}
            setNavHover={setNavHover}
            setNavVisible={setNavVisible}
            collapsedNavWidth={collapsedNavWidth}
            toggleNavVisibility={toggleNavVisibility}
            navigationBorderWidth={navigationBorderWidth}
            verticalNavItems={VerticalNavItems()}
            {...props}
          />
        )
      }
      <MainContentWrapper className="layout-content-wrapper">
        <AppBar toggleNavVisibility={toggleNavVisibility} {...props} />
        <ContentWrapper
          className="layout-page-content"
          sx={{
            ...(contentWidth === "boxed" && {
              mx: "auto",
              "@media (min-width:1440px)": { maxWidth: 1440 },
              "@media (min-width:1200px)": { maxWidth: "100%" }
            })
          }}
        >
          {children}
        </ContentWrapper>
      </MainContentWrapper>
    </VerticalLayoutWrapper>
  )
}

export default Layout
