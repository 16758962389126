import Req from "requests"
import Store from "store"
import { RollbackSetCollection } from "store/actions"

const GetRollBackCollection = function () {
  const page = this.collection.page
  const sort = this.collection.sort
  const limit = this.collection.limit
  const filter = this.collection.filter
  const search_text = this.collection.search_text
  const total = this.collection.total

  Req.GetRollbackImports({ page, limit, search_text, filter, sort, total })
    .then((response) => {
      Store.dispatch(RollbackSetCollection(response.data))
      this.collection.total = response.total
    })
    .catch(function (err) {
      console.log(err)
    })
}
export default GetRollBackCollection
