import {ReactComponent as Logo} from "images/alltrue-logo.svg"
import Uri from "services/Uri"
import {useNavigate} from "react-router-dom"

const AllTrueLogo = function () {

  const navigate =  useNavigate()

  const logoOnClickHandler = function () {
    navigate(Uri.uPersonal(), {replace: true})
  }

  return (
    <Logo className="logo" alt="AllTrue Admin" onClick={logoOnClickHandler} style={{"cursor": "pointer"}} />
  )
}

export default AllTrueLogo
