import * as actionType from "store/actions/actionTypes"
import DefaultToObject from "store/defaults/DefaultToObject"
import StoreTemplate from "store/StoreTemplate"

const container = DefaultToObject()

const defaultState = function () {
  return {
    users: {
      columns: null
    },
  }
}

const DataGrid = StoreTemplate(defaultState(), container)

container[actionType.ACTION_DATAGRID_SET_USER_COLUMNS] = function (state, payload) {
  state.users.columns = payload.columns
  return {
    ...state,
  }
}

container[actionType.ACTION_DATAGRID_GLOBAL_RESET] = function (state) {
  return defaultState()
}

export default DataGrid
