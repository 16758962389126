import { combineReducers } from "redux"
import Shared from "store/reducers/Shared"
import Init from "store/reducers/Init"
import Auth from "store/reducers/Auth"
import Requests from "store/reducers/Requests"
import User from "store/reducers/User"
import Users from "store/reducers/Users"
import Roles from "store/reducers/Roles"
import Permissions from "store/reducers/Permissions"
import ImportData from "store/reducers/ImportData"
import Rollback from "store/reducers/Rollback"
import Stores from "store/reducers/Stores"
import DataGrid from "store/reducers/DataGrid"

const CollectionOfReducers = combineReducers({
  Shared: Shared,
  Requests: Requests,
  User: User,
  Auth: Auth,
  Init: Init,
  Users: Users,
  Roles: Roles,
  Permissions: Permissions,
  ImportData: ImportData,
  Rollback: Rollback,
  Stores: Stores,
  DataGrid: DataGrid
})

export default CollectionOfReducers
