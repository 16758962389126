const Breadcrumbs = {
  MuiBreadcrumbs: {
    styleOverrides: {
      li: {
        "& > .MuiLink-root": {
          textDecoration: "none"
        }
      }
    }
  }
}
export default Breadcrumbs