import { useSelector } from "react-redux"

import Store from "store"
import { SharedModalErrorMessageHide } from "store/actions"
import { Box, Button, Dialog, DialogContent, DialogTitle, Typography } from "@mui/material"

const ErrorMessageModal = function () {

  const { opened, message, callback } = useSelector(state => state.Shared.modals.error_message)

  const closeModalHandler = function (ev) {
    Store.dispatch(SharedModalErrorMessageHide())
    if (typeof callback === "function") {
      callback()
    }
  }

  return (
    <Dialog fullWidth maxWidth="xs" onClose={closeModalHandler} open={opened}>
      <DialogTitle sx={{ pt: 10, mx: "auto", textAlign: "center" }}>
        <Typography variant="h4" component="span" color="error">
          Error
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ mx: "auto" }}>
        <Box>
          <Typography variant="p" sx={{ mb: 4 }}>
            {message}
          </Typography>
          <Box className="demo-space-x" sx={{justifyContent: "center", display: "flex", mb: 5}}>
            <Button size="large" variant="outlined" color="secondary" onClick={closeModalHandler}>
              Discard
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default ErrorMessageModal
