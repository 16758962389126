import Req from "requests"
import Store from "store"
import { StoresSetCollection } from "store/actions"

const StoreChanges = function ({ name, category_id, subcategory_id, email, phone, website, address }) {
  this.addStore.submitButton.setDisabled()
  this.addStore.loader.showLoader()
  Req.AddStore({ name, category_id, subcategory_id, email, phone, website, address })
    .then((response) => {
      let old_stores = Store.getState().Stores.collection
      const stores = [response, ...old_stores]
      Store.dispatch(StoresSetCollection(stores))
      this.addStore.cancel()
    }).catch((error) => {
      this.addStore.setErrors(error.data)
      this.addStore.loader.hideLoader()
      this.addStore.submitButton.setEnabled()
    })
}

export default StoreChanges
