// ** MUI Imports
import Box from "@mui/material/Box"
import IconButton from "@mui/material/IconButton"
// ** Components
import UserDropdown from "./UserDropdown"
// import NotificationDropdown from "./NotificationDropdown"

// ** Icons Imports
import MenuIcon from "mdi-material-ui/Menu"

const AppBarContent = props => {
  // ** Props
  const { settings, hidden, toggleNavVisibility } = props

  return (
    <Box sx={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
      <Box className="actions-left" sx={{ mr: 2, display: "flex", alignItems: "center" }}>
        {hidden ? (
          <IconButton color="inherit" sx={{ ml: -2.75 }} onClick={toggleNavVisibility}>
            <MenuIcon />
          </IconButton>
        ) : null}
      </Box>
      <Box className="actions-right" sx={{ display: "flex", alignItems: "center" }}>
        {/* <NotificationDropdown settings={settings} /> */}
        <UserDropdown settings={settings} />
      </Box>
    </Box>
  )
}

export default AppBarContent
