import Catch401 from "middlewares/Catch401"
import Comm from "services/Comm"
import Uri from "services/Uri"

const AddPermission = async function ({category, access}) {

  let result = {}
  let error = {
    code: 0,
    type: "",
    data: {},
    message: "Permission couldn't be added!",
  }

  await Comm.request({
    url: Uri.addPermission(),
    method: "post",
    data: {
      category,
      access
    }
  }).then((res) => {
    // console.dir(res)
    result = res.data.data
  }).catch(Catch401((err) => {
    // console.log(err)
    error.code = err.response.status
    error.message = err.response.data.errors
    error.data = Object.keys(err.response.data.errors)
  }))

  if (error.code > 0) {
    return Promise.reject(error)
  }

  return Promise.resolve(result)
}

export default AddPermission
