import Store from "store"
import { SharedModalConfirmActionShow } from "store/actions"

const onMultipleEditConfirm = function(data) {
  this.multipleUserEdit.multipleEditConfirm.selectedData = data
  Store.dispatch(
    SharedModalConfirmActionShow(
      `${data.data.length} Users will be Updated. Are you sure?`,
      this.multipleUserEdit.multipleEditConfirm
    )
  )
}
export default onMultipleEditConfirm