import Comm from "services/Comm"
import Catch401 from "middlewares/Catch401"
import Uri from "services/Uri"

const UpdateUserMeta = async function (data) {
  let result = {}
  let error = {
    code: 0,
    type: "",
    data: [],
    message: "User meta couldn't be updated",
  }
  await Comm.request({
    url: Uri.updateUserMeta(),
    method: "post",
    data: data,
  })
    .then((res) => {
      result = res.data.data
    })
    .catch(
      Catch401((err) => {
        error.code = err.response.status
        error.message = err.response.data.message
        error.data = err.response.data.errors
        // console.log(err)
      })
    )
  if (error.code > 0) {
    return Promise.reject(error)
  }
  return Promise.resolve(result)
}
export default UpdateUserMeta