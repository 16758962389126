import React, { useContext } from "react"
import { Navigate } from "react-router-dom"
import Uri from "services/Uri"
import { AbilityContext } from "context/Can"
import { useSelector } from "react-redux"

const ProtectedRoute = ({ component: Component, action, subject, ...rest }) => {
  const impersonate = useSelector(state => state.Auth.impersonate)
  const ability = useContext(AbilityContext)

  if (ability.can(action, subject)) {
    return <Component />
  }

  const redirectTo = {
    pathname: impersonate ? Uri.uDashboard() : Uri.notAuthorized(),
    state: {
      from: rest.location
    }
  }

  return <Navigate to={redirectTo} replace={true} />
}

export default ProtectedRoute
