import { Grid, Typography } from "@mui/material"
import { useSelector } from "react-redux"

import FieldMappingForm from "./FieldMappingForm"

const StepTwo = ({ngn}) => {
  const csv_data = useSelector(state => state.ImportData.csv_data)
  return (
    <Grid container spacing={0}>
      {
        csv_data ? <>
          <Grid item xs={12}>
            <Typography variant="body2">
              <strong>{csv_data.collection.length}</strong> records are ready to upload. Please map csv columns below and click on Import button.
            </Typography>
            <FieldMappingForm ngn={ngn} headings={csv_data.headings} db_columns={csv_data.db_columns} />
          </Grid>
        </>
          :
          <Typography variant="body2">
            Unable to import CSV file.
          </Typography>
      }
    </Grid>
  )
}
export default StepTwo