import { Box, Typography } from "@mui/material"
import { Link } from "react-router-dom"
import TableRowOptions from "components/UserArea/Users/__Shared/Tables/TableRowOptions"
import UserAvatar from "components/UserArea/Users/__Shared/UserAvatar"

// ** Custom Components Imports
import CustomChip from "components/__Shared/Chip"
import Uri from "services/Uri"

// ** Icons Imports
import ShieldAccount from "mdi-material-ui/ShieldAccount"

// ** Vars
import RoleColors from "configs/RoleColors"
import StatusColors from "configs/StatusColors"

const TableColumns = [
  // {
  //   flex: 0.02,
  //   field: "__check__",
  //   filterable: false,
  //   sortable: false,
  // },
  {
    flex: 0.2,
    minWidth: 150,
    field: "full_name",
    headerName: "Name",
    renderCell: ({ row }) => {
      const { id, first_name, last_name, avatar } = row
      return (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <UserAvatar avatar={avatar} id={id} full_name={`${first_name} ${last_name}`} />
          <Box sx={{ display: "flex", alignItems: "flex-start", flexDirection: "column" }}>
            <Typography
              to={Uri.uUserId({ "id": id })}
              noWrap
              component={Link}
              variant="subtitle2"
              sx={{ color: "text.primary", textDecoration: "none" }}
            >
              {`${first_name} ${last_name}`}
            </Typography>
          </Box>
        </Box>
      )
    }
  },
  {
    flex: 0.1,
    minWidth: 80,
    field: "status",
    headerName: "Status",
    renderCell: ({ row }) => {
      return (
        <CustomChip
          skin="light"
          size="small"
          label={row.status}
          color={StatusColors[row.status]}
          sx={{ textTransform: "capitalize", "& .MuiChip-label": { lineHeight: "18px" } }}
        />
      )
    }
  },
  {
    flex: 0.08,
    minWidth: 220,
    field: "email",
    headerName: "Email",
    renderCell: ({ row }) => {
      return (
        <Typography noWrap variant="body2">
          {row.email}
        </Typography>
      )
    }
  },
  {
    flex: 0.1,
    minWidth: 220,
    field: "roles",
    headerName: "Roles",
    renderCell: ({ row }) => {
      return (
        <>
          {row.roles.map((role, index) => (
            <CustomChip
              icon={<ShieldAccount />}
              key={index}
              skin="light"
              size="small"
              color={RoleColors[role.name]}
              label={role.name}
              sx={{ textTransform: "capitalize", "& .MuiChip-label": { lineHeight: "18px" } }}
            />
          ))}
        </>
      )
    }
  },
  {
    flex: 0.08,
    minWidth: 150,
    field: "phone",
    headerName: "Phone",
    renderCell: ({ row }) => {
      return (
        <Typography noWrap variant="body2">
          {row.phone}
        </Typography>
      )
    }
  },
  {
    flex: 0.04,
    filterable: false,
    minWidth: 90,
    sortable: false,
    field: "actions",
    headerName: "Actions",
    renderCell: ({ row }) => <TableRowOptions id={row.id} />
  }
]

export default TableColumns