import { IconButton, Menu, MenuItem } from "@mui/material"

// ** Icons Imports
import EyeOutline from "mdi-material-ui/EyeOutline"
import DotsVertical from "mdi-material-ui/DotsVertical"
import PencilOutline from "mdi-material-ui/PencilOutline"
import DeleteOutline from "mdi-material-ui/DeleteOutline"
import { Link } from "react-router-dom"
import Uri from "services/Uri"
import { styled } from "@mui/material/styles"
import { useContext, useState } from "react"
import { AbilityContext } from "context/Can"
import ngn from "engine/Users"
import { useSelector } from "react-redux"

// ** Styled component for the link inside menu
const MenuItemLink = styled(Link)(({ theme }) => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  textDecoration: "none",
  padding: theme.spacing(1.5, 4),
  color: theme.palette.text.primary,
}))

const TableRowOptions = ({ id }) => {
  // ** Hooks
  const ability = useContext(AbilityContext)
  const users = useSelector((state) => state.Users.collection)

  // ** State
  const [anchorEl, setAnchorEl] = useState(null)
  const rowOptionsOpen = Boolean(anchorEl)

  const handleRowOptionsClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleRowOptionsClose = () => {
    setAnchorEl(null)
  }

  const handleDelete = () => {
    ngn.collection.onDeleteConfirm({ id })
    handleRowOptionsClose()
  }

  const handleEdit = () => {
    ngn.editUser.selected_rows[0] = id
    const selectedUser = users.filter((u) => ngn.editUser.selected_rows.includes(u.id))[0]
    selectedUser.date_of_birth = new Date(selectedUser.date_of_birth)
    selectedUser.role = selectedUser.roles.map((role) => role.id)
    ngn.editUser.showModal(selectedUser)
    handleRowOptionsClose()
  }

  return (
    <>
      <IconButton size="small" onClick={handleRowOptionsClick}>
        <DotsVertical />
      </IconButton>
      <Menu
        keepMounted
        anchorEl={anchorEl}
        open={rowOptionsOpen}
        onClose={handleRowOptionsClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{ style: { minWidth: "8rem" } }}
      >
        {ability?.can("read", "users") ? (
          <MenuItem sx={{ p: 0 }}>
            <MenuItemLink to={Uri.uUserId({ id: id })}>
              <EyeOutline fontSize="small" sx={{ mr: 2 }} />
              View
            </MenuItemLink>
          </MenuItem>
        ) : null}
        {ability?.can("update", "users") ? (
          <MenuItem onClick={() => handleEdit()}>
            <PencilOutline fontSize="small" sx={{ mr: 2 }} />
            Edit
          </MenuItem>
        ) : null}
        {ability?.can("delete", "users") ? (
          <MenuItem onClick={() => handleDelete()}>
            <DeleteOutline fontSize="small" sx={{ mr: 2 }} />
            Delete
          </MenuItem>
        ) : null}
      </Menu>
    </>
  )
}

export default TableRowOptions
