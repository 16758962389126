import Comm from "services/Comm"
import Catch401 from "middlewares/Catch401"


const StoreChanges = function (data) {

  const { id, ...rest } = data
  this.editItem.loader.showLoader()
  this.editItem.submitButton.setDisabled()


  Comm.request({
    url: `${this.source}/${id}`,
    method: "patch",
    data: rest
  }).then(() => {
    this.collection.fetchData()
    this.editItem.loader.hideLoader()
    this.editItem.resetEditItemModal()
    this.editItem.modal.hide()
    if(this.collection.callback){
      this.collection.callback()
    }
  }).catch(Catch401((err) => {
    this.editItem.loader.hideLoader()
    this.editItem.submitButton.setEnabled()
    this.editItem.setErrors(err.response.data.errors)
  }))

}
export default StoreChanges
