import Req from "requests"
import Store from "store"
import { SetRolesCollection } from "store/actions"

const StoreChanges = function ({ name, permissions }) {
  this.addRole.submitButton.setDisabled()
  this.addRole.loader.showLoader()
  Req.AddRole({ name, permissions })
    .then((response) => {
      let old_roles = Object.values(Store.getState().Roles.collection)
      const roles = [...old_roles, response]
      Store.dispatch(SetRolesCollection(roles))
      this.addRole.cancel()
    }).catch((error) => {
      this.addRole.setErrors(error.data)
      this.addRole.loader.hideLoader()
      this.addRole.submitButton.setEnabled()
    })
}

export default StoreChanges
