import SortPermissions from "engine/__Shared/Actions/onSort"
import SearchPermissions from "engine/__Shared/Actions/onSearch"
import onConfirmDeletePermission from "engine/RolesAndPermission/Permissions/Actions/onConfirmDelete"
import EditPermission from "engine/__Shared/Actions/onEdit"
import GetPermissions from "engine/RolesAndPermission/Permissions/GetPermissions"
import DeletePermission from "engine/RolesAndPermission/Permissions/DeletePermission"

import AddPermissionStoreChanges from "engine/RolesAndPermission/Permissions/AddPermission/StoreChanges"
import AddPermissionResetModal from "engine/RolesAndPermission/Permissions/AddPermission/ResetModal"
import AddPermissionConfirm from "engine/RolesAndPermission/Permissions/AddPermission/Confirm"
import AddPermissionCancel from "engine/RolesAndPermission/Permissions/AddPermission/Cancel"
import AddPermissionOnEscape from "engine/RolesAndPermission/Permissions/AddPermission/OnEscape"

import ObjectSubmitButton from "engine/__Shared/Objects/SubmitButton"
import Loader from "engine/__Shared/Objects/Loader"

const ngn = {}

ngn.collection = {
  search_text: "",
  sort: [],
  fetching: false,

  onSort: SortPermissions.bind(ngn),
  onEdit: EditPermission.bind(ngn),
  onDeleteConfirm: onConfirmDeletePermission.bind(ngn),
  onSearch: SearchPermissions.bind(ngn),

  fetchData: GetPermissions.bind(ngn),
  delete: {
    selected: null,
    onSubmit: DeletePermission.bind(ngn)
  }
}

ngn.addPermission = {
  storeChanges: AddPermissionStoreChanges.bind(ngn),
  resetAddPermissionModal: AddPermissionResetModal.bind(ngn),
  loader: Loader(),
  confirm: AddPermissionConfirm.bind(ngn),
  cancel: AddPermissionCancel.bind(ngn),
  onEscape: AddPermissionOnEscape.bind(ngn),
  setErrors: null,
  submitButton: ObjectSubmitButton(),
  resetForm: null,
  showModal: null,
  hideModal: null,
}

export default ngn