const Confirm = function (data) {
  // console.dir(this)

  // Close the modal and reset the form
  // this.editRole.resetEditRoleModal()

  // Store changes
  this.editRole.storeChanges(data)
}

export default Confirm
