import { createRoot } from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import { Provider } from "react-redux"

import Store from "store"
import History from "services/History"
import App from "components/App"
import createCache from "@emotion/cache"
import { CacheProvider } from "@emotion/react"
import reportWebVitals from "./reportWebVitals"
import ThemeComponent from "theme/ThemeComponent"
// ** Contexts
import { SettingsConsumer, SettingsProvider } from "context/settingsContext"
import ReactHotToast from "components/__Shared/ReactHotToast"

// ** Third Party Import
import { Toaster } from "react-hot-toast"

const styleCache = createCache({
  key: "css",
  nonce: document.querySelector("meta[property=\"csp-nonce\"]").content,
})

createRoot(document.getElementById("root")).render(
  <CacheProvider value={styleCache}>
    <Provider store={Store}>
      <BrowserRouter history={History}>
        <SettingsProvider>
          <SettingsConsumer>
            {({ settings }) => {
              return (
                <ThemeComponent settings={settings}>
                  <App />
                  <ReactHotToast>
                    <Toaster position={settings.toastPosition} toastOptions={{ className: "react-hot-toast" }} />
                  </ReactHotToast>
                </ThemeComponent>
              )
            }}
          </SettingsConsumer>
        </SettingsProvider>
      </BrowserRouter>
    </Provider>
  </CacheProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
