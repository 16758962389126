import { Box, Button, Card, IconButton, Typography } from "@mui/material"
import PageHeader from "components/UserArea/__Shared/PageHeader"
import { useCallback, useEffect, useState } from "react"

import TableHeaderToolbar from "components/UserArea/__Shared/DataGridTable/TableHeaderToolbar"
import ModalEdit from "components/UserArea/__Shared/DataGridTable/ModalEdit"
import ModalAdd from "components/UserArea/__Shared/DataGridTable/ModalAdd"

import Comm from "services/Comm"
import Catch401 from "middlewares/Catch401"
import Store from "store"
import { SharedModalErrorMessageShow } from "store/actions"

import ngn from "engine/DataGridTable"
import { DataGrid } from "@mui/x-data-grid"
import General from "configs/General"
import { SquareEditOutline, TrashCanOutline } from "mdi-material-ui"
import ConfirmActionModal from "components/__Shared/Modals/ConfirmActionModal"

const DataGridTable = ({ title, subtitle, source, fields, canEdit, canDelete, canAdd }) => {

  // ** State
  const [loader, setLoader] = useState(false)
  const [currentPage, setCurrentPage] = useState(0)
  const [collection, setCollection] = useState([])
  const [selected, setSelected] = useState([])

  // Load Items
  ngn.collection.fetchData = useCallback(() => {

    const page = ngn.collection.page
    const sort = ngn.collection.sort
    const limit = ngn.collection.limit
    const filter = ngn.collection.filter
    const search = ngn.collection.search_text
    const soft_deleted = ngn.collection.show_deleted

    ngn.collection.loader.showLoader()
    Comm.request({
      url: source,
      method: "post",
      data: { page, sort, limit, filter, search, soft_deleted }
    }).then((res) => {
      ngn.collection.setCollection(res.data.data, res.data.meta.total)
      ngn.collection.loader.hideLoader()
    }).catch(Catch401((err) => {
      ngn.collection.loader.hideLoader()
      Store.dispatch(SharedModalErrorMessageShow(err.response.data.message))
    }))

  }, [source])

  useEffect(() => {
    ngn.collection.fetchData()
  }, [])

  // ** Engine
  ngn.source = source

  ngn.collection.loader.hideLoader = () => {
    setLoader(false)
  }
  ngn.collection.loader.showLoader = () => {
    setLoader(true)
  }
  ngn.collection.setCurrentPage = (page) => {
    setCurrentPage(page)
  }
  ngn.collection.setCollection = (data, total) => {
    ngn.collection.collection = data
    ngn.collection.total = total
    setCollection(data)
  }
  ngn.collection.setSelectedItems = (users) => {
    setSelected(users)
    console.log(selected)
  }

  const CustomToolbar = () => {
    return (
      <TableHeaderToolbar ngn={ngn} />
    )
  }

  const ActionColumn = [
    {
      //flex: 1,
      minWidth: 115,
      sortable: false,
      filterable: false,
      field: "actions",
      headerName: "Actions",
      renderCell: ({ row }) => {
        return (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {
              canEdit && <IconButton color="info" onClick={() => ngn.editItem.modal.show(row)}>
                <SquareEditOutline />
              </IconButton>
            }
            {
              canDelete && <IconButton color="error" onClick={() => ngn.collection.onDeleteConfirm(row)}>
                <TrashCanOutline />
              </IconButton>
            }
          </Box>
        )
      }
    }
  ]

  const TableColumns = [...fields, ...ActionColumn]

  return <>
    {canEdit && <ModalEdit ngn={ngn.editItem} onEscape={ngn.editItem.onEscape} fields={fields} title={title} />}
    {canDelete && <ConfirmActionModal />}
    {canAdd && <ModalAdd ngn={ngn.addItem} onEscape={ngn.addItem.onEscape} fields={fields} title={title} />}
    <Box sx={{ mb: 8, display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "space-between" }}>
      <Box sx={{ width: "80%" }}>
        <PageHeader
          title={<Typography variant="h5">{title}</Typography>}
          subtitle={
            <Typography variant="body2">
              {subtitle}
            </Typography>
          }
        />
      </Box>
      {
        canAdd && <Button variant="contained" onClick={() => ngn.addItem.modal.show()}>Add new</Button>
      }
    </Box>
    <Card>
      <DataGrid
        autoHeight
        checkboxSelection
        disableSelectionOnClick
        rowsPerPageOptions={General.dataTablePageLimitOptions}
        keepNonExistentRowsSelected
        sx={{ "& .MuiDataGrid-columnHeaders": { borderRadius: 0 } }}
        rows={collection}
        rowCount={ngn.collection.total}
        columns={(!canEdit && !canDelete) ? fields : TableColumns}
        loading={loader}
        pagination
        page={currentPage}
        pageSize={ngn.collection.limit}
        sortingMode="server"
        paginationMode="server"
        filterMode="server"
        onPageSizeChange={ngn.collection.onChangePageLimit}
        onPageChange={ngn.collection.onChangePage}
        onSortModelChange={ngn.collection.onSort}
        onFilterModelChange={ngn.collection.onFilter}
        onSelectionModelChange={ngn.collection.onSelect}
        components={{ Toolbar: CustomToolbar }}
      />
    </Card>
  </>
}
export default DataGridTable