import { Box, Button, TextField } from "@mui/material"
import useDebounce from "hooks/useDebounce"

const RolesToolBar = ({ ngn, ability }) => {
  const onSearch = useDebounce(function (value) {
    ngn.collection.onSearch(value)
  }, 1000)
  const onClickHandler = function (ev) {
    // console.dir(ev)
    ngn.addRole.showModal()
  }
  return (
    <Box sx={{ p: 5, display: "flex", justifyContent: "flex-end" }}>
      <TextField
        sx={{ pr: 2 }}
        size="small"
        defaultValue={ngn.collection.search_text}
        onChange={e => onSearch(e.target.value)}
        placeholder="Search Role"
      />
      {ability?.can("create", "roles") && (
        <Button variant="contained" onClick={onClickHandler}>Add Role</Button>
      )}
    </Box>
  )
}
export default RolesToolBar