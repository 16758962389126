import Comm from "services/Comm"
import Uri from "services/Uri"
import Catch401 from "middlewares/Catch401"

const ParseCSV = async function ({ csv_file, type }) {

  let result = {}
  let error = {
    code: 0,
    message: ""
  }

  const data = new FormData()
  data.append("csv_file", csv_file)
  data.append("header", true)
  data.append("import_type", type)

  await Comm.request({
    url: Uri.importsParseCSV(),
    method: "post",
    data: data
  }).then((res) => {
    result.data = res.data
  }).catch(Catch401((err) => {
    // console.dir(err)
    error.code = err.response.status
    error.message = err.response.data.message
  }, error))

  if (error.code) {
    return Promise.reject(error)
  }

  return Promise.resolve(result)
}

export default ParseCSV
