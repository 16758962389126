import { Grid, CircularProgress, Box } from "@mui/material"
import ngn from "engine/Users"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"

import GeneralInfo from "components/UserArea/Users/Detail/GeneralInfo"
import UserDetails from "components/UserArea/Users/Detail/UserDetails"
import ConfirmActionModal from "components/__Shared/Modals/ConfirmActionModal"
import ModalRequestExtraInfo from "components/UserArea/Users/ModalRequestExtraInfo"

const UserDetail = () => {
  let { id } = useParams()
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    ngn.user.user_id = id
    ngn.user.fetchData()
  }, [id])

  ngn.user.loading.showLoader = function () {
    setLoading(true)
  }

  ngn.user.loading.hideLoader = function () {
    setLoading(false)
  }

  return (
    !loading ?
      <Grid container spacing={6}>
        <ModalRequestExtraInfo ngn={ngn.requestUserMeta} onEscape={ngn.requestUserMeta.onEscape}/>
        <ConfirmActionModal />
        <Grid item xs={12} md={5} lg={4}>
          <GeneralInfo ngn={ngn}/>
        </Grid>
        <Grid item xs={12} md={7} lg={8}>
          <UserDetails ngn={ngn}/>
        </Grid>
      </Grid>
      : <Box sx={{ justifyContent: "center", alignItems: "center", display: "flex", height: "100%" }}>
        <CircularProgress size={40} />
      </Box>
  )
}
export default UserDetail