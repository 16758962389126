import Req from "requests"
import Store from "store"
import { SharedModalConfirmActionHide, SharedModalErrorMessageShow, StoresSetCollection } from "store/actions"

const DeleteStore = function () {
  const { id } = this.collection.delete.selected

  this.collection.fetching = true
  Req.DeleteStore({ id })
    .then((response) => {
      let stores = Store.getState().Stores.collection
      const new_stores = Object.values(stores).filter((s) => s.id !== id)
      Store.dispatch(SharedModalConfirmActionHide())
      Store.dispatch(StoresSetCollection(new_stores))
      this.collection.fetching = false
    }).catch((error) => {
      Store.dispatch(SharedModalConfirmActionHide())
      Store.dispatch(SharedModalErrorMessageShow(error.message))
      this.collection.fetching = false
    })
}

export default DeleteStore