import React from "react"
import Proptype from "prop-types"
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material"
import { Controller } from "react-hook-form"
import FormFieldMessage from "components/__Shared/FormFieldMessage"

function RadioButtons(props) {
  const { label, name, control, required, error, change, options } = props
  return (
    <>
      <FormLabel error={Boolean(error)}>{label}</FormLabel>
      <FormControl fullWidth>
        <Controller
          name={name}
          control={control}
          rules={{ required: required }}
          render={({ field: { value, onChange } }) => (
            <RadioGroup row aria-label={name} name={name} value={value ? value : ""} onChange={(e) => {
              if (change) {
                change(e)
              }
              return onChange(e)
            }}>
              {options && Array.isArray(options) && options?.map((v, k) =>
                <FormControlLabel key={v.value} value={v.value} control={<Radio />} label={v.label} />
              )}
            </RadioGroup>
          )}
        />
        <FormFieldMessage color="error.main" error={error} />
      </FormControl>
    </>
  )
}

RadioButtons.defaultProps = {
  label: "",
  name: "input-text",
  control: {},
  required: false,
  error: null,
  placeholder: "",
  options: [],
  change: () => { },
}

RadioButtons.prototype = {
  label: Proptype.string,
  name: Proptype.string,
  control: Proptype.object,
  required: Proptype.bool,
  error: Proptype.object,
  placeholder: Proptype.string,
  options: Proptype.array,
  change: Proptype.func,
}

export default RadioButtons