import Catch401 from "middlewares/Catch401"
import Comm from "services/Comm"
import Uri from "services/Uri"

const AddStore = async function ({ name, category_id, subcategory_id, email, phone, website, address }) {

  let result = {}
  let error = {
    code: 0,
    type: "",
    data: null,
    message: "Store couldn't be added!",
  }

  await Comm.request({
    url: Uri.addStore(),
    method: "post",
    data: {
      name,
      category_id,
      subcategory_id: (subcategory_id === "") ? null : subcategory_id,
      email,
      phone,
      website,
      address
    }
  }).then((res) => {
    // console.dir(res)
    result = res.data.data
  }).catch(Catch401((err) => {
    // console.dir(err)
    error.code = err.response.status
    error.message = err.response.data.message
    error.data = err.response.data.errors
  }))

  if (error.code > 0) {
    return Promise.reject(error)
  }

  return Promise.resolve(result)
}

export default AddStore
