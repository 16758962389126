import { useState } from "react"
// ** MUI Components
import {
  IconButton,
  InputAdornment,
  OutlinedInput,
} from "@mui/material"

// ** Icons Imports
import EyeOutline from "mdi-material-ui/EyeOutline"
import EyeOffOutline from "mdi-material-ui/EyeOffOutline"

const Password = function ({ value, onBlur, onChange, error, id = "password-field" }) {

  const [showPassword, setShowPassword] = useState(false)

  return (
    <OutlinedInput
      value={value}
      onBlur={onBlur}
      id={id}
      inputProps={{
        autoComplete: "new-password",
        form: {
          autoComplete: "off",
        },
      }}
      label="Password"
      onChange={onChange}
      error={Boolean(error)}
      type={showPassword ? "text" : "password"}
      endAdornment={
        <InputAdornment position="end">
          <IconButton
            edge="end"
            onMouseDown={e => e.preventDefault()}
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? <EyeOutline /> : <EyeOffOutline />}
          </IconButton>
        </InputAdornment>
      }
    />
  )
}

export default Password
