import * as actionType from "store/actions/actionTypes"
import StoreTemplate from "store/StoreTemplate"

import DefaultToObject from "store/defaults/DefaultToObject"
import DefaultToStringOnNull from "store/defaults/DefaultToStringOnNull"

const container = DefaultToObject()

const readyState = function () {
  return {
    func: null,
    params: DefaultToStringOnNull()
  }
}

const defaultState = function () {
  return {
    func: "Init",
    params: DefaultToStringOnNull()
  }
}

const Requests = StoreTemplate(defaultState(), container)

container[actionType.ACTION_REQUESTS_SET_ACTION] = function (state, payload) {
  return {
    ...state,
    func: payload.func,
    params: payload.params
  }
}

container[actionType.ACTION_REQUESTS_SET_REQUEST] = function (state, payload) {
  return {
    ...state,
    func: payload.func
  }
}

container[actionType.ACTION_REQUESTS_SET_PARAMS] = function (state, payload) {
  return {
    ...state,
    params: payload.params
  }
}

container[actionType.ACTION_REQUESTS_READY] = function (state, payload) {
  return readyState()
}

container[actionType.ACTION_REQUESTS_GLOBAL_RESET] = function (state, payload) {
  return defaultState()
}

export default Requests
