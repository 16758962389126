import { TextField } from "@mui/material"
import Box from "@mui/material/Box"
//import UserActions from "./UserActions"

import useDebounce from "hooks/useDebounce"

const TableHeaderRightToolbar = ({ ngn }) => {

  const onSearchText = useDebounce(function (value) {
    ngn.collection.onSearch(value)
  }, 1000)

  return (
    <Box sx={{ p: 5, pb: 3, display: "flex", flexWrap: "wrap", alignItems: "center" }}>
      {/* <UserActions ngn={ngn} /> */}
      <TextField
        size="small"
        defaultValue={ngn.collection.search_text}
        onChange={e => onSearchText(e.target.value)}
        placeholder="Search Stores" />
    </Box>
  )
}

export default TableHeaderRightToolbar