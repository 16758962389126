import * as actionType from "store/actions/actionTypes"

import DefaultToFalse from "store/defaults/DefaultToFalse"
import DefaultToStringOnNull from "store/defaults/DefaultToStringOnNull"

const defaultModals = function () {
  return {
    error_message: {
      opened: DefaultToFalse(),
      message: "",
      callback: DefaultToStringOnNull()
    },
    confirm_action: {
      opened: DefaultToFalse(),
      message: "",
      ngn: DefaultToStringOnNull()
    },
  }
}
const defaultState = function () {
  return {
    modals: defaultModals()
  }
}

const Shared = function (state = defaultState(), action) {

  switch (action.type) {
    //
    // Modals
    //
    case actionType.ACTION_SHARED_MODALS_ERROR_MESSAGE_SHOW:
      return modalsErrorMessageShow(state, action.payload)
    case actionType.ACTION_SHARED_MODALS_ERROR_MESSAGE_HIDE:
      return modalsErrorMessageHide(state)

    case actionType.ACTION_SHARED_MODALS_CONFIRM_ACTION_SHOW:
      return modalsConfirmActionShow(state, action.payload)
    case actionType.ACTION_SHARED_MODALS_CONFIRM_ACTION_HIDE:
      return modalsConfirmActionHide(state)

    //
    // Other
    case actionType.ACTION_SHARED_GLOBAL_RESET:
      return globalReset()

    default:
      return state
  }
}


const globalReset = () => {
  return defaultState()
}

const modalsErrorMessageShow = (state, payload) => {
  state.modals.error_message = {
    opened: true,
    message: payload.message,
    callback: payload.callback
  }
  return {
    ...state
  }
}

const modalsErrorMessageHide = (state) => {
  state.modals = defaultModals()
  return {
    ...state
  }
}

const modalsConfirmActionShow = (state, payload) => {
  state.modals.confirm_action = {
    opened: true,
    message: payload.message,
    ngn: payload.ngn
  }
  return {
    ...state
  }
}

const modalsConfirmActionHide = (state) => {
  state.modals = defaultModals()
  return {
    ...state
  }
}


export default Shared
