import Store from "store"

import {RequestsGlobalReset, UserGlobalReset} from "store/actions"
// ** Third Party Components
import toast from "react-hot-toast"
const GlobalReset = function () {
  Store.dispatch(RequestsGlobalReset())
  Store.dispatch(UserGlobalReset())
  toast.dismiss()
}

export default GlobalReset
