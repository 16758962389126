import Store from "store"
import { UserGlobalReset, RolesGlobalReset, PermissionsGlobalReset, SharedGlobalReset, ImportDataGlobalReset, RollbackGlobalReset } from "store/actions"

const ImpersonateUserReset = function () {
  Store.dispatch(UserGlobalReset())
  Store.dispatch(RolesGlobalReset())
  Store.dispatch(PermissionsGlobalReset())
  Store.dispatch(SharedGlobalReset())
  Store.dispatch(ImportDataGlobalReset())
  Store.dispatch(RollbackGlobalReset())
}

export default ImpersonateUserReset
