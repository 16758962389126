import Uri from "services/Uri"
import { useSelector } from "react-redux"
import Req from "requests"
import { Navigate, Route, Routes, useMatch } from "react-router-dom"
import Dashboard from "components/UserArea/Dashboard"

import InitLoading from "components/__Shared/InitLoading"

// ** React Perfect Scrollbar Style
import "react-perfect-scrollbar/dist/css/styles.css"

import useMediaQuery from "@mui/material/useMediaQuery"

// ** Layout Imports
// !Do not remove this Layout import
import Layout from "components/UserArea/__Shared/Layout"

// ** Hook Import
import { useSettings } from "hooks/useSettings"

import Users from "components/UserArea/Users"
import UserDetail from "components/UserArea/Users/Detail"

import Roles from "components/UserArea/RolesPermissions/Roles"
import Permissions from "components/UserArea/RolesPermissions/Permissions"
import Stores from "components/UserArea/Stores"
import ImportData from "components/UserArea/ImportAndRollback/ImportData"
import RollbackImports from "components/UserArea/ImportAndRollback/Rollback"
import AclGuard from "components/__Shared/AclGuard"
import ProtectedRoute from "components/__Shared/ProtectedRoute"
import ImpersonateToast from "components/__Shared/ImpersonateToast"

import ExperiencePoints from "components/UserArea/ControlPanel/ExperiencePoints"
import ScorerRank from "components/UserArea/ControlPanel/ScorerRank"
import ReservationDeals from "components/UserArea/ControlPanel/ReservationDeals"

import StoreCategory from "components/UserArea/Categories/StoreCategory"
import CommonCategory from "components/UserArea/Categories/CommonCategory"
import ProductCategory from "components/UserArea/Categories/ProductCategory"
import BlogCategory from "components/UserArea/Categories/BlogCategory"

const UserArea = function () {
  const RequestFunc = useSelector(state => state.Requests.func)
  const RequestParams = useSelector(state => state.Requests.params)
  const isEmpty = !!useMatch(Uri.u())
  // ** Hooks
  const { settings, saveSettings } = useSettings()

  /**
   *  The below variable will hide the current layout menu at given screen size.
   *  The menu will be accessible from the Hamburger icon only (Vertical Overlay Menu).
   *  You can change the screen size from which you want to hide the current layout menu.
   *  Please refer useMediaQuery() hook: https://mui.com/material-ui/react-use-media-query/,
   *  to know more about what values can be passed to this hook.
   *  ! Do not change this value unless you know what you are doing. It can break the template.
   */
  const hidden = useMediaQuery(theme => theme.breakpoints.down("lg"))

  // this will show a loading page if the initial requests to the api are not completed yet; see /requests
  if (RequestFunc !== null) {
    Req[RequestFunc](RequestParams)
    return (
      <InitLoading />
    )
  }

  // just /u ? redirect to the default page
  if (isEmpty) {
    return <Navigate to={`${Uri.uDashboard()}`} replace={true} />
  }

  return (
    <AclGuard>
      <Layout
        hidden={hidden}
        settings={settings}
        saveSettings={saveSettings}
      >
        <ImpersonateToast />
        <Routes>
          <Route path={`${Uri.dashboard()}`} element={<Dashboard />} />
          <Route path={`${Uri.users()}`} element={<ProtectedRoute action="read" subject="users" component={Users} />} />

          <Route path={`${Uri.usersID()}`} element={<ProtectedRoute action="read" subject="users" component={UserDetail} />} />

          <Route path={`${Uri.roles()}`} element={<ProtectedRoute action="read" subject="roles" component={Roles} />} />
          <Route path={`${Uri.permissions()}`} element={<ProtectedRoute action="read" subject="permissions" component={Permissions} />} />
          <Route path={`${Uri.stores()}`} element={<ProtectedRoute action="read" subject="stores" component={Stores} />} />
          <Route path={`${Uri.importData()}`} element={<ProtectedRoute action="read" subject="import" component={ImportData} />} />
          <Route path={`${Uri.rollbackImport()}`} element={<ProtectedRoute action="delete" subject="import" component={RollbackImports} />} />

          <Route path={`${Uri.experiencePoints()}`} element={<ProtectedRoute action="read" subject="experience-points" component={ExperiencePoints} />} />
          <Route path={`${Uri.reservationDeals()}`} element={<ProtectedRoute action="read" subject="reservation-deal-settings" component={ReservationDeals} />} />
          <Route path={`${Uri.scorerRank()}`} element={<ProtectedRoute action="read" subject="scorer-rank-settings" component={ScorerRank} />} />

          <Route path={`${Uri.storeCategory()}`} element={<ProtectedRoute action="read" subject="categories" component={StoreCategory} />} />
          <Route path={`${Uri.commonCategory()}`} element={<ProtectedRoute action="read" subject="categories" component={CommonCategory} />} />
          <Route path={`${Uri.productCategory()}`} element={<ProtectedRoute action="read" subject="categories" component={ProductCategory} />} />
          <Route path={`${Uri.blogCategory()}`} element={<ProtectedRoute action="read" subject="categories" component={BlogCategory} />} />
        </Routes>
      </Layout>
    </AclGuard>
  )
}

export default UserArea

