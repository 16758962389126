import Req from "requests"
import Store from "store"
import { SharedModalErrorMessageShow, UsersSetSelectedUser } from "store/actions"

const GetUserDetail = function () {
  const id = this.user.user_id
  this.user.loading.showLoader()
  Req.GetUser({ id }).then((response) => {
    this.user.loading.hideLoader()
    Store.dispatch(UsersSetSelectedUser(response.data))
  }).catch((error) => {
    this.user.loading.hideLoader()
    Store.dispatch(SharedModalErrorMessageShow(error.message))
  })
}
export default GetUserDetail