// ** MUI Imports
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import RolesColumns from "components/UserArea/RolesPermissions/__Shared/RolesTableColumns"
import PageHeader from "components/UserArea/__Shared/PageHeader"
import { Card } from "@mui/material"
import { useSelector } from "react-redux"
import { useContext, useEffect } from "react"
import ngn from "engine/RolesAndPermission/Roles"
import { DataGrid } from "@mui/x-data-grid"
import RolesToolBar from "components/UserArea/RolesPermissions/__Shared/RolesToolBar"
import ModalAddRole from "components/UserArea/RolesPermissions/Roles/ModelAddRole"
import ModalEditRole from "components/UserArea/RolesPermissions/Roles/ModelEditRole"
import ConfirmActionModal from "components/__Shared/Modals/ConfirmActionModal"
import { AbilityContext } from "context/Can"

const Roles = () => {
  const rolesDetails = useSelector((state) => state.Roles)
  const ability = useContext(AbilityContext)

  //Updating Roles
  useEffect(() => {
    ngn.collection.fetchData()
  }, [])

  const CustomRolesToolBar = () => {
    return <RolesToolBar ngn={ngn} ability={ability}/>
  }

  return (
    <Grid container>
      <ModalAddRole ngn={ngn.addRole} onEscape={ngn.addRole.onEscape} />
      <ModalEditRole ngn={ngn.editRole} onEscape={ngn.editRole.onEscape} />
      <ConfirmActionModal />
      <Grid item xs={12}>
        <PageHeader
          title={<Typography variant="h5">Roles List</Typography>}
          subtitle={
            <Typography variant="body2">
              A role definition is collection of permissions. You can use the
              built-in roles or you can create your own custom roles.
            </Typography>
          }
        />
        <Card sx={{ mt: 4 }}>
          <DataGrid
            autoHeight
            disableColumnSelector
            disableColumnMenu
            hideFooterPagination
            rows={Object.values(rolesDetails.collection)}
            rowCount={ngn.collection.total}
            loading={ngn.collection.fetching}
            columns={RolesColumns}
            disableSelectionOnClick
            sortingMode="server"
            onSortModelChange={ngn.collection.onSort}
            onFilterModelChange={ngn.collection.onFilter}
            filterMode="server"
            keepNonExistentRowsSelected
            sx={{ "& .MuiDataGrid-columnHeaders": { borderRadius: 0 } }}
            components={{ Toolbar: CustomRolesToolBar }}
          />
        </Card>
      </Grid>
    </Grid>
  )
}
export default Roles
