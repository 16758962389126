import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  Typography,
} from "@mui/material"
import { useEffect, useState } from "react"

// ** Third Party Imports
import * as yup from "yup"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { Input } from "components/__Shared/FormControls"
import Submit from "components/__Shared/Input/Submit"

const permission_access = [
  {
    label: "Read",
    value: "read",
  },
  {
    label: "Create",
    value: "create",
  },
  {
    label: "Update",
    value: "update",
  },
  {
    label: "Delete",
    value: "delete",
  },
]

// ** Default Values
const defaultValues = {
  category: "",
  access: [],
}

const schema = yup.object().shape({
  category: yup.string().required(),
  access: yup.array().min(1, "at least 1").required("required"),
})

const ModalAddPermission = function ({ ngn, onEscape }) {
  const [open, setOpened] = useState(false)
  const [loading, setLoading] = useState(false)
  const [accessLevel, setAccessLevel] = useState([])

  // ** Hooks
  const {
    reset,
    setValue,
    setError,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
    mode: "onBlur",
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    if (open) {
      window.addEventListener("keydown", onEscape)
    } else {
      window.removeEventListener("keydown", onEscape)
    }
  }, [open, onEscape])

  ngn.resetForm = function () {
    reset()
    setAccessLevel([])
    ngn.submitButton.reset()
  }

  ngn.setErrors = function (error) {
    console.log(error)
    if (error.code > 0) {
      const { type, message, data } = error
      data.forEach((f) => {
        setError(f, {
          type: type,
          message: message[`${f}`],
        })
      })
      ngn.submitButton.setDisabled()
    }
  }

  ngn.loader.showLoader = function () {
    setLoading(true)
  }

  ngn.loader.hideLoader = function () {
    setLoading(false)
  }

  ngn.showModal = function () {
    setOpened(true)
  }

  ngn.hideModal = function () {
    setOpened(false)
  }

  const onConfirm = function (data) {
    ngn.confirm(data)
  }

  const onCancel = function (ev) {
    // console.dir(ev)
    ngn.cancel()
  }

  const handleChange = (event) => {
    let access = accessLevel
    if (event.target.checked) {
      access.push(event.target.name)
    } else {
      access = accessLevel.filter((x) => x !== event.target.name)
    }
    setAccessLevel(access)
    setValue("access", access)
  }

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      onClose={onCancel}
      open={open}
      disableEscapeKeyDown
    >
      <DialogTitle sx={{ pt: 10, mx: "auto", textAlign: "center" }}>
        <Typography variant="h4" component="span" sx={{ mb: 2 }}>
          Add New Permission
        </Typography>
        <Typography variant="body2">
          Permissions you may use and assign to your users.
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ pb: 8, mx: "auto" }}>
        {loading ? (
          <Box
            sx={{
              justifyContent: "center",
              alignContent: "center",
              display: "flex",
              mt: 3,
            }}
          >
            <CircularProgress sx={{ ml: 3, mr: 3 }} size={40} />
          </Box>
        ) : (
          <Box
            component="form"
            sx={{ mt: 2 }}
            onSubmit={handleSubmit(onConfirm)}
          >
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Input
                  label="Category"
                  placeholder="Enter Permission Category"
                  name="category"
                  control={control}
                  error={errors.category}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl>
                  <FormGroup
                    row
                    sx={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-evenly",
                    }}
                  >
                    {permission_access.map((access) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            name={access.value}
                            onChange={handleChange}
                          />
                        }
                        name={access.value}
                        label={access.label}
                        key={access.value}
                      />
                    ))}
                  </FormGroup>
                </FormControl>
                {errors.access && (
                  <FormHelperText sx={{ color: "error.main" }}>
                    Please select at least one permission access
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={12}>
                <Box
                  className="demo-space-x"
                  sx={{
                    "&>:last-child": { mr: 0 },
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Submit ngn={ngn.submitButton} title="Create Permission" />
                  <Button
                    size="large"
                    variant="outlined"
                    color="secondary"
                    onClick={onCancel}
                  >
                    Discard
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default ModalAddPermission
