// ** MUI Imports
import { FormControl, Grid, InputLabel, MenuItem, Select, Button, List, ListItem, Typography, IconButton } from "@mui/material"

// ** Third Party Imports
import * as yup from "yup"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

import FormFieldMessage from "components/__Shared/FormFieldMessage"
import { useEffect, useRef, useState } from "react"
import { Close, FileDocumentOutline, UploadOutline } from "mdi-material-ui"
import FieldList from "./FieldList"

import { ImportDataSetType } from "store/actions"
import Store from "store"
import Submit from "components/__Shared/Input/Submit"

import ImportTypes from "configs/ImportTypes"

// ** Default Values
const defaultValues = {
  import_type: "",
  csv_file: ""
}

const schema = yup.object().shape({
  import_type: yup.string().required("Import Type is required field"),
  csv_file: yup.mixed()
    .test("fileRequired", "You need to provide a .csv file", (value) => {
      if (value.length === 0) {
        return false
      }
      return true
    })
    .test("fileSize", "File size is too large", (value) => {
      if (value.length > 0) {
        return value[0].size <= 1e+7
      }
      return true
    })
    .test("fileType", "Unsupported file format. The file must be a .csv type", (value) => {
      if (value.length > 0) {
        return ["text/csv"].includes(value[0].type)
      }
      return true
    })
})

const StepOne = ({ ngn }) => {

  // ** State
  const [files, setFiles] = useState([])
  const fileInput = useRef()

  // ** Hooks
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    setValue
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    mode: "onChange"
  })

  useEffect(() => {
    if (isValid) {
      ngn.execute.submitButton.setEnabled()
    }
  }, [isValid, ngn.execute.submitButton])

  const onSubmit = (data) => {
    ngn.execute.onParseFile(files[0])
  }

  const handleRemoveFile = file => {
    const uploadedFiles = files
    const filtered = uploadedFiles.filter(i => i.name !== file.name)
    fileInput.current.value = ""
    setValue("csv_file", [...filtered])
    setFiles([...filtered])
  }

  const handleOnChangeImportType = (type) => {
    Store.dispatch(ImportDataSetType(type))
    ngn.execute.onChangeType(type)
    fileInput.current.value = ""
    setFiles([])
  }

  const fileList = files.map(file => (
    <ListItem key={file.name} sx={{ justifyContent: "space-between", border: "1px solid rgba(93, 89, 98, 0.14)", borderRadius: 1 }}>
      <div className="file-details" style={{ display: "flex", alignItems: "center" }}>
        <div className="file-preview" style={{ display: "flex", marginRight: "0.9375rem" }}>
          <FileDocumentOutline sx={{ fontSize: "2rem" }} />
        </div>
        <div>
          <Typography className="file-name" sx={{ fontWeight: 600 }}>{file.name}</Typography>
          <Typography className="file-size" variant="body2">
            {Math.round(file.size / 100) / 10 > 1000
              ? `${(Math.round(file.size / 100) / 10000).toFixed(1)} mb`
              : `${(Math.round(file.size / 100) / 10).toFixed(1)} kb`}
          </Typography>
        </div>
      </div>
      <IconButton onClick={() => handleRemoveFile(file)}>
        <Close fontSize="small" />
      </IconButton>
    </ListItem>
  ))

  return (
    <form key={0} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={5}>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth sx={{ mb: 5 }}>
            <InputLabel htmlFor="import-data-type" error={Boolean(errors.import_type)}>
              Import Type
            </InputLabel>
            <Controller
              name="import_type"
              control={control}
              rules={{ required: true }}
              render={({ field: { value, onChange } }) => (
                <Select
                  label="Import Type"
                  value={value}
                  onChange={e => {
                    handleOnChangeImportType(e.target.value)
                    onChange(e)
                  }}
                  error={Boolean(errors.import_type)}
                  id="import-data-type"
                >
                  {ImportTypes.map((item, index) => (
                    <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                  ))}
                </Select>
              )}
            />
            <FormFieldMessage color="error.main" error={errors.import_type} />
          </FormControl>
          <FormControl fullWidth>
            <Controller
              name="csv_file"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <>
                  <input accept=".csv" ref={fileInput} style={{ display: "none" }} type="file" onChange={e => {
                    field.onChange(e.target.files)
                    setFiles([...e.target.files])
                  }} id="import-data-file" />
                  <label htmlFor="import-data-file">
                    <Button color={errors.csv_file ? "error" : "secondary"} variant="contained" startIcon={<UploadOutline />} onClick={() => fileInput.current.click()}>
                      Upload CSV File
                    </Button>
                  </label>
                </>
              )}
            />
            <FormFieldMessage color="error.main" error={errors.csv_file} />
          </FormControl>
          {files.length ? (
            <List>{fileList}</List>
          ) : null}
        </Grid>
        <Grid item xs={12} sm={6}>
          <FieldList ngn={ngn} />
        </Grid>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
          <Submit title="Next" ngn={ngn.execute.submitButton} />
        </Grid>
      </Grid>
    </form>
  )
}
export default StepOne