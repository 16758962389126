import Req from "requests"
import Store from "store"
import { SetRolesCollection, SharedModalConfirmActionHide, SharedModalErrorMessageShow } from "store/actions"

const DeleteRole = function () {
  const { id, name } = this.collection.delete.selected

  this.collection.fetching = true
  Req.DeleteRole({ id, name })
    .then((response) => {
      let roles = Store.getState().Roles.collection
      const new_roles = Object.values(roles).filter((p) => p.id !== id)
      Store.dispatch(SharedModalConfirmActionHide())
      Store.dispatch(SetRolesCollection(new_roles))
      this.collection.fetching = false
    }).catch((error) => {
      Store.dispatch(SharedModalConfirmActionHide())
      Store.dispatch(SharedModalErrorMessageShow(error.message))
      this.collection.fetching = false
    })
}

export default DeleteRole