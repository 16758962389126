import { buildAbilityFor } from "configs/acl"
import { useState } from "react"

// ** Context Imports
import { AbilityContext } from "context/Can"
import Store from "store"

const AclGuard = props => {
  // ** Props
  const { children } = props
  const [ability, setAbility] = useState(undefined)
  const user = Store.getState().User

  // User is logged in, build ability for the user based on his role
  if (user && user.permissions && !ability) {
    setAbility(buildAbilityFor(user.permissions))
  }
  if (ability) {
    return <AbilityContext.Provider value={ability}>{children}</AbilityContext.Provider>
  }
  return <></>
}

export default AclGuard