import React from "react"
import Proptype from "prop-types"
import { FormControl, FormControlLabel, Switch } from "@mui/material"
import { Controller } from "react-hook-form"
import FormFieldMessage from "components/__Shared/FormFieldMessage"

function ReactSwitch(props) {
  const { label, name, control, required, error, change } = props
  return (
    <FormControl fullWidth>
      <Controller
        name={name}
        control={control}
        rules={{ required: required }}
        render={({ field: { value, onChange } }) => (
          <FormControlLabel label={label} control={<Switch checked={value} value={value}
            onChange={(e) => {
              if (change) {
                change(e)
              }
              return onChange(e)
            }} />}
          />
        )}
      />
      <FormFieldMessage color="error.main" error={error} />
    </FormControl>
  )
}

ReactSwitch.defaultProps = {
  label: "",
  name: "input-text",
  control: {},
  required: false,
  error: null,
  change: () => { },
}

ReactSwitch.prototype = {
  label: Proptype.string,
  name: Proptype.string,
  control: Proptype.object,
  required: Proptype.bool,
  error: Proptype.object,
  change: Proptype.func,
}

export default ReactSwitch
