import { Box, IconButton, Typography } from "@mui/material"
import CustomChip from "components/__Shared/Chip"
import { TrashCanOutline } from "mdi-material-ui"
import StringFn from "utils/StringFn"
import ngn from "engine/RolesAndPermission/Permissions"

const permissionAccessObj = {
  read: "info",
  create: "success",
  update: "warning",
  delete: "error",
}

const PermissionsColumns = [
  {
    field: "category",
    headerName: "Permission Name",
    flex: 0.3,
    renderCell: ({ row }) => {
      const { category } = row
      const category_name = new StringFn(category).removeHyphen().capitalize().result
      return (
        <Typography variant="body1" noWrap>
          {category_name}
        </Typography>
      )
    },
  },
  {
    field: "name",
    headerName: "Access",
    flex: .6,
    sortable: false,
    renderCell: ({ row }) => {
      const { access } = row
      access.sort()
      return (
        <Box>
          {access.map((permission, index) => (
            <CustomChip skin="light"
              size="small"
              label={permission}
              color={permissionAccessObj[permission]}
              sx={{ textTransform: "capitalize", mr: 1, "& .MuiChip-label": { lineHeight: "18px" } }} key={index} />
          ))}
        </Box>
      )
    },
  },
  {
    flex: 0.1,
    minWidth: 115,
    sortable: false,
    field: "actions",
    headerName: "Actions",
    renderCell: ({ row }) => {
      const { category } = row
      const category_name = new StringFn(category).removeHyphen().capitalize().result
      return <Box sx={{ display: "flex", alignItems: "center" }}>
        <IconButton color="error" onClick={() => ngn.collection.onDeleteConfirm({category, name: category_name})}>
          <TrashCanOutline />
        </IconButton>
      </Box>
    }
  }
]

export default PermissionsColumns