import Catch401 from "middlewares/Catch401"
import Uri from "services/Uri"
import Comm from "services/Comm"

const MultipleUpdateRollBack = async function ( id, model_id ) {
  let result = {}
  let error = {
    code: 0,
    message: "Update couldn't be rollbacked",
  }
  await Comm.request({
    url: Uri.multiUpdateRollBack(),
    method: "post",
    data: {
      id,
      model_id,
    },
  })
    .then((res) => {
      //
    })
    .catch(
      Catch401((err) => {
        error.code = err.response.status
        error.message = err.response.status.message
      }),
      error
    )

  if (error.code > 0) {
    return Promise.reject(error)
  }

  return Promise.resolve(result)
}

export default MultipleUpdateRollBack
