import React, { forwardRef } from "react"
import Proptype from "prop-types"
import { FormControl, TextField } from "@mui/material"
import { Controller } from "react-hook-form"
import FormFieldMessage from "components/__Shared/FormFieldMessage"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import DatePickerWrapper from "components/__Shared/DatePickerWrapper"

const CustomInput = forwardRef((props, ref) => {
  return <TextField
    InputLabelProps={{
      shrink: true,
    }}
    fullWidth {...props}
    inputRef={ref} autoComplete="off"
  />
})

function ReactDate(props) {
  const { label, name, control, required, error, placeholder, onChange, ...rest } = props
  return (
    <DatePickerWrapper>
      <FormControl fullWidth>
        <Controller
          name={name}
          control={control}
          rules={{ required: required }}
          render={({ field: { value, onChange: change } }) => (
            <DatePicker
              selected={value ? value : ""}
              showYearDropdown
              showMonthDropdown
              dateFormat="yyyy-MM-dd"
              placeholderText="YYYY-MM-DD"
              customInput={<CustomInput error={Boolean(error)} label={label} />}
              id={name}
              onChange={(e) => {
                if (props?.onChange) {
                  props?.onChange(e)
                }
                return change(e)
              }}
              {...rest}
            />
          )}
        />
        <FormFieldMessage color="error.main" error={error} />
      </FormControl>
    </DatePickerWrapper>
  )
}

ReactDate.defaultProps = {
  label: "",
  name: "input-text",
  control: {},
  required: false,
  error: null,
  placeholder: ""
}

ReactDate.prototype = {
  label: Proptype.string,
  name: Proptype.string,
  control: Proptype.object,
  required: Proptype.bool,
  error: Proptype.object,
  placeholder: Proptype.string,
  onChange: Proptype.func,
}

export default ReactDate
