// ** Third Party Imports
import * as yup from "yup"

// Add method
yup.addMethod(yup.string, "stripEmptyString", function () {
  return this.transform((value) => (value === "" ? undefined : value))
})

yup.addMethod(yup.number, "stripEmptyNumber", function () {
  return this.transform((value) => {
    return (value === "" ? undefined : value)
  })
})

export default yup