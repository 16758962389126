// ** MUI Imports
import { Typography } from "@mui/material"
import Grid from "@mui/material/Grid"
import DataGridTable from "components/UserArea/__Shared/DataGridTable"
import { AbilityContext } from "context/Can"
import { useCallback, useContext, useEffect, useState } from "react"
import Uri from "services/Uri"

import Comm from "services/Comm"
import Catch401 from "middlewares/Catch401"
import Store from "store"
import { SharedModalErrorMessageShow } from "store/actions"

import ngn from "engine/DataGridTable"

// ** Third Party Imports
import Yup from "utils/Yup"

const StoreCategory = () => {

  // ** States
  const [categoryOptions, setCategoryOptions] = useState([])

  // ** Hooks
  const ability = useContext(AbilityContext)

  const fetchParentCategories = useCallback(() => {
    // Filter & Limit
    const limit = 1000
    const filter = [{
      field: "parent_id",
      condition: "equals",
      search: 0
    }]
    Comm.request({
      url: Uri.storeCategory(),
      method: "post",
      data: { filter, limit }
    }).then((res) => {
      setCategoryOptions(res.data.data.map((x) => {
        return { value: x.id, label: x.name }
      }))
    }).catch(Catch401((err) => {
      Store.dispatch(SharedModalErrorMessageShow(err.response.data.message))
    }))
  },[])

  useEffect(() => {
    fetchParentCategories()
  }, [fetchParentCategories])

  ngn.collection.callback = function(){
    fetchParentCategories()
  }

  // ** Table columns
  const fields = [
    //{ value: "id", label: "Id" },
    { flex: 1, field: "name", headerName: "Name", schema: Yup.string().required("Name field is required"), fieldType: "string" },
    {
      flex: 1,
      field: "parent_id",
      headerName: "Parent Category",
      renderCell: ({ row }) => {
        const { parent_category } = row
        return <Typography noWrap variant="body2">{parent_category ? parent_category : ""}</Typography>
      },
      schema: Yup.number().stripEmptyNumber().default(0),
      fieldType: "select",
      options: categoryOptions
    },
  ]

  return (
    <Grid container>
      <Grid item xs={12}>
        <DataGridTable
          fields={fields}
          title="Store Categories"
          subtitle="Store Categories are hierarchical, you can create sub-categories. Each category can have child categories. By selecting parent category become a child category of selected parent category."
          source={Uri.storeCategory()}
          canEdit={ability?.can("update", "categories")}
          canDelete={ability?.can("delete", "categories")}
          canAdd={ability?.can("create", "categories")}
        />
      </Grid>
    </Grid>
  )
}
export default StoreCategory
