const ImportTypes = [
  {
    label: "None",
    value: "none"
  },
  {
    label: "Users import from CSV",
    value: "users"
  },
  {
    label: "Stores import from CSV",
    value: "stores"
  }
]

export default ImportTypes