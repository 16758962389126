import SearchUsersByText from "engine/__Shared/Actions/onSearch"
import ShowSoftDeletedUsers from "engine/Users/Actions/onShowDeleted"
import SortUsers from "engine/__Shared/Actions/onSort"
import FilterUsers from "engine/__Shared/Actions/onFilter"
import MultipleSelectUsers from "engine/Users/Actions/onSelect"
import ChangeUserListPageLimit from "engine/__Shared/Actions/onChangePageLimit"
import ChangeColumnVisiblity from "engine/__Shared/Actions/onChangeColumnVisiblity"
import ChangeUserListPage from "engine/__Shared/Actions/onChangePage"
import ImpersonateUserProfile from "engine/Users/Actions/onImpersonate"
import VisitUserDetail from "engine/Users/Actions/onVisitUserDetail"
import EditMultipleUserProfile from "engine/Users/Actions/onMultipleEdit"
import SendEmailToSelectedUsers from "engine/Users/Actions/onMail"
import onConfirmDeleteUser from "engine/Users/Actions/onConfirmDelete"
import MultipleUserDelete from "engine/Users/Actions/onMultipleUserDelete"
import DeleteUser from "engine/Users/DeleteUser"

import GetUserCollection from "engine/Users/Collection/GetUserCollection"

import GetUserDetail from "engine/Users/Details/GetUserDetail"

import General from "configs/General"
import Loader from "engine/__Shared/Objects/Loader"
import ObjectSubmitButton from "engine/__Shared/Objects/SubmitButton"

//** Add new User */
import AddUserStoreChanges from "engine/Users/AddUser/StoreChanges"
import AddUserResetModal from "engine/Users/AddUser/ResetModal"
import AddUserConfirm from "engine/Users/AddUser/Confirm"
import AddUserCancel from "engine/Users/AddUser/Cancel"
import AddUserOnEscape from "engine/Users/AddUser/OnEscape"

//** Multiple User Edit */
import MultipleUserEditStoreChanges from "engine/Users/MultipleUserEdit/StoreChanges"
import MultipleUserEditResetModal from "engine/Users/MultipleUserEdit/ResetModal"
import MultipleUserEditConfirm from "engine/Users/MultipleUserEdit/MultipleEditConfirm"
import MultipleUserEditCancel from "engine/Users/MultipleUserEdit/Cancel"
import MultipleUserEditOnEscape from "engine/Users/MultipleUserEdit/OnEscape"

//** Edit User */
import EditUserStoreChanges from "engine/Users/EditUser/StoreChanges"
import EditUserOnEscape from "engine/Users/EditUser/OnEscape"
import EditUserResetModal from "engine/Users/EditUser/ResetModal"
import EditUserConfirm from "engine/Users/EditUser/Confirm"
import EditUserCancel from "engine/Users/EditUser/Cancel"
import DeleteUsers from "engine/Users/DeleteUsers"

//** Request for User Meta */
import RequestUserMetaStoreChanges from "engine/Users/RequestUserMeta/StoreChanges"
import RequestUserMetaOnEscape from "engine/Users/RequestUserMeta/OnEscape"
import RequestUserMetaResetModal from "engine/Users/RequestUserMeta/ResetModal"
import RequestUserMetaConfirm from "engine/Users/RequestUserMeta/Confirm"
import RequestUserMetaCancel from "engine/Users/RequestUserMeta/Cancel"
import RequestUserMetaDelete from "engine/Users/RequestUserMeta/Delete"

import MultipleUpdateRollBack from "engine/Users/MultipleUpdateRollBack"
import MultipleUpdateRollBackConfirm from "engine/Users/Actions/onConfirmMultipleEditRollBack"

const ngn = {}

ngn.collection = {
  limit: General.dataTablePageLimit,
  total: 0,
  page: 1,
  sort: [],
  filter: [],
  selected_rows: [],
  search_text: "",
  fetching: true,
  show_deleted: false,
  visibleColumns: {},

  onSearch: SearchUsersByText.bind(ngn),
  onShowDeleted: ShowSoftDeletedUsers.bind(ngn),
  onSort: SortUsers.bind(ngn),
  onFilter: FilterUsers.bind(ngn),
  onSelect: MultipleSelectUsers.bind(ngn),
  onChangePageLimit: ChangeUserListPageLimit.bind(ngn),
  onChangePage: ChangeUserListPage.bind(ngn),
  onImpersonate: ImpersonateUserProfile.bind(ngn),
  onColumnVisiblityChange: ChangeColumnVisiblity.bind(ngn),
  onView: VisitUserDetail.bind(ngn),
  onMultipleEdit: EditMultipleUserProfile.bind(ngn),
  onMail: SendEmailToSelectedUsers.bind(ngn),
  onDeleteConfirm: onConfirmDeleteUser.bind(ngn),
  onMultipleUpdateRollBackConfirm: MultipleUpdateRollBackConfirm.bind(ngn),
  onMultipleDelete: MultipleUserDelete.bind(ngn),

  setCurrentPage: null,
  setSelectedUser: null,

  delete: {
    selected: null,
    onSubmit: DeleteUser.bind(ngn)
  },

  multipleDelete: {
    selected: null,
    onSubmit: DeleteUsers.bind(ngn)
  },

  multipleEditRollBack: {
    selected: {id: null, model_id: null},
    onSubmit: MultipleUpdateRollBack.bind(ngn)
  },

  fetchData: GetUserCollection.bind(ngn),

}

ngn.addUser = {
  storeChanges: AddUserStoreChanges.bind(ngn),
  loader: Loader(),
  resetAddUserModal: AddUserResetModal.bind(ngn),
  confirm: AddUserConfirm.bind(ngn),
  cancel: AddUserCancel.bind(ngn),
  onEscape: AddUserOnEscape.bind(ngn),
  setErrors: null,
  submitButton: ObjectSubmitButton(),
  resetForm: null,
  showModal: null,
  hideModal: null,
}

ngn.multipleUserEdit = {
  selected_rows: [],
  resetMultipleUserEditModal: MultipleUserEditResetModal.bind(ngn),
  confirm: MultipleUserEditConfirm.bind(ngn),
  cancel: MultipleUserEditCancel.bind(ngn),
  onEscape: MultipleUserEditOnEscape.bind(ngn),
  setErrors: null,
  resetForm: null,
  showModal: null,
  hideModal: null,

  multipleEditConfirm: {
    selectedData: null,
    onSubmit: MultipleUserEditStoreChanges.bind(ngn)
  }
}

ngn.editUser = {
  selected_rows: [],
  loader: Loader(),
  confirm: EditUserConfirm.bind(ngn),
  onEscape: EditUserOnEscape.bind(ngn),
  cancel: EditUserCancel.bind(ngn),
  resetEditUserModal: EditUserResetModal.bind(ngn),
  storeChanges: EditUserStoreChanges.bind(ngn),
  submitButton: ObjectSubmitButton(),
  setErrors: null,
  resetForm: null,
  showModal: null,
  hideModal: null
}

ngn.requestUserMeta = {
  confirm: RequestUserMetaConfirm.bind(ngn),
  onEscape: RequestUserMetaOnEscape.bind(ngn),
  cancel: RequestUserMetaCancel.bind(ngn),
  resetRequestUserMetaModal: RequestUserMetaResetModal.bind(ngn),
  storeChanges: RequestUserMetaStoreChanges.bind(ngn),
  delete: RequestUserMetaDelete.bind(ngn),
  resetForm: null,
  showModal: null,
  hideModal: null
}

ngn.user = {
  user_id: null,
  loading: Loader(),
  fetchData: GetUserDetail.bind(ngn)
}

export default ngn