import * as actionType from "store/actions/actionTypes"
import StoreTemplate from "store/StoreTemplate"

import DefaultToFalse from "store/defaults/DefaultToFalse"
import DefaultToObject from "store/defaults/DefaultToObject"
import DefaultToStringOnNull from "store/defaults/DefaultToStringOnNull"
import DefaultToTrue from "store/defaults/DefaultToTrue"

const container = DefaultToObject()

const defaultState = function () {
  return {
    "id": DefaultToStringOnNull(),
    "first_name": DefaultToStringOnNull(),
    "last_name": DefaultToStringOnNull(),
    "full_name": DefaultToStringOnNull(),
    "email": DefaultToStringOnNull(),
    "has_password": DefaultToTrue(),
    "email_verified": DefaultToFalse(),
    // "is_linked_to_apple": false
  }
}

const User = StoreTemplate(defaultState(), container)

container[actionType.ACTION_USER_GLOBAL_RESET] = function (state) {
  return defaultState()
}

container[actionType.ACTION_USER_SET_DATA] = function (state, payload) {
  return {
    ...state,
    ...payload.data
  }
}

container[actionType.ACTION_USERS_SET_PERMISSONS] = function (state, payload) {
  state.permissions = payload.permissions
  return {
    ...state,
  }
}

export default User
