import {useEffect} from "react"
import History from "services/History"
import Uri from "services/Uri"

const Offline = function () {

  useEffect(() => {
    if (navigator.onLine) {
      History.replace(Uri.home())
    }
  }, [])

  return (
    <h1>You"re Offline</h1>
  )
}

export default Offline
