import Req from "requests"
import Store from "store"
import { SharedModalConfirmActionHide, UserUpdate, } from "store/actions"

const MultipleUpdateRollBack = function () {
  const {id, model_id} = this.collection.multipleEditRollBack.selected
  Req.MultipleUpdateRollBack(id, model_id)
    .then(() => {
      Store.dispatch(SharedModalConfirmActionHide())
      const user = Store.getState().Users.selected
      const oldValue = JSON.parse(user.activity_log.new_values)
      oldValue.forEach((value) => {
        let key = Object.keys(value)[0]
        let values = Object.values(value)[0]
        user[key] = values
      })
      Store.dispatch(UserUpdate(user))
    })
    .catch((error) => {
      //console.log(error)
    })
}

export default MultipleUpdateRollBack
