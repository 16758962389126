// ** MUI Imports
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import PageHeader from "components/UserArea/__Shared/PageHeader"
import DataTable from "components/UserArea/ControlPanel/__Shared/DataTable"
import { AbilityContext } from "context/Can"
import { useContext } from "react"
const ExperiencePoints = () => {
  const fields = [
    //{ value: "id", label: "Id" },
    { value: "action", label: "Automatic Action", type: "string" },
    { value: "points", label: "Points", type: "number" },
  ]

  // ** Hooks
  const ability = useContext(AbilityContext)

  return (
    <Grid container>
      <Grid item xs={12}>
        <DataTable
          title="Experience Points"
          source="/settings/experience-points"
          fields={fields}
          pageHeader={
            <PageHeader
              title={<Typography variant="h5">Experience Points</Typography>}
              subtitle={
                <Typography variant="body2">
                  SCORERS will gain the amount of points issued for each complete task or milestone. SCORERS can also lose points from not completing tasks correctly.
                </Typography>
              }
            />
          }
          canEdit={ability?.can("update", "experience-points")}
          canDelete={ability?.can("delete", "experience-points")}
          canAdd={ability?.can("create", "experience-points")}
        />
      </Grid>
    </Grid>
  )
}
export default ExperiencePoints
