import Req from "requests"
import Store from "store"
import { SharedModalErrorMessageShow, UsersSetCollection } from "store/actions"

const GetUserCollection = function () {

  const page = this.collection.page
  const sort = this.collection.sort
  const limit = this.collection.limit
  const filter = this.collection.filter
  const search = this.collection.search_text
  const soft_deleted = this.collection.show_deleted

  this.collection.fetching = true
  Req.GetUsers({page, limit, sort, filter, search, soft_deleted}).then((response) => {
    Store.dispatch(UsersSetCollection(response.collection))
    this.collection.total = response.total_records
    this.collection.fetching = false
  }).catch((error) => {
    Store.dispatch(SharedModalErrorMessageShow(error.message))
    this.collection.fetching = false
  })
}
export default GetUserCollection