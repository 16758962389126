import { yupResolver } from "@hookform/resolvers/yup"
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material"
import yup from "utils/Yup"
import { useFieldArray, useForm } from "react-hook-form"
import { Box } from "@mui/system"
import "react-datepicker/dist/react-datepicker.css"

import { useEffect, useMemo, useState } from "react"
import { Input, Select, Switch } from "components/__Shared/FormControls"
import { Delete } from "mdi-material-ui"
import StringFn from "utils/StringFn"

const schema = {
  meta_title: yup.string().required("Title field is required"),
  is_mandatory: yup.boolean().default(false),
  type: yup.string().required("Type field is required").default("text"),
  meta_key: yup.string(),
  options: yup.string().nullable()
}
const metaData = { meta_title: "", meta_key: "", is_mandatory: false, type: "text", options: null }
const metaDefaultValues = {
  meta_data: [metaData]
}

const ModalRequestExtraInfo = function ({ ngn, onEscape }) {

  const [open, setOpened] = useState(false)
  const [selectedUser, setSelectedUser] = useState(null)

  //** Init default Schema for Validation
  const [validationSchema, setValidationSchema] = useState({})
  const [defaultValues, setDefaultValues] = useState(metaDefaultValues)
  const [userMetaData, setUserMetaData] = useState([])

  const {
    reset,
    watch,
    getValues,
    setValue,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: useMemo(() => {
      return defaultValues
    }, [defaultValues]),
    mode: "onBlur",
    resolver: yupResolver(yup.object().shape(validationSchema)),
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: "meta_data"
  })

  ngn.showModal = function (user_data) {
    const user = JSON.parse(JSON.stringify(user_data)) // Clone the Data object without reference
    setSelectedUser(user)
    let validation = validationSchema
    let metaDataArr = metaDefaultValues.meta_data
    if (user.meta_data.length > 0) {
      for (let u = 0; u < user.meta_data.length; u++) {
        metaDataArr[u] = user.meta_data[u]
        metaDataArr[u].is_mandatory = user.meta_data[u].is_mandatory === "0" ? false : true
        if (user.meta_data[u].options) {
          metaDataArr[u].options = JSON.parse(user.meta_data[u].options).join("\r\n")
        }
      }
      setUserMetaData(user.meta_data)
    } else {
      metaDataArr = [metaData]
    }
    // Set Validation Schema
    let dataValidation = yup.array().of(yup.object().shape(schema)).required("Required field")
    validation = { ...validation, meta_data: dataValidation }
    setValidationSchema(validation)

    // Set Default Value
    metaDefaultValues.meta_data = metaDataArr
    setDefaultValues(metaDefaultValues)

    // Reset Default Value
    reset(metaDefaultValues)

    // Open the Modal
    setOpened(true)
  }

  useEffect(() => {
    if (open) {
      window.addEventListener("keydown", onEscape)
    } else {
      window.removeEventListener("keydown", onEscape)
    }
  }, [open, onEscape])

  ngn.resetForm = function () {
    metaDefaultValues.meta_data = [metaData]
    reset(metaDefaultValues)
    setDefaultValues()
  }

  ngn.hideModal = function () {
    setOpened(false)
  }

  const onConfirm = function (data) {
    const clone = JSON.parse(JSON.stringify(data)) // Clone the Data object without reference
    const metaDataValues = clone.meta_data.map((x) => {
      x.is_mandatory = x.is_mandatory ? "1" : "0"
      x.options = x.options ? x.options.split(/\r?\n/) : null
      x.meta_key = new StringFn(x.meta_title).createSlug().lowercase().result
      x.user_id = selectedUser.id
      return x
    })
    ngn.confirm(metaDataValues)
  }

  const onCancel = function (ev) {
    // console.dir(ev)
    ngn.cancel()
  }

  // Render Fields
  const getError = (field_name, index) => {
    let error = null
    if (errors?.meta_data && errors?.meta_data[index]) {
      error = errors?.meta_data[index][field_name]
    }
    return error
  }

  const removeMetaData = (index) => {
    let uMetaData = userMetaData
    if (uMetaData[index]) {
      const metaId = uMetaData[index].id
      delete uMetaData[index]
      setUserMetaData(uMetaData)
      ngn.delete(metaId)
    }
    remove(index)
  }

  return (
    <Dialog
      scroll="body"
      fullWidth
      maxWidth="md"
      onClose={onCancel}
      open={open}
      disableEscapeKeyDown
    >
      <DialogTitle sx={{ pt: 12, mx: "auto", textAlign: "center" }}>
        <Typography variant="h4" component="span" sx={{ mb: 2 }}>
          Request For Extra Information
        </Typography>
        <Typography variant="body2">Getting extra information from user.</Typography>
      </DialogTitle>
      <DialogContent>
        <Box
          component="form"
          noValidate
          autoComplete="off"
          sx={{ mt: 4 }}
          onSubmit={handleSubmit(onConfirm)}
        >
          {fields.map((item, index) => {
            return (
              <Grid container spacing={5} key={item.id} alignItems="center" sx={{ mb: 8 }}>
                <Grid item>
                  <Typography variant="body2" sx={{ fontWeight: 600 }}>
                    {index + 1}.
                  </Typography>
                </Grid>
                <Grid item xs={4} sm={4}>
                  <Input
                    label="Title"
                    name={`meta_data.${index}.meta_title`}
                    control={control}
                    placeholder="Title"
                    error={getError("meta_title", index)}
                  />
                </Grid>
                <Grid item>
                  <Select
                    label={"Type"}
                    name={`meta_data.${index}.type`}
                    defaultValue={getValues(`meta_data.${index}.type`) ? getValues(`meta_data.${index}.type`) : []}
                    multiple={false}
                    setValue={setValue}
                    control={control}
                    error={getError("type", index)}
                    options={[{ label: "Text", value: "text" }, { label: "Select", value: "select" }]}
                    placeholder="Select Type"
                  />
                </Grid>
                <Grid item>
                  <Switch name={`meta_data.${index}.is_mandatory`} label="Mandatory?" control={control} error={getError("is_mandatory", index)} />
                </Grid>
                <Grid item>
                  <Tooltip title="Delete" placement="top">
                    <IconButton
                      color="error"
                      aria-label="delete"
                      size="medium"
                      onClick={() => removeMetaData(index)}
                    >
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </Grid>
                {
                  watch(`meta_data.${index}.type`) === "select" &&
                  <>
                    <Grid item xs={11} sm={11} sx={{ ml: 8 }}>
                      <Input
                        label="Options"
                        multiline={true}
                        rows={3}
                        name={`meta_data.${index}.options`}
                        control={control}
                        placeholder="Options"
                        error={getError("options", index)}
                        helperText="One choice per line"
                      />
                    </Grid>
                  </>
                }
              </Grid>
            )
          })}
          <Box
            className="demo-space-x"
            sx={{
              "&>:last-child": { mr: 0 },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: 3,
            }}
          >
            <Button size="large" color="secondary" type="button" variant="contained" onClick={() => {
              append({ meta_title: "", meta_key: "", is_mandatory: false, type: "text", options: null })
            }}>
              Add New
            </Button>
            <Button size="large" type="submit" variant="contained">
              Submit
            </Button>
            <Button
              size="large"
              variant="outlined"
              color="secondary"
              onClick={onCancel}
            >
              Discard
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default ModalRequestExtraInfo
