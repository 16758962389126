import Req from "requests"
import Store from "store"
import { SharedModalErrorMessageShow, UserSetMetaData } from "store/actions"

const Delete = function (id) {
  Req.DeleteUserMeta({id})
    .then(() => {
      const meta_data = Store.getState().Users.selected.meta_data.filter(u => u.id !== id)
      Store.dispatch(UserSetMetaData(meta_data))
    })
    .catch((error) => {
      Store.dispatch(SharedModalErrorMessageShow(error.message))
    })
}
export default Delete
