import Comm from "services/Comm"
import Uri from "services/Uri"
import Password from "components/__Shared/Input/Password"
import Submit from "components/__Shared/Input/Submit"
import FormFieldMessage from "components/__Shared/FormFieldMessage"

// ** MUI Components
import {
  Box,
  FormControl,
  InputLabel,
  Typography,
} from "@mui/material"

// ** Third Party Imports
import * as yup from "yup"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

import { Link, useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
import { useEffect, useRef } from "react"

// ** Icons Imports
import ChevronLeft from "mdi-material-ui/ChevronLeft"

// ** Default Values
const defaultValues = {
  password: "",
  confirm_password: ""
}

const schema = yup.object().shape({
  password: yup.string().min(5).required(),
  confirm_password: yup.string().min(5).required().oneOf([yup.ref("password")], "Both passwords do not match."),
})


const Form = function ({ ngn, requestToken }) {

  const Signed = useSelector(state => state.Auth.signed)
  const navigate = useNavigate()
  const userData = useRef({})

  useEffect(function () {
    if (!Signed) {
      ngn.submit.setDisabled()
      // api request
      Comm.request({
        url: Uri.passwordResetsToken({ "token": requestToken }),
        method: "get"
      }).then((response) => {
        //console.log(response)
        userData.current = response.data.data
        ngn.submit.setEnabled()
      }).catch((error) => {
        //console.dir(error)
        navigate(Uri.signIn(), { replace: true })
      })
    }
  }, [ngn.submit, requestToken, navigate, Signed])

  const {
    control,
    setError,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues,
    mode: "onBlur",
    resolver: yupResolver(schema)
  })

  const onSubmitHandler = (data) => {
    const { password } = data
    ngn.submit.setDisabled()
    // api request
    Comm.request({
      url: Uri.usersID({ "id": userData.current.user_id }),
      method: "patch",
      data: {
        password_reset_token: requestToken,
        password
      }
    }).then((res) => {
      // console.dir(res)
      navigate(Uri.resetPasswordSuccess(), { replace: true })
    }).catch((error) => {
      // console.dir(error)
      if (error.response?.data?.errors) {
        const errors = error.response.data.errors
        if (errors.password) {
          setError("password", {
            type: "manual",
            message: errors.password[0]
          })
        }
      } else if (error.response?.data?.message) {
        setError("password", {
          type: "manual",
          message: error.response.data.message
        })
      }
      ngn.submit.setEnabled()
    })
  }

  return (
    <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmitHandler)}>
      {/* Password Field  */}
      <FormControl fullWidth sx={{ mb: 4 }}>
        <InputLabel htmlFor="auth-login-v2-password" error={Boolean(errors.password)}>
          New Password
        </InputLabel>
        <Controller
          name="password"
          control={control}
          rules={{ required: true }}
          render={({ field: { value, onChange, onBlur } }) => (
            <Password value={value} onChange={onChange} onBlur={onBlur} error={errors.password} />
          )}
        />
        <FormFieldMessage color="error.main" error={errors.password} />
      </FormControl>
      {/* Confirm Password Field  */}
      <FormControl fullWidth sx={{ mb: 4 }}>
        <InputLabel htmlFor="auth-login-v2-confirm_password" error={Boolean(errors.confirm_password)}>
          Confirm new password
        </InputLabel>
        <Controller
          name="confirm_password"
          control={control}
          rules={{ required: true }}
          render={({ field: { value, onChange, onBlur } }) => (
            <Password value={value} onChange={onChange} onBlur={onBlur} error={errors.confirm_password} />
          )}
        />
        <FormFieldMessage color="error.main" error={errors.confirm_password} />
      </FormControl>
      {/* Submit button  */}
      <Submit fullWidth ngn={ngn.submit} title="Reset password" />
      {/* Back to Login button  */}
      <Box
        sx={{ mb: 4, display: "flex", alignItems: "center", flexWrap: "wrap", justifyContent: "center" }}
      >
        <Typography
          component={Link} to={Uri.signIn()}
          sx={{ display: "flex", alignItems: "center", color: "primary.main", justifyContent: "center" }}
        >
          <ChevronLeft sx={{ mr: 1.5, fontSize: "2rem" }} />
          <span>Back to login</span>
        </Typography>
      </Box>
    </form>
  )
}

export default Form
