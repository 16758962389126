import * as actionType from "store/actions/actionTypes"
import StoreTemplate from "store/StoreTemplate"
import DefaultToFalse from "store/defaults/DefaultToFalse"
const container = {}

const defaultState = function () {
  return {
    signed: DefaultToFalse(),
    impersonate: DefaultToFalse(),
  }
}

const Auth = StoreTemplate(defaultState(), container)

container[actionType.ACTION_AUTH_SET_NOT_SIGNED] = function (state) {
  return {
    ...state,
    signed: false,
    impersonate: false
  }
}

container[actionType.ACTION_AUTH_SET_SIGNED] = function (state) {
  return {
    ...state,
    signed: true
  }
}

container[actionType.ACTION_AUTH_SET_IMPERSONATE] = function (state, payload) {
  return {
    ...state,
    impersonate: payload
  }
}

container[actionType.ACTION_AUTH_RESET] = function (state) {
  return defaultState()
}

export default Auth
