import { Grid, Box, Button, Dialog, DialogContent, DialogTitle, Typography, Divider } from "@mui/material"
import { useCallback, useEffect, useState } from "react"

// ** Third Party Imports
import * as yup from "yup"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"


import { Input, Select } from "components/__Shared/FormControls"

import Submit from "components/__Shared/Input/Submit"
import { SharedModalErrorMessageShow } from "store/actions"
import Store from "store"
import Catch401 from "middlewares/Catch401"
import Comm from "services/Comm"
import Uri from "services/Uri"

// ** Default Values
const defaultValues = {
  name: "",
  email: "",
  phone: "",
  website: "",
  address: "",
  category_id: "",
  subcategory_id: ""
}
const showErrors = (field, valueLen, min) => {
  if (valueLen === 0) {
    return `${field} field is required`
  } else if (valueLen > 0 && valueLen < min) {
    return `${field} must be at least ${min} characters`
  } else {
    return ""
  }
}
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const websiteRegExp = /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/
const schema = yup.object().shape({
  name: yup.string().min(2, obj => showErrors("Store name", obj.value.length, obj.min)),
  email: yup.string().nullable(),
  phone: yup.string().matches(phoneRegExp, { message: "Contact number is not valid", excludeEmptyString: true }).nullable().notRequired(),
  website: yup.string().matches(websiteRegExp, { message: "Website is not valid", excludeEmptyString: true }).nullable(),
  address: yup.string().min(2, obj => showErrors("Address", obj.value.length, obj.min)),
  category_id: yup.string().required(obj => showErrors("Category", obj.value.length)),
  subcategory_id: yup.string().nullable(),
})

const ModalAddStore = function ({ ngn, onEscape }) {

  const [open, setOpened] = useState(false)
  const [categories, setCategories] = useState([])
  const [parentCategories, setParentCategories] = useState([])
  const [childCategories, setChildCategories] = useState([])
  const [loading, setLoading] = useState(false)

  // ** Hooks
  const {
    reset,
    setValue,
    setError,
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues,
    mode: "onBlur",
    resolver: yupResolver(schema)
  })

  const fetchAllCategories = useCallback(() => {
    // Filter & Limit
    const limit = 1000
    const filter = []
    Comm.request({
      url: Uri.storeCategory(),
      method: "post",
      data: { filter, limit }
    }).then((res) => {
      setCategories(res.data.data)
      setParentCategories(res.data.data.filter(p => p.parent_id === 0).map(x => {
        return { label: x.name, value: x.id }
      }))
    }).catch(Catch401((err) => {
      Store.dispatch(SharedModalErrorMessageShow(err.response.data.message))
    }))
  }, [])

  useEffect(() => {
    if (open) {
      fetchAllCategories()
    }
  }, [fetchAllCategories, open])

  useEffect(() => {
    if (open) {
      window.addEventListener("keydown", onEscape)
    } else {
      window.removeEventListener("keydown", onEscape)
    }
  }, [open, onEscape])

  ngn.resetForm = function () {
    reset()
    ngn.submitButton.reset()
  }

  ngn.setErrors = function (errors) {
    if (errors && Object.keys(errors).length > 0) {
      Object.keys(errors).forEach((e) => {
        setError(e, { type: "manual", message: errors[e] })
      })
    }
  }

  ngn.loader.showLoader = function () {
    setLoading(true)
  }

  ngn.loader.hideLoader = function () {
    setLoading(false)
  }

  ngn.showModal = function () {
    setOpened(true)
  }

  ngn.hideModal = function () {
    setOpened(false)
  }

  const onConfirm = function (data) {
    ngn.confirm(data)
  }

  const onCancel = function (ev) {
    // console.dir(ev)
    ngn.cancel()
  }

  const handleCategoryChange = (e) => {
    setChildCategories(categories.filter(c => c.parent_id === e.target.value).map(x => {
      return { label: x.name, value: x.id }
    }))
  }

  return (
    <Dialog scroll="body" fullWidth maxWidth="sm" onClose={onCancel} open={open} disableEscapeKeyDown>
      <DialogTitle sx={{ pt: 12, mx: "auto", textAlign: "center" }}>
        <Typography variant="h4" component="span" sx={{ mb: 2 }}>
          Add New Store
        </Typography>
        <Typography variant="body2">Add new stores</Typography>
      </DialogTitle>
      <DialogContent sx={{ pb: 12 }}>
        <Box component="form" noValidate autoComplete="off" sx={{ mt: 4 }} onSubmit={handleSubmit(onConfirm)}>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <Typography variant="body2" sx={{ fontWeight: 600 }}>
                1. Store Details
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Input label="Name" name="name" placeholder="Enter Store name" control={control} error={errors.name} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Select
                label="Category"
                name="category_id"
                setValue={setValue}
                control={control}
                error={errors.category_id}
                options={parentCategories}
                placeholder="Select Category"
                change={handleCategoryChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Select
                label="Sub-category"
                name="subcategory_id"
                setValue={setValue}
                control={control}
                error={errors.subcategory_id}
                options={childCategories}
                placeholder="Select Sub-category"
              />
            </Grid>
            <Grid item xs={12}>
              <Divider sx={{ mb: 0 }} />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" sx={{ fontWeight: 600 }}>
                2. Contact Info
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Input label="Email" placeholder="Enter Email" name="email" control={control} error={errors.email} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Input label="Contact Number" placeholder="Enter Contact Number" name="phone" control={control} error={errors.phone} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Input label="Website" placeholder="Enter Website" name="website" control={control} error={errors.website} />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Input multiline={true} rows={2} label="Address" placeholder="Enter Address" name="address" control={control} error={errors.address} />
            </Grid>
          </Grid>
          <Box className="demo-space-x" sx={{ "&>:last-child": { mr: 0 }, display: "flex", justifyContent: "center", alignItems: "center", mt: 3 }}>
            <Submit ngn={ngn.submitButton} title="Create Store" />
            <Button size="large" variant="outlined" color="secondary" disabled={loading} onClick={onCancel}>
              Discard
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default ModalAddStore