import { Box, Typography } from "@mui/material"
import StringFn from "utils/StringFn"

const GetActivityMessage = ({ old_value, new_value }) => {
  const oldVal = JSON.parse(old_value)
  const newVal = JSON.parse(new_value)
  //console.log(oldVal, newVal)
  const dataKeys = Object.keys(oldVal.length > 0 ? oldVal[0] : oldVal)

  return <Box>
    {
      dataKeys.map((key) => {
        return (
          key !== "password" ? <Typography style={{ fontSize: 14 }} key={key}>{new StringFn(key).removeUnderscore().capitalizeFirstLetter().result} updated from {oldVal[0][key]} to {newVal[0][key]}</Typography>
            : <Typography style={{ fontSize: 14 }} key={key}>Password updated</Typography>
        )
      })}
  </Box>
}
export default GetActivityMessage