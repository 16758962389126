import { TableContainer, Table, TableHead, TableRow, TableBody, FormControl, Select, MenuItem, Paper, Grid } from "@mui/material"
import FormFieldMessage from "components/__Shared/FormFieldMessage"
import { Controller, useForm } from "react-hook-form"
import TableCell, { tableCellClasses } from "@mui/material/TableCell"
import { styled } from "@mui/material/styles"
import { useEffect } from "react"
import Submit from "components/__Shared/Input/Submit"
import CircularLoader from "components/__Shared/CircularLoader"
import Togglers from "store/togglers"

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.common.black,
    backgroundColor: theme.palette.customColors.bodyBg
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}))

const FieldMappingForm = ({ ngn, headings, db_columns }) => {
  //const db_columns = useSelector(state => state.ImportData.columns)
  const { handleSubmit, control, formState: { errors, isValid } } = useForm()
  const onSubmit = data => {
    ngn.execute.finalizeProcess(data)
  }
  const convertToName = (name) => {
    return name.replace(/_/g, " ")
  }
  useEffect(() => {
    ngn.execute.submitButton.setEnabled()
  }, [isValid, ngn.execute.submitButton])
  return (
    <form key={1} onSubmit={handleSubmit(onSubmit)}>
      <TableContainer component={Paper} sx={{ mt: 5 }}>
        <Table aria-label="Column Mapping">
          <TableHead>
            <TableRow>
              <StyledTableCell width={"25%"}><strong>Field</strong></StyledTableCell>
              <StyledTableCell><strong>CSV Column</strong></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {db_columns.map(index => {
              let csv_heading =  Togglers.RevertRenameColumnImportData(ngn.type, index)
              return (
                <TableRow
                  key={index}
                  sx={{
                    "&:last-of-type td, &:last-of-type th": {
                      border: 0
                    }
                  }}
                >
                  <TableCell style={{ textTransform: "capitalize" }} component="th" scope="row">
                    {convertToName(index)}
                  </TableCell>
                  <TableCell>
                    <FormControl fullWidth size="small" variant="standard" sx={{ maxWidth: 250 }}>
                      <Controller
                        name={index}
                        control={control}
                        defaultValue={csv_heading}
                        rules={{ required: true }}
                        render={({ field: { value, onChange } }) => (
                          <Select
                            autoWidth
                            value={csv_heading}
                            label={index}
                            onChange={onChange}
                            error={Boolean(errors[index])}
                            id={index}
                            style={{ textTransform: "capitalize" }}
                          >
                            {
                              headings.map(field_name => {
                                return <MenuItem key={field_name} value={field_name} style={{ textTransform: "capitalize" }}>{convertToName(field_name)}</MenuItem>
                              })
                            }
                          </Select>
                        )}
                      />
                      <FormFieldMessage color="error.main" error={errors[index]} />
                    </FormControl>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid item xs={12} sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
        <CircularLoader size={30} ngn={ngn.execute.loading} />
        <Submit title="Finish the Import" ngn={ngn.execute.submitButton} />
      </Grid>
    </form>
  )
}

export default FieldMappingForm