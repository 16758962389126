import { Card, Grid, Typography } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import General from "configs/General"
import ngn from "engine/ImportAndRollback/Rollback"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import PageHeader from "components/UserArea/__Shared/PageHeader/index"
import RollbackColumns from "components/UserArea/ImportAndRollback/Rollback/RollbackColumns"
import ConfirmActionModal from "components/__Shared/Modals/ConfirmActionModal"

const Rollback = () => {
  const rollBackData = useSelector((state) => state.Rollback)
  const [page,setPage] = useState(0)
  ngn.collection.setCurrentPage = (page) => {
    setPage(page)
  }
  useEffect(() => {
    ngn.collection.fetchData()
  }, [])
  return (
    <Grid>
      <PageHeader title={<Typography variant="h5">Rollback</Typography>} />
      <ConfirmActionModal/>
      <Card sx={{ mt: 4 }}>
        <DataGrid
          rows={Object.values(rollBackData.collection)}
          autoHeight
          rowCount={ngn.collection.total}
          columns={RollbackColumns}
          pageSize={ngn.collection.limit}
          page={page}
          onPageChange={ngn.collection.onChangePage}
          onPageSizeChange={ngn.collection.onChangePageLimit}
          filterMode="server"
          onFilterModelChange={ngn.collection.onFilter}
          sortingMode="server"
          onSortModelChange={ngn.collection.onSort}
          paginationMode="server"
          disableSelectionOnClick
          keepNonExistentRowsSelected
          rowsPerPageOptions={General.dataTablePageLimitOptions}
        />
      </Card>
    </Grid>
  )
}
export default Rollback
