const Confirm = function (data) {
  // console.dir(this)

  // Get the values from the form
  //console.log(data)

  // Close the modal and reset the form
  // this.addUser.resetAddUserModal()

  // Store changes
  this.addStore.storeChanges(data)
}

export default Confirm
