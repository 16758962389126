import Store from "store"
import { SharedModalConfirmActionShow } from "store/actions"

const onMultipleUserDelete = function(selected) {
  this.collection.multipleDelete.selected = selected
  Store.dispatch(
    SharedModalConfirmActionShow(
      `${selected.length} Users will be removed. Are you sure?`,
      this.collection.multipleDelete
    )
  )
}
export default onMultipleUserDelete