import * as actionType from "store/actions/actionTypes"
import StoreTemplate from "store/StoreTemplate"

import DefaultToFalse from "store/defaults/DefaultToFalse"
import DefaultToObject from "store/defaults/DefaultToObject"
import DefaultToStringOnNull from "store/defaults/DefaultToStringOnNull"
import DefaultToTrue from "store/defaults/DefaultToTrue"
import DefaultToZero from "store/defaults/DefaultToZero"

const container = DefaultToObject()

const defaultState = function () {
  return {
    step: DefaultToZero(),
    type: DefaultToStringOnNull(),
    columns: DefaultToStringOnNull(),
    csv_data: DefaultToStringOnNull(),
    loading: DefaultToFalse(),
    next_button_disabled: DefaultToTrue(),
    failed_rows_count: DefaultToZero(),
    failed_rows: DefaultToStringOnNull(),
    success_rows_count: DefaultToZero(),
    total_rows_count: DefaultToZero(),
    status: DefaultToFalse()
  }
}

const ImportData = StoreTemplate(defaultState(), container)

container[actionType.ACTION_IMPORT_DATA_GLOBAL_RESET] = function (state) {
  return defaultState()
}

container[actionType.ACTION_SET_IMPORT_DATA_STEP] = function (state, payload) {
  state.step = payload
  return {
    ...state,
  }
}

container[actionType.ACTION_SET_IMPORT_DATA_COLUMNS] = function (state, payload) {
  state.columns = payload
  return {
    ...state,
  }
}

container[actionType.ACTION_SET_IMPORT_DATA_TYPE] = function (state, payload) {
  state.type = payload
  return {
    ...state,
  }
}

container[actionType.ACTION_SET_IMPORT_DATA_CSV_DATA] = function (state, payload) {
  state.csv_data = payload
  return {
    ...state,
  }
}

container[actionType.ACTION_SET_IMPORT_DATA_LOADING] = function (state, payload) {
  state.loading = payload
  return {
    ...state,
  }
}

container[actionType.ACTION_SET_IMPORT_DATA_NEXT_BUTTON_DISABLED] = function (state, payload) {
  state.next_button_disabled = payload
  return {
    ...state,
  }
}

container[actionType.ACTION_SET_IMPORT_DATA_FINAL_RESULT] = function (state, payload) {
  state.failed_rows = payload.failed_rows
  state.failed_rows_count = payload.failed_rows_count
  state.success_rows_count = payload.success_rows_count
  state.total_rows_count = payload.total_rows_count
  state.status = payload.status
  return {
    ...state,
  }
}

export default ImportData
