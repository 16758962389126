import { IconButton, Typography } from "@mui/material"
import CustomChip from "components/__Shared/Chip"
import ngn from "engine/ImportAndRollback/Rollback"
import { DatabaseRefresh } from "mdi-material-ui"
const ImportStatusObj = {
  partially_success: "info",
  success: "success",
  failed: "warning",
}

const handleClick = (csv_type, id) => {
  ngn.collection.onRollbackConfirm({ import_type: csv_type, id: id })
}

const RollbackColumns = [
  {
    field: "id",
    headerName: "ID",
    flex: 0.05,
    renderCell: ({ row }) => {
      const { id } = row
      return (
        <Typography variant="body2" noWrap>
          {id}
        </Typography>
      )
    },
  },
  {
    field: "name",
    headerName: "Name",
    flex: 0.15,
    renderCell: ({ row }) => {
      const { name } = row
      return (
        <Typography variant="body2" noWrap>
          {name}
        </Typography>
      )
    },
  },
  {
    field: "status",
    headerName: "Status",
    flex: 0.1,
    renderCell: ({ row }) => {
      const { status } = row
      return (
        <CustomChip
          skin="light"
          size="large"
          label={status.replace("_", " ")}
          color={ImportStatusObj[status]}
          sx={{
            textTransform: "capitalize",
            mr: 1,
            "& .MuiChip-label": { lineHeight: "18px" },
          }}
        />
      )
    },
  },
  {
    field: "csv_type",
    headerName: "CSV Type",
    flex: 0.1,
    renderCell: ({ row }) => {
      const { csv_type } = row
      return <Typography variant="body2">{csv_type}</Typography>
    },
  },
  {
    field: "total_rows",
    headerName: "Total",
    flex: 0.1,
    renderCell: ({ row }) => {
      const { total_rows } = row
      return <Typography variant="body2">{total_rows}</Typography>
    },
  },
  {
    field: "failed_rows",
    headerName: "Failed",
    sortable: false,
    flex: 0.1,
    renderCell: ({ row }) => {
      const { failed_rows } = row
      const failedRows = JSON.parse(failed_rows)
      return (
        <Typography variant="body2">
          {Object.keys(failedRows).length}
        </Typography>
      )
    },
  },
  {
    field: "rollback_btn",
    headerName: "Rollback",
    sortable: false,
    flex: 0.1,
    renderCell: ({ row }) => {
      const { status, csv_type, id } = row
      return <IconButton disabled={status === "failed" || status === "rollback"} color="error" onClick={() => handleClick(csv_type, id)}>
        <DatabaseRefresh />
      </IconButton>
    },
  },
]

export default RollbackColumns
