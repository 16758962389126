import Comm from "services/Comm"
import Catch401 from "middlewares/Catch401"

const StoreChanges = function (data) {

  this.addItem.loader.showLoader()
  this.addItem.submitButton.setDisabled()

  Comm.request({
    url: `${this.source}/create`,
    method: "post",
    data: data
  }).then(() => {
    this.collection.fetchData()
    this.addItem.loader.hideLoader()
    this.addItem.modal.hide()
    this.addItem.resetAddItemModal()
    if(this.collection.callback){
      this.collection.callback()
    }
  }).catch(Catch401((err) => {
    this.addItem.loader.hideLoader()
    this.addItem.submitButton.setEnabled()
    this.addItem.setErrors(err.response.data.errors)
  }))

}
export default StoreChanges
