import { Box, Typography } from "@mui/material"
import StoreRowOptions from "./StoreRowOptions"
import CustomChip from "components/__Shared/Chip"
import StoreLogo from "./StoreLogo"
import Uri from "services/Uri"
import { Link } from "react-router-dom"

const StatusColors = {
  claimed: "success",
  unclaimed: "warning",
  processing: "secondary"
}

const StoreColumns = [
  {
    flex: 0.2,
    minWidth: 150,
    field: "name",
    headerName: "Name",
    renderCell: ({ row }) => {
      const { id, name } = row
      return (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <StoreLogo avatar={null} id={id} name={`${name}`} />
          <Box sx={{ display: "flex", alignItems: "flex-start", flexDirection: "column" }}>
            <Typography
              to={Uri.uUserId({ "id": id })}
              noWrap
              component={Link}
              variant="subtitle2"
              sx={{ color: "text.primary", textDecoration: "none" }}
            >
              {`${name}`}
            </Typography>
          </Box>
        </Box>
      )
    },
  },
  {
    flex: 0.1,
    field: "status",
    headerName: "Status",
    renderCell: ({ row }) => {
      const { status } = row
      return (
        <CustomChip
          skin="light"
          size="small"
          label={status}
          color={StatusColors[status]}
          sx={{ textTransform: "capitalize", "& .MuiChip-label": { lineHeight: "18px" } }}
        />
      )
    },
  },
  {
    flex: 0.1,
    field: "phone",
    headerName: "Phone",
    renderCell: ({ row }) => {
      const { phone } = row
      return <Typography variant="body2">{phone}</Typography>
    },
  },
  {
    flex: 0.1,
    field: "email",
    headerName: "Email",
    renderCell: ({ row }) => {
      const { email } = row
      return <Typography variant="body2">{email}</Typography>
    },
  },
  {
    flex: 0.1,
    field: "address",
    headerName: "Address",
    renderCell: ({ row }) => {
      const { address } = row
      return <Typography variant="body2">{address}</Typography>
    },
  },
  {
    flex: 0.05,
    field: "action",
    filterable: false,
    sortable: false,
    headerName: "Actions",
    renderCell: ({ row }) => {
      const { id } = row
      return (
        <StoreRowOptions id={id} />
      )
    },
  },
]

export default StoreColumns