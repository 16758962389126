import * as actionType from "store/actions/actionTypes"
import DefaultToObject from "store/defaults/DefaultToObject"

import StoreTemplate from "store/StoreTemplate"

const container = DefaultToObject()

const defaultState = function () {
  return {
    collection: defaultRollBackData(),
  }
}

const defaultRollBackData = () => {
  return DefaultToObject()
}

const Rollback = StoreTemplate(defaultState(), container)

container[actionType.ACTION_ROLLBACK_SET_DATA] = function (state, payload) {
  const collection = {}
  for (let index in payload.data){
    collection[`${payload.data[index].id}`] = payload.data[index]
  }
  state.collection = {
    ...collection
  }

  return {
    ...state,
  }
}

container[actionType.ACTION_ROLLBACK_GLOBAL_RESET] = function () {
  return defaultState()
}

export default Rollback
