import * as actionType from "store/actions/actionTypes"
import StoreTemplate from "store/StoreTemplate"
import DefaultToObject from "store/defaults/DefaultToObject"
import DefaultToArray from "store/defaults/DefaultToArray"

const container = DefaultToObject()

const defaultState = function () {
  return {
    collection: defaultStores(),
  }
}

const defaultStores = function () {
  return DefaultToArray()
}

const Stores = StoreTemplate(defaultState(), container)

container[actionType.ACTION_STORES_SET_COLLECTION] = function (state, payload) {
  state.collection = payload.collection
  return {
    ...state,
  }
}

container[actionType.ACTION_STORES_GLOBAL_RESET] = function () {
  return defaultState()
}

export default Stores
