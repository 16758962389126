import Req from "requests"
import Store from "store"
import { ImportDataSetStep, ImportDataSetFinalResult, SharedModalErrorMessageShow } from "store/actions"

const FinalizeProcess = function (fields) {
  this.execute.loading.showLoader()
  this.execute.submitButton.setDisabled()
  Req.ProcessCSV({ fields }).then((response) => {
    let result = {
      failed_rows: response.data.failed_rows,
      failed_rows_count: response.data.failed_rows_count,
      success_rows_count: response.data.success_rows_count,
      total_rows_count: response.data.total_rows_count,
      status: response.data.result
    }
    Store.dispatch(ImportDataSetStep(2))
    Store.dispatch(ImportDataSetFinalResult(result))
    this.execute.loading.hideLoader()
    this.execute.submitButton.setEnabled()
    this.step = 2
  }).catch((error) => {
    Store.dispatch(SharedModalErrorMessageShow(error.message))
    this.execute.loading.hideLoader()
    this.execute.submitButton.setEnabled()
  })
}

export default FinalizeProcess