import GroupBy from "utils/GroupBy"

const PermissionsByCategory = function (permissions) {

  const permission_group = GroupBy(permissions, "category")

  let acl_permissions = []
  for (var permission_category in permission_group) {
    const permissions = permission_group[permission_category].map((permission) => {
      return permission.name.slice(0, permission.name.indexOf("-"))
    })
    const permission_ids = permission_group[permission_category].map((permission) => {
      return permission.id
    })
    acl_permissions.push({
      id: permission_category,
      category: permission_category,
      access: permissions,
      permission_ids: permission_ids
    })
  }
  return acl_permissions
}

export default PermissionsByCategory
