import { Link } from "react-router-dom"
import Uri from "services/Uri"
import { getInitials } from "utils/get-initials"
import { styled } from "@mui/material/styles"
import CustomAvatar from "components/__Shared/Avatar"

// ** Styled component for the link for the avatar with image
const AvatarWithImageLink = styled(Link)(({ theme, mr }) => ({
  marginRight: theme.spacing(mr)
}))

// ** Styled component for the link for the avatar without image
const AvatarWithoutImageLink = styled(Link)(({ theme, mr }) => ({
  textDecoration: "none",
  marginRight: theme.spacing(mr)
}))

const StoreLogo = ({ avatar, id, name, size = 34, variant = "rounded", marginRight=1, fontSize=1 }) => {
  return avatar ?
    <AvatarWithImageLink to={Uri.uUserId({ "id": id })} mr={marginRight}>
      <CustomAvatar variant={variant} src={avatar} sx={{ mr: marginRight, width: size, height: size }} />
    </AvatarWithImageLink>
    : <AvatarWithoutImageLink to={Uri.uUserId({ "id": id })} mr={marginRight}>
      <CustomAvatar
        variant={variant}
        skin="light"
        color={"primary"}
        sx={{ mr: marginRight, width: size, height: size, fontSize: `${fontSize}rem` }}
      >
        {getInitials(name).substring(0, 1)}
      </CustomAvatar>
    </AvatarWithoutImageLink>
}

export default StoreLogo