import { Button, Grid, Paper, Table, TableBody, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import TableCell, { tableCellClasses } from "@mui/material/TableCell"
import { styled } from "@mui/material/styles"

// ** Icons Imports
import AlertCircle from "mdi-material-ui/AlertCircle"
import { useSelector } from "react-redux"
import Store from "store"
import { ImportDataGlobalReset, ImportDataSetStep } from "store/actions"

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.common.black,
    backgroundColor: theme.palette.customColors.bodyBg
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}))

const ImportFailed = () => {
  const failed_rows_count = useSelector(state => state.ImportData.failed_rows_count)
  const success_rows_count = useSelector(state => state.ImportData.success_rows_count)
  const total_rows_count = useSelector(state => state.ImportData.total_rows_count)

  const failed_rows = useSelector(state => state.ImportData.failed_rows)
  const csv_data = useSelector(state => state.ImportData.csv_data)

  const displayCSVData = (index) => {
    let csv_record = csv_data.collection[index - 1]
    let errors = failed_rows[index]
    if (csv_record) {
      let _errors = []
      for(let i=0; i<errors.length; i++){
        for (var prop in errors[i]) {
          if (csv_record[prop]) {
            _errors.push([prop, csv_record[prop]].join(": "))
          }
        }
      }
      return _errors.join(", ")
    }
    return ""
  }

  const displayErrorMessage = (index) => {
    let errors = failed_rows[index]
    let _errors = []
    for(let i=0; i<errors.length; i++){
      for (var prop in errors[i]) {
        _errors.push(errors[i][prop])
      }
    }
    return _errors.join(", ")
  }

  const handleRestartProcess = () => {
    Store.dispatch(ImportDataSetStep(0))
    Store.dispatch(ImportDataGlobalReset())
  }

  return (
    <Grid container spacing={0}>
      <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
        <AlertCircle sx={{ width: 50, height: 50, color: "error.main" }} />
        <Typography variant="h5" sx={{ mt: 3 }}>
          Import Completed
        </Typography>
        <Typography variant="body" sx={{ mt: 3 }}>
          Failed to import <strong>{failed_rows_count}</strong> records from the CSV file.
        </Typography>
        <Typography variant="body" sx={{ mt: 3 }}>
          <strong>Total Records:</strong> {total_rows_count} &nbsp;&nbsp;|&nbsp;&nbsp; <strong>Success Records:</strong> {success_rows_count}
        </Typography>
      </Grid>
      <TableContainer component={Paper} sx={{ mt: 5 }}>
        <Table aria-label="Column Mapping">
          <TableHead>
            <TableRow>
              <StyledTableCell><strong>CSV Data</strong></StyledTableCell>
              <StyledTableCell><strong>Reason for failure</strong></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              Object.keys(failed_rows).map((index, record) => (
                <TableRow
                  key={index}
                  sx={{
                    "&:last-of-type td, &:last-of-type th": {
                      border: 0
                    }
                  }}
                >
                  <TableCell component="th" scope="row">
                    {displayCSVData(index)}
                  </TableCell>
                  <TableCell>
                    {displayErrorMessage(index)}
                  </TableCell>
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
      </TableContainer>
      <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", mb: 5, mt: 8 }}>
        <Button variant="outlined" onClick={handleRestartProcess}>Restart the Process</Button>
      </Grid>
    </Grid>
  )
}
export default ImportFailed