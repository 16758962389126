import Card from "@mui/material/Card"
import { useSelector } from "react-redux"
import { useContext, useEffect } from "react"
import ngn from "engine/RolesAndPermission/Permissions"
import PermissionsColumns from "components/UserArea/RolesPermissions/__Shared/PermissionsColumns"
import { DataGrid } from "@mui/x-data-grid"
import PageHeader from "components/UserArea/__Shared/PageHeader"
import { Grid, Typography } from "@mui/material"
import PermissionsToolBar from "components/UserArea/RolesPermissions/__Shared/PermissionsToolBar"
import ModalAddPermission from "components/UserArea/RolesPermissions/Permissions/ModelAddPermission"
import ConfirmActionModal from "components/__Shared/Modals/ConfirmActionModal"
import { AbilityContext } from "context/Can"

const Permissions = () => {
  const permissionsDetails = useSelector((state) => state.Permissions)
  const ability = useContext(AbilityContext)
  //Updating Permissions
  useEffect(() => {
    ngn.collection.fetchData()
  }, [])

  const CustomPermissionsToolBar = () => {
    return <PermissionsToolBar ngn={ngn} ability={ability} />
  }

  return (
    <Grid container>
      <ModalAddPermission ngn={ngn.addPermission} onEscape={ngn.addPermission.onEscape} />
      <ConfirmActionModal/>
      <Grid item xs={12}>
        <PageHeader
          title={<Typography variant="h5">Permissions List</Typography>}
          subtitle={
            <Typography variant="body2">
              A permission definition is collection of access. You can use the built-in permissions or you can create your own custom permissions.
            </Typography>
          }
        />
        <Card sx={{ mt: 4 }}>
          <DataGrid
            autoHeight
            disableColumnSelector
            disableColumnMenu
            hideFooterPagination
            rows={Object.values(permissionsDetails.collection)}
            rowCount={ngn.collection.total}
            loading={ngn.collection.fetching}
            columns={PermissionsColumns}
            disableSelectionOnClick
            sortingMode="server"
            onSortModelChange={ngn.collection.onSort}
            onFilterModelChange={ngn.collection.onFilter}
            filterMode="server"
            keepNonExistentRowsSelected
            sx={{ "& .MuiDataGrid-columnHeaders": { borderRadius: 0 } }}
            components={{ Toolbar: CustomPermissionsToolBar }}
          />
        </Card>
      </Grid>
    </Grid>
  )
}
export default Permissions