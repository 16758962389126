import { Navigate, Route, Routes, useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
import "init"

import Uri from "services/Uri"
import History from "services/History"
import Offline from "components/Errors/Offline"

import SignIn from "components/Authentication/SignIn"
import PasswordResets from "components/Authentication/PasswordResets"
import ResetPassword from "components/Authentication/ResetPassword"
import ResetPasswordSuccess from "components/Authentication/ResetPasswordSuccess"
import SignOut from "components/Authentication/SignOut"
import AuthProtectedRoute from "components/__Shared/AuthProtectedRoute"
import UserArea from "components/UserArea"

import NotAuthorized from "components/Errors/NotAuthorized"
import ErrorMessageModal from "components/__Shared/Modals/ErrorMessageModal"

const App = function () {
  const InitDone = useSelector(state => state.Init.done)

  const navigate = useNavigate()

  // redefinition native replace method
  History.replace = (url, state) => {
    navigate(url, { replace: true, state })
  }

  return (
    InitDone ?
      <>

        <ErrorMessageModal />

        <Routes>
          {/* -------------------------------------------------------------------------------- */}
          {/* Offline Area */}
          <Route path={Uri.offline()} element={<Offline />} />

          {/* -------------------------------------------------------------------------------- */}
          {/* Errors Area */}
          <Route path={Uri.notAuthorized()} element={<NotAuthorized />} />

          {/* -------------------------------------------------------------------------------- */}
          {/* User Restricted Area */}
          <Route path={`${Uri.u()}/*`} element={<AuthProtectedRoute component={UserArea} />} />

          {/* -------------------------------------------------------------------------------- */}
          {/* User Authentication (incl. external Services) */}
          <Route path={Uri.signIn()} element={<SignIn />} />
          <Route path={Uri.passwordResets()} element={<PasswordResets />} />
          <Route path={Uri.resetPassword()} element={<ResetPassword />} />
          <Route path={Uri.resetPasswordSuccess()} element={<ResetPasswordSuccess />} />
          <Route path={Uri.signOut()} element={<SignOut />} />

          {/* -------------------------------------------------------------------------------- */}
          {/* Go to default on no match found */}
          <Route path="*" element={<Navigate to={Uri.u()} replace={true} />} />
        </Routes>
      </>
      :
      null
  )
}

export default App
