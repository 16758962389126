// ** Returns initials from string
export const getInitials = (string) => {
  let initials = string
    .split(/\s/)
    .reduce((response, word) => (response += word.slice(0, 1)), "")
  let finalInitials = ""
  if (initials.length >= 3) {
    finalInitials = initials.replace(initials.charAt(1), "")
  } else {
    finalInitials = initials
  }
  return finalInitials
}
