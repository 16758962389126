// ----------------------------------------------------------------------------------------------------
// User
// ----------------------------------------------------------------------------------------------------

export const ACTION_USER_GLOBAL_RESET = "ACTION_USER_GLOBAL_RESET"
export const ACTION_USER_SET_DATA = "ACTION_USER_SET_DATA"

// ----------------------------------------------------------------------------------------------------
// Auth
// ----------------------------------------------------------------------------------------------------

export const ACTION_AUTH_SET_SIGNED = "ACTION_AUTH_SET_SIGNED"
export const ACTION_AUTH_SET_NOT_SIGNED = "ACTION_AUTH_SET_NOT_SIGNED"
export const ACTION_AUTH_RESET = "ACTION_AUTH_RESET"
export const ACTION_AUTH_SET_IMPERSONATE = "ACTION_AUTH_SET_IMPERSONATE"


// ----------------------------------------------------------------------------------------------------
// Requests
// ----------------------------------------------------------------------------------------------------

export const ACTION_REQUESTS_SET_ACTION = "ACTION_REQUESTS_SET_ACTION"
export const ACTION_REQUESTS_SET_PARAMS = "ACTION_REQUESTS_SET_PARAMS"
export const ACTION_REQUESTS_SET_REQUEST = "ACTION_REQUESTS_SET_REQUEST"
export const ACTION_REQUESTS_READY = "ACTION_REQUESTS_READY"
export const ACTION_REQUESTS_GLOBAL_RESET = "ACTION_REQUESTS_GLOBAL_RESET"


// ----------------------------------------------------------------------------------------------------
// Init
// ----------------------------------------------------------------------------------------------------

export const ACTION_INIT_SET_DONE = "ACTION_INIT_SET_DONE"
export const ACTION_INIT_UNSET_DONE = "ACTION_INIT_UNSET_DONE"


// ----------------------------------------------------------------------------------------------------
// Shared
// ----------------------------------------------------------------------------------------------------

export const ACTION_SHARED_GLOBAL_RESET = "ACTION_SHARED_GLOBAL_RESET"

// modals - error message
export const ACTION_SHARED_MODALS_ERROR_MESSAGE_SHOW = "ACTION_SHARED_MODALS_ERROR_MESSAGE_SHOW"
export const ACTION_SHARED_MODALS_ERROR_MESSAGE_HIDE = "ACTION_SHARED_MODALS_ERROR_MESSAGE_HIDE"

// modals - confirm content action
export const ACTION_SHARED_MODALS_CONFIRM_ACTION_SHOW = "ACTION_SHARED_MODALS_CONFIRM_ACTION_SHOW"
export const ACTION_SHARED_MODALS_CONFIRM_ACTION_HIDE = "ACTION_SHARED_MODALS_CONFIRM_ACTION_HIDE"


// ----------------------------------------------------------------------------------------------------
// Users
// ----------------------------------------------------------------------------------------------------
export const ACTION_USERS_SET_COLLECTION = "ACTION_USERS_SET_COLLECTION"
export const ACTION_USERS_SET_SELECTED_USER = "ACTION_USERS_SET_SELECTED_USER"
export const ACTION_USERS_REMOVE_USER = "ACTION_USERS_REMOVE_USER"
export const ACTION_USERS_REMOVE_MULTIPLE_USERS = "ACTION_USERS_REMOVE_MULTIPLE_USERS"
export const ACTION_USERS_MULTIPLE_UPDATE_ROLLBACK = "ACTION_USERS_MULTIPLE_UPDATE_ROLLBACK"
export const ACTION_USERS_EDIT_USER = "ACTION_USERS_EDIT_USER"
export const ACTION_USERS_SET_PERMISSONS = "ACTION_USERS_SET_PERMISSONS"
export const ACTION_USERS_GLOBAL_RESET = "ACTION_USERS_GLOBAL_RESET"
export const ACTION_USERS_EDIT_DETAILS = "ACTION_USERS_EDIT_DETAILS"
export const ACTION_USERS_ADD_NEW_USER = "ACTION_USERS_ADD_NEW_USER"
export const ACTION_USERS_SET_SELECTED_USER_METADATA = "ACTION_USERS_SET_SELECTED_USER_METADATA"


// ----------------------------------------------------------------------------------------------------
// Import Data
// ----------------------------------------------------------------------------------------------------
export const ACTION_IMPORT_DATA_GLOBAL_RESET = "ACTION_IMPORT_DATA_GLOBAL_RESET"
export const ACTION_SET_IMPORT_DATA_STEP = "ACTION_SET_IMPORT_DATA_STEP"
export const ACTION_SET_IMPORT_DATA_COLUMNS = "ACTION_SET_IMPORT_DATA_COLUMNS"
export const ACTION_SET_IMPORT_DATA_TYPE = "ACTION_SET_IMPORT_DATA_TYPE"
export const ACTION_SET_IMPORT_DATA_CSV_DATA = "ACTION_SET_IMPORT_DATA_CSV_DATA"
export const ACTION_SET_IMPORT_DATA_LOADING = "ACTION_SET_IMPORT_DATA_LOADING"
export const ACTION_SET_IMPORT_DATA_NEXT_BUTTON_DISABLED = "ACTION_SET_IMPORT_DATA_NEXT_BUTTON_DISABLED"
export const ACTION_SET_IMPORT_DATA_FINAL_RESULT = "ACTION_SET_IMPORT_DATA_FINAL_RESULT"

// ----------------------------------------------------------------------------------------------------
// ROLLBACK
// ----------------------------------------------------------------------------------------------------

export const ACTION_ROLLBACK_SET_DATA = "ACTION_ROLLBACK_SET_DATA"
export const ACTION_ROLLBACK_GLOBAL_RESET = "ACTION_ROLLBACK_GLOBAL_RESET"

// ----------------------------------------------------------------------------------------------------
// Roles
// ----------------------------------------------------------------------------------------------------

export const ACTION_ROLES_SET_DATA = "ACTION_ROLES_SET_DATA"
export const ACTION_ROLES_GLOBAL_RESET = "ACTION_ROLES_GLOBAL_RESET"
export const ACTION_ROLES_EDIT_ROLE = "ACTION_ROLES_EDIT_ROLE"

// ----------------------------------------------------------------------------------------------------
// PERMISSIONS
// ----------------------------------------------------------------------------------------------------

export const ACTION_PERMISSIONS_SET_DATA = "ACTION_PERMISSIONS_SET_DATA"
export const ACTION_PERMISSIONS_GLOBAL_RESET = "ACTION_PERMISSIONS_GLOBAL_RESET"

// ----------------------------------------------------------------------------------------------------
// STORES
// ----------------------------------------------------------------------------------------------------

export const ACTION_STORES_SET_COLLECTION = "ACTION_STORES_SET_COLLECTION"
export const ACTION_STORES_GLOBAL_RESET = "ACTION_STORES_GLOBAL_RESET"

// ----------------------------------------------------------------------------------------------------
// DataGrid
// ----------------------------------------------------------------------------------------------------
export const ACTION_DATAGRID_GLOBAL_RESET = "ACTION_DATAGRID_GLOBAL_RESET"
export const ACTION_DATAGRID_SET_USER_COLUMNS = "ACTION_DATAGRID_SET_USER_COLUMNS"