const Confirm = function (data) {
  // console.dir(this)

  // Get the values from the form
  // console.log(data)

  // Close the modal and reset the form
  // this.addPermission.resetAddPermissionModal()

  // Store changes
  this.addPermission.storeChanges(data)
}

export default Confirm
