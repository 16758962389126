import Req from "requests"
import Store from "store"
import { UserUpdate } from "store/actions"

const StoreChanges = function ({
  id,
  first_name,
  last_name,
  phone,
  status,
  gender,
  address,
  date_of_birth,
  role,
}) {
  this.editUser.submitButton.setDisabled()
  this.editUser.loader.showLoader()
  Req.UpdateUser({
    id,
    first_name,
    last_name,
    gender,
    status,
    role,
    address,
    phone,
    date_of_birth,
  })
    .then((response) => {
      Store.dispatch(UserUpdate(response))
      this.editUser.submitButton.setEnabled()
      this.editUser.cancel()
      this.editUser.loader.hideLoader()
    })
    .catch((error) => {
      this.editUser.setErrors(error)
      this.editRole.loader.hideLoader()
      this.editUser.submitButton.setEnabled()
    })
}
export default StoreChanges
