const ActivityLogColumns = [
  {
    minWidth: 600,
    field: "message",
    headerName: "Message",
  },
  {
    minWidth: 100,
    field: "created_at",
    headerName: "Created At",
  },
  {
    field: "rollback_btn",
    headerName: "Rollback",
    minWidth: 100,
  },
]

export default ActivityLogColumns
