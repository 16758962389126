import Store from "store"
import Comm from "services/Comm"
import Catch401 from "middlewares/Catch401"
import { SharedModalConfirmActionHide, SharedModalErrorMessageShow } from "store/actions"

const DeleteItem = function () {
  const { id } = this.collection.delete.selected
  this.collection.loader.showLoader()

  Comm.request({
    url: `${this.source}/${id}`,
    method: "delete",
  }).then(() => {
    this.collection.fetchData()
    this.collection.loader.hideLoader()
    if(this.collection.callback){
      this.collection.callback()
    }
    Store.dispatch(SharedModalConfirmActionHide())
  }).catch(Catch401((err) => {
    this.collection.loader.hideLoader()
    Store.dispatch(SharedModalConfirmActionHide())
    Store.dispatch(SharedModalErrorMessageShow(err.response.data.message))
  }))

}

export default DeleteItem