const Confirm = function (data) {
  //console.log(data)

  // Get the values from the form
  this.requestUserMeta.resetRequestUserMetaModal()

  // Store changes
  this.requestUserMeta.storeChanges(data)
}

export default Confirm
