import Req from "requests"
import Store from "store"
import { UsersSetCollection } from "store/actions"

const StoreChanges = function ({ first_name, last_name, email, password, phone, date_of_birth, gender, address, role }) {
  this.addUser.submitButton.setDisabled()
  this.addUser.loader.showLoader()
  Req.AddUser({ first_name, last_name, email, password, phone, date_of_birth, gender, address, role })
    .then((response) => {
      this.addUser.submitButton.setEnabled()
      let old_users = Store.getState().Users.collection
      const users = [response, ...old_users]
      Store.dispatch(UsersSetCollection(users))
      this.addUser.cancel()
    }).catch((error) => {
      this.addUser.setErrors(error)
      this.addUser.submitButton.setEnabled()
      this.addUser.loader.hideLoader()
    })
}

export default StoreChanges
