import Req from "requests"
import Store from "store"
import { SetPermissionCollection, SharedModalConfirmActionHide, SharedModalErrorMessageShow } from "store/actions"

const DeletePermission = function () {
  const {category, name} = this.collection.delete.selected

  this.collection.fetching = true
  Req.DeletePermission({ category, name })
    .then((response) => {
      let permissions = Store.getState().Permissions.collection
      const new_permissions = Object.values(permissions).filter((p) => p.category !== category)
      Store.dispatch(SharedModalConfirmActionHide())
      Store.dispatch(SetPermissionCollection(new_permissions))
      this.collection.fetching = false
    }).catch((error) => {
      Store.dispatch(SharedModalConfirmActionHide())
      Store.dispatch(SharedModalErrorMessageShow(error.message))
      this.collection.fetching = false
    })
}

export default DeletePermission