import ObjectSubmitButton from "engine/__Shared/Objects/SubmitButton"
import ObjectLoader from "engine/__Shared/Objects/Loader"
import GetDatabaseColumns from "engine/ImportAndRollback/ImportData/GetDatabaseColumns.js"
import ParseFile from "engine/ImportAndRollback/ImportData/ParseFile"
import FinalizeProcess from "engine/ImportAndRollback/ImportData/FinalizeProcess"

const ngn = {}

ngn.type = ""
ngn.step = 0

ngn.execute = {
  loading: ObjectLoader(),
  submitButton: ObjectSubmitButton(),
  onChangeType: GetDatabaseColumns.bind(ngn),
  onParseFile: ParseFile.bind(ngn),
  finalizeProcess: FinalizeProcess.bind(ngn)
}

export default ngn