import General from "configs/General"
import GetRollbackCollection from "engine/ImportAndRollback/Rollback/GetRollBackCollection"
import SortData from "engine/__Shared/Actions/onSort"
import FilterData from "engine/__Shared/Actions/onFilter"
import ChangePageLimit from "engine/__Shared/Actions/onChangePageLimit"
import ChangePage from "engine/__Shared/Actions/onChangePage"

import OnRollbackImport from "engine/ImportAndRollback/Rollback/RollbackImport"
import OnConfirmRollback from "engine/ImportAndRollback/Rollback/Actions/onConfirmRollback"

const ngn = {}

ngn.collection = {
  limit: General.dataTablePageLimit,
  page: 1,
  sort: [],
  filter: [],
  total: 0,
  search_text: "",

  onChangePageLimit: ChangePageLimit.bind(ngn),
  onSort: SortData.bind(ngn),
  onFilter: FilterData.bind(ngn),
  onChangePage: ChangePage.bind(ngn),
  onRollbackConfirm: OnConfirmRollback.bind(ngn),

  fetchData: GetRollbackCollection.bind(ngn),

  rollback: {
    selected: null,
    onSubmit: OnRollbackImport.bind(ngn)
  }
}

export default ngn