import { useNavigate } from "react-router-dom"
import Uri from "services/Uri"
import BlankLayout from "components/__Shared/BlankLayout"
import AllTrueLogo from "components/__Shared/AllTrueLogo"

// ** MUI Components
import {
  Box,
  Button,
  Typography
} from "@mui/material"
import MuiCard from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import { styled } from "@mui/material/styles"

// ** Styled Components
const Card = styled(MuiCard)(({ theme }) => ({
  [theme.breakpoints.up("sm")]: { width: 450 }
}))

const PasswordResets = function () {
  const navigate = useNavigate()

  const onClickHandler = () => {
    navigate(Uri.signIn(), { replace: true })
  }

  return (
    <BlankLayout>
      <Box className="content-center">
        <Card sx={{ zIndex: 1 }}>
          <CardContent sx={{ p: theme => `${theme.spacing(13, 7, 6.5)} !important` }}>
            <Box sx={{ mb: 8, display: "flex", alignItems: "center", justifyContent: "center" }}>
              <AllTrueLogo />
            </Box>
            <Box sx={{ mb: 5 }}>
              <Typography variant="h5" sx={{ mb: 1.5, fontWeight: 600, letterSpacing: "0.18px", textAlign: "center" }}>
                You've updated your password!
              </Typography>
              <Typography variant="body2" sx={{ textAlign: "center" }}>
                Now you can sign in to your ClipFlip account
              </Typography>
            </Box>
            <Button fullWidth size="large" onClick={onClickHandler} variant="contained" sx={{ mb: 7 }}>
              Sign In
            </Button>
          </CardContent>
        </Card>
      </Box>
    </BlankLayout>
  )
}

export default PasswordResets
