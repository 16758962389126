import { getInitials } from "utils/get-initials"
import CustomAvatar from "components/__Shared/Avatar"

const UserAvatar = ({ avatar, id, full_name, size = 34, variant = "rounded", marginRight = 1, fontSize = 1, color = "primary" }) => {
  return avatar ?
    <CustomAvatar variant={variant} src={avatar} sx={{ mr: marginRight, width: size, height: size }} />
    : <CustomAvatar
      variant={variant}
      skin="light"
      color={color}
      sx={{ mr: marginRight, width: size, height: size, fontSize: `${fontSize}rem` }}
    >
      {getInitials(full_name)}
    </CustomAvatar>
}

export default UserAvatar