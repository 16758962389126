import Store from "store"
import { DataGridSetUserColumns } from "store/actions"

const Memo = (function () {
  const ret = {}

  ret.tables = {
    // Users
    "users": {
      "columns": {
        "key": "tbl_users_columns_views",
        "store": (id) => {
          localStorage.setItem(ret.tables.users.columns.key, id)
        }
      }
    }
  }

  const initTableUserColumnsViewsState = function () {
    // views
    let value = localStorage.getItem(ret.tables.users.columns.key)
    if (value === null) {
      value = Store.getState().DataGrid.users.columns
    } else {
      value = JSON.parse(value)
    }
    ret.tables.users.columns.store(value ? JSON.stringify(value) : null)
    Store.dispatch(DataGridSetUserColumns(value))
  }

  // service initialization script
  ret.init = function () {
    initTableUserColumnsViewsState()
  }

  return ret
})()

export default Memo