// ** MUI Imports
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import PageHeader from "components/UserArea/__Shared/PageHeader"
import DataTable from "components/UserArea/ControlPanel/__Shared/DataTable"
import { AbilityContext } from "context/Can"
import { useContext } from "react"
const ReservationDeals = () => {

  const fields = [
    //{ value: "id", label: "Id" },
    { value: "min_experience_point", label: "Min. Experience Point", type: "number" },
    { value: "max_experience_point", label: "Max. Experience Point", type: "number" },
    { value: "required_hours", label: "Required Hours", type: "number" },
  ]

  // ** Hooks
  const ability = useContext(AbilityContext)

  return (
    <Grid container>
      <Grid item xs={12}>
        <DataTable
          title="Reservation Deals"
          source="/settings/reservation-deal"
          fields={fields}
          pageHeader={
            <PageHeader
              title={<Typography variant="h5">Reservation Deals</Typography>}
              subtitle={
                <Typography variant="body2">
                  <strong>DEAL completion time frames</strong> - Once a deal has been reserved by a scorer, they will have a certain amount of time to complete the deal. These completion times will vary based on how many experience points the Scorer has. The more experience points they have, the longer they will have to complete the deal.
                </Typography>
              }
            />
          }
          canEdit={ability?.can("update", "reservation-deal-settings")}
          canDelete={ability?.can("delete", "reservation-deal-settings")}
          canAdd={ability?.can("create", "reservation-deal-settings")}
        />
      </Grid>
    </Grid>
  )
}
export default ReservationDeals
