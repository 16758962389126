import { useSelector } from "react-redux"
import Store from "store"
import { SharedModalConfirmActionHide } from "store/actions"
import { useEffect } from "react"
import { Box, Button, Dialog, DialogContent, DialogTitle, Typography } from "@mui/material"
const ConfirmActionModal = function () {

  const { opened, message, ngn } = useSelector(state => state.Shared.modals.confirm_action)
  useEffect(() => {
    if (opened) {
      window.addEventListener("keydown", closeModalHandler)
    } else {
      window.removeEventListener("keydown", closeModalHandler)
    }
  }, [opened])

  const closeModalHandler = function (ev) {
    Store.dispatch(SharedModalConfirmActionHide())
  }

  const onConfirm = function (ev) {
    if (typeof ngn === "object") {
      ngn.onSubmit(ngn.selected)
    }
  }

  return (
    <Dialog fullWidth maxWidth="xs" onClose={closeModalHandler} open={opened}>
      <DialogTitle sx={{ pt: 10, mx: "auto", textAlign: "center" }}>
        <Typography variant="h4" component="span" color="warning">
        Confirm Action
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ mx: "auto" }}>
        <Box>
          <Typography variant="p" sx={{ mb: 4 }}>
            {message}
          </Typography>
          <Box className="demo-space-x" sx={{ justifyContent: "center", display: "flex", mb: 5, mt: 2, "&>:last-child": { mr: 0 } }}>
            <Button size="large" variant="outlined" color="secondary" onClick={closeModalHandler}>
              Discard
            </Button>
            <Button size="large" type="submit" variant="contained" onClick={() => {
              onConfirm()
            }}>
              Confirm
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default ConfirmActionModal
