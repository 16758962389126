import Catch401 from "middlewares/Catch401"
import Comm from "services/Comm"
import Uri from "services/Uri"

const UpdateRole = async function ({id, name, permissions}) {

  let result = {}
  let error = {
    code: 0,
    message: `Role "${name}" couldn't be updated!`,
    data: null
  }

  await Comm.request({
    url: Uri.role({id}),
    method: "patch",
    data: {
      name,
      permissions
    }
  }).then((res) => {
    // console.dir(res)
    result = res.data.data
  }).catch(Catch401((err) => {
    // console.dir(err)
    error.code = err.response.status
    error.message = err.response.data.message
    error.data = err.response.data.errors
  }))

  if (error.code > 0) {
    return Promise.reject(error)
  }

  return Promise.resolve(result)
}

export default UpdateRole
