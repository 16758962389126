import Init from "requests/Init"
import GetUser from "requests/GetUser"
import GetUsers from "requests/GetUsers"
import GetColumnList from "requests/ImportData/GetColumnList"
import ParseCSV from "requests/ImportData/ParseCSV"
import ProcessCSV from "requests/ImportData/ProcessCSV"
import GetRoles from "requests/GetRoles"
import GetStores from "requests/GetStores"
import GetPermissions from "requests/GetPermissions"
import AddPermission from "requests/AddPermission"
import DeletePermission from "requests/DeletePermission"
import GetRollbackImports from "requests/GetRollbackImports"
import AddRole from "requests/AddRole"
import DeleteRole from "requests/DeleteRole"
import DeleteUser from "requests/DeleteUser"
import DeleteUsers from "requests/DeleteUsers"
import DeleteStore from "requests/DeleteStore"
import UpdateRole from "requests/UpdateRole"
import UpdateUser from "requests/UpdateUser"
import RollbackImport from "requests/RollbackImport"
import ImpersonateUser from "requests/ImpersonateUser"
import EnterImpersonate from "requests/EnterImpersonate"
import LeaveImpersonate from "requests/LeaveImpersonate"
import LeaveImpersonateUser from "requests/LeaveImpersonateUser"
import AddUser from "requests/AddUser"
import MultipleUserUpdate from "requests/MultipleUserUpdate"
import MultipleUpdateRollBack from "requests/MultipleUpdateRollBack"

import AddUserMeta from "requests/AddUserMeta"
import UpdateUserMeta from "requests/UpdateUserMeta"
import DeleteUserMeta from "requests/DeleteUserMeta"

import AddStore from "requests/AddStore"

const Requests = {
  Init,
  GetUser,
  GetUsers,
  GetColumnList,
  ParseCSV,
  ProcessCSV,
  GetRoles,
  GetStores,
  GetPermissions,
  AddPermission,
  DeletePermission,
  GetRollbackImports,
  AddRole,
  DeleteRole,
  DeleteUser,
  DeleteUsers,
  DeleteStore,
  UpdateRole,
  UpdateUser,
  RollbackImport,
  ImpersonateUser,
  EnterImpersonate,
  LeaveImpersonate,
  LeaveImpersonateUser,
  AddUser,
  MultipleUserUpdate,
  MultipleUpdateRollBack,
  AddUserMeta,
  UpdateUserMeta,
  DeleteUserMeta,
  AddStore
}

export default Requests