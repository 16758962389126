const OnFiltering = function (filter) {
  let filters = []
  if (filter.items.length > 0 && filter.items[0].value) {
    filters = filter.items.map((x) => {
      return {
        field: x.columnField,
        condition: x.operatorValue,
        search: x.value,
      }
    })
  }
  this.collection.filter = filters
  this.collection.fetchData()
}
export default OnFiltering