// ** MUI Imports
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import PageHeader from "components/UserArea/__Shared/PageHeader"
import DataTable from "components/UserArea/ControlPanel/__Shared/DataTable"
import { AbilityContext } from "context/Can"
import { useContext } from "react"
const ScorerRank = () => {

  const fields = [
    //{ value: "id", label: "Id" },
    { value: "name", label: "Rank", type: "string" },
    { value: "low", label: "Low", type: "number" },
    { value: "high", label: "High", type: "number" },
  ]

  // ** Hooks
  const ability = useContext(AbilityContext)

  return (
    <Grid container>
      <Grid item xs={12}>
        <DataTable
          title="Scorer Rank"
          source="/settings/scorer-rank"
          fields={fields}
          pageHeader={
            <PageHeader
              title={<Typography variant="h5">Scorer Rank</Typography>}
              subtitle={
                <Typography variant="body2">
                  Experience Points required to obtain rank. Based On the number of experience points they have, this will determine their Scorer Rank.
                </Typography>
              }
            />
          }
          canEdit={ability?.can("update", "scorer-rank-settings")}
          canDelete={ability?.can("delete", "scorer-rank-settings")}
          canAdd={ability?.can("create", "scorer-rank-settings")}
        />
      </Grid>
    </Grid>
  )
}
export default ScorerRank
