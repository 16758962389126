import { AbilityBuilder, Ability } from "@casl/ability"

export const AppAbility = Ability

/**
 * Please define your own Ability rules according to your app requirements.
 * We have just shown Admin and Client rules for demo purpose where
 * admin can manage everything and client can just visit ACL page
 */

const defineRulesFor = (permissions) => {
  const { can, rules } = new AbilityBuilder(AppAbility)
  for(let i=0; i < permissions.length; i++){
    can(permissions[i].access, permissions[i].category)
  }
  return rules
}

export const buildAbilityFor = (permissions) => {
  return new AppAbility(defineRulesFor(permissions), {
    // https://casl.js.org/v5/en/guide/subject-type-detection
    // @ts-ignore
    detectSubjectType: object => object.type
  })
}

export const defaultACLObj = {
  action: "manage",
  subject: "all"
}

export default defineRulesFor
