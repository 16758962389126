import Comm from "services/Comm"
import Uri from "services/Uri"
import Catch401 from "middlewares/Catch401"

const GetPermissions = async function ({ sort = [], search = "" }) {

  let result = {}
  let error = {
    code: 0,
    message: ""
  }

  let collection = []
  let current_page = 1
  let total_records = 0
  let permissions

  permissions = await Comm.request({
    url: Uri.permissions(),
    method: "post",
    data: {
      sort,
      search
    }
  }).then((res) => {
    // console.dir(res)
    current_page = res.data?.meta?.current_page
    total_records = res.data?.meta?.total
    return res.data.data
  }).catch(Catch401((err) => {
    // console.dir(err)
    error.code = err.response.status
    error.message = err.response.data.message
  }, error))

  if (error.code) {
    return Promise.reject(error)
  }

  collection = [
    ...collection,
    ...permissions
  ]

  result.collection = collection
  result.total_records = total_records
  result.current_page = current_page

  return Promise.resolve(result)
}

export default GetPermissions
