import { useState } from "react"
import { CircularProgress } from "@mui/material"

const CircularLoader = ({ ngn, size = 40 }) => {

  const [show, setShow] = useState(false)

  ngn.showLoader = () => {
    setShow(true)
  }
  ngn.hideLoader = () => {
    setShow(false)
  }

  return show ? <CircularProgress sx={{ml:3, mr: 3}} size={size} /> : <></>
}

export default CircularLoader