import * as actionType from "store/actions/actionTypes"
import StoreTemplate from "store/StoreTemplate"

import DefaultToFalse from "store/defaults/DefaultToFalse"
import DefaultToObject from "store/defaults/DefaultToObject"

const container = DefaultToObject()

const defaultState = function () {
  return {
    done: DefaultToFalse()
  }
}

const Init = StoreTemplate(defaultState(), container)

container[actionType.ACTION_INIT_SET_DONE] = function (state) {
  return {
    ...state,
    done: true
  }
}

container[actionType.ACTION_INIT_UNSET_DONE] = function (state) {
  return {
    ...state,
    done: false
  }
}

export default Init
