import Store from "store"
import Log from "utils/Log"
import GetUser from "requests/GetUser"
import Auth from "services/Auth"

import {
  RequestsReady,
  SetPermissionCollection,
  SetRolesCollection,
  UserSetData,
} from "store/actions"
import Togglers from "store/togglers"
import GetRoles from "requests/GetRoles"
import GetPermissions from "requests/GetPermissions"

const Init = async function (params) {
  Log.req("Init()")

  // NOTES
  // 1. The requests sequence here is important; Do not re-arrange requests if you are not at least 280% sure!

  let error = {
    code: 0,
    message: "",
  }

  // --------------------------------------------------------------------------------
  // Initial Synchronous requests
  // --------------------------------------------------------------------------------

  // *** User Personal Info
  await GetUser({ id: Auth.getUserID() })
    .then((res) => {
      // console.dir(res)
      Store.dispatch(UserSetData(res.data))
      Togglers.UserPermissionsByCategory()
    })
    .catch((err) => {
      // console.dir(err)
      error = err
      if (err.code !== 401) {
        // ...
      }
    })

  if (error.code > 0) {
    return
  }

  // *** Roles Info
  await GetRoles({})
    .then((res) => {
      Store.dispatch(SetRolesCollection(res.collection))
    })
    .catch((err) => {
      // console.dir(err)
      error = err
      if (err.code !== 401) {
        // ...
      }
    })

  // *** Permissions Info
  await GetPermissions({})
    .then((res) => {
      const permissions = Togglers.PermissionsByCategory(res.collection)
      Store.dispatch(SetPermissionCollection(permissions))
    })
    .catch((err) => {
      // console.dir(err)
      error = err
      if (err.code !== 401) {
        // ...
      }
    })

  // --------------------------------------------------------------------------------
  // Asynchronous requests
  // --------------------------------------------------------------------------------

  // --------------------------------------------------------------------------------
  // *** Post Setup

  // Good to go...
  Store.dispatch(RequestsReady())
}
export default Init
