import Comm from "services/Comm"
import Uri from "services/Uri"
import Catch401 from "middlewares/Catch401"
import Store from "store"

const ProcessCSV = async function ({ fields }) {

  let result = {}
  let error = {
    code: 0,
    message: ""
  }

  await Comm.request({
    url: Uri.importsProcessCSV(),
    method: "post",
    data: {
      fields,
      csv_data_file_id: Store.getState().ImportData.csv_data.id,
      import_type: Store.getState().ImportData.type
    }
  }).then((res) => {
    result.data = res.data
  }).catch(Catch401((err) => {
    // console.dir(err)
    error.code = err.response.status
    error.message = err.response.data.message
  }, error))

  if (error.code) {
    return Promise.reject(error)
  }

  return Promise.resolve(result)
}

export default ProcessCSV
