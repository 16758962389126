import Comm from "services/Comm"
import Uri from "services/Uri"
import Submit from "components/__Shared/Input/Submit"
import FormFieldMessage from "components/__Shared/FormFieldMessage"

import AlertMessage from "components/__Shared/AlertMessage"

// ** MUI Components
import {
  Box,
  FormControl,
  TextField,
  Typography,
} from "@mui/material"

// ** Icons Imports
import ChevronLeft from "mdi-material-ui/ChevronLeft"

// ** Third Party Imports
import * as yup from "yup"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { Link } from "react-router-dom"
import { useState } from "react"

// ** Default Values
const defaultValues = {
  email: ""
}

const schema = yup.object().shape({
  email: yup.string().email().required()
})


const Form = function ({ ngn }) {

  const [showSuccess, setShowSuccess] = useState(false)

  const {
    control,
    setError,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    defaultValues,
    mode: "onBlur",
    resolver: yupResolver(schema)
  })

  const onSubmitHandler = (data) => {
    const { email } = data
    ngn.submit.setDisabled()
    // api request
    Comm.request({
      url: Uri.passwordResets(),
      method: "post",
      data: {
        email
      }
    }).then((res) => {
      // console.dir(res)
      ngn.submit.setEnabled()
      setShowSuccess(true)
      reset()
    }).catch((error) => {
      // console.dir(error)
      if (error.response?.data?.errors) {
        const errors = error.response.data.errors
        if (errors.email) {
          setError("email", {
            type: "manual",
            message: errors.email[0]
          })
        }
      } else if (error.response?.data?.message) {
        setError("email", {
          type: "manual",
          message: error.response.data.message
        })
      }
      ngn.submit.setEnabled()
    })
  }

  return (
    <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmitHandler)}>
      {showSuccess && <AlertMessage message="We've sent you an email with a link to reset your password." type="success"></AlertMessage>}
      {/* Email Field  */}
      <FormControl fullWidth sx={{ mb: 4 }}>
        <Controller
          name="email"
          control={control}
          rules={{ required: true }}
          render={({ field: { value, onChange, onBlur } }) => (
            <TextField
              autoFocus
              label="Email"
              value={value}
              onBlur={onBlur}
              onChange={onChange}
              error={Boolean(errors.email)}
              placeholder="admin@alltrue.com"
            />
          )}
        />
        <FormFieldMessage color="error.main" error={errors.email} />
      </FormControl>
      <Submit fullWidth ngn={ngn.submit} title="Send Reset Link" />
      {/* Back to Login button  */}
      <Box
        sx={{ mb: 4, display: "flex", alignItems: "center", flexWrap: "wrap", justifyContent: "center" }}
      >
        <Typography
          component={Link} to={Uri.signIn()}
          sx={{ display: "flex", alignItems: "center", color: "primary.main", justifyContent: "center" }}
        >
          <ChevronLeft sx={{ mr: 1.5, fontSize: "2rem" }} />
          <span>Back to login</span>
        </Typography>
      </Box>
    </form>
  )
}

export default Form
