const General = {
  "roles": [
    {value: "admin", label: "Admin"},
    {value: "editor", label: "Editor"},
    {value: "viewer", label: "Viewer"},
  ],
  "statusMessageLifeCycle": 3000, // in milliseconds
  "hexColorRegEx": /^#[0-9a-f]{6}$/i,
  "hardwareIDRegEx": /\s\([0-9a-f]{4}:[0-9a-f]{4}\)$/,
  "currency": "$",
  "dataTablePageLimit": 15,
  "dataTablePageLimitOptions": [15, 30, 60]
}

export default General
