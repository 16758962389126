import Catch401 from "middlewares/Catch401"
import Comm from "services/Comm"
import Uri from "services/Uri"

const RollbackImport = async function ({import_type, id}) {

  let result = {}
  let error = {
    code: 0,
    message: "Import couldn't be rollbacked!"
  }

  await Comm.request({
    url: Uri.rollbackData(),
    method: "post",
    data: {
      id,
      import_type
    }
  }).then((res) => {
    // console.dir(res)
    result = res.data.data
  }).catch(Catch401((err) => {
    // console.dir(err)
    error.code = err.response.status
    error.message = err.response.data.message
  }))

  if (error.code > 0) {
    return Promise.reject(error)
  }

  return Promise.resolve(result)
}

export default RollbackImport
