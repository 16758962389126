import React, { useState } from "react"
import Proptype from "prop-types"
import { FormControl, Select, MenuItem, ListItemText, Checkbox, InputLabel } from "@mui/material"
import { Controller } from "react-hook-form"
import FormFieldMessage from "components/__Shared/FormFieldMessage"

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8

const MenuProps = {
  PaperProps: {
    style: {
      width: 250,
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
    }
  }
}

function ReactSelect(props) {
  const { label, name, control, required, error, defaultValue, change, setValue, options, multiple, placeholder } = props
  const [values, setValues] = useState(defaultValue)

  return (
    <FormControl fullWidth>
      <InputLabel shrink={true} error={Boolean(error)} id={name}>{label}</InputLabel>
      <Controller
        name={name}
        control={control}
        rules={{ required: required }}
        render={() => (
          <Select
            notched={true}
            multiple={multiple}
            label={label}
            value={values}
            MenuProps={MenuProps}
            onChange={(e) => {
              if (change) {
                change(e)
              }
              setValues(e.target.value)
              setValue(name, e.target.value)
            }}
            id={name}
            error={Boolean(error)}
            labelId={name}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            renderValue={selected => {
              if(selected.length === 0){
                return placeholder
              }
              if (multiple) {
                var selected_options = options.filter(r => selected.includes(r.value)).map(v => v.label)
                return selected_options.join(", ")
              } else {
                var selected_option = options.find(r => r.value === selected).label
                return selected_option
              }
            }}
          >
            {!multiple && <MenuItem value="">Select {label}</MenuItem>}
            {options.map(r => (
              multiple ? <MenuItem key={r.value} value={r.value}>
                <Checkbox checked={values.indexOf(r.value) > -1} />
                <ListItemText primary={r.label} />
              </MenuItem>
                : <MenuItem key={r.value} value={r.value}>{r.label}</MenuItem>
            ))}
          </Select>
        )}
      />
      <FormFieldMessage color="error.main" error={error} />
    </FormControl>
  )
}

ReactSelect.defaultProps = {
  label: "",
  name: "input-text",
  control: {},
  required: false,
  error: null,
  placeholder: "",
  options: [],
  multiple: false,
  defaultValue: ""
}

ReactSelect.prototype = {
  label: Proptype.string,
  name: Proptype.string,
  control: Proptype.object,
  required: Proptype.bool,
  error: Proptype.object,
  placeholder: Proptype.string,
  options: Proptype.array,
  onChange: Proptype.func,
  multiple: Proptype.bool,
  defaultValue: Proptype.any,
}

export default ReactSelect
