// ** MUI Theme Provider
import { deepmerge } from "@mui/utils"

// ** User Theme Options
import UserThemeOptions from "./UserThemeOptions"

// ** Theme Override Imports
import palette from "./palette"
import spacing from "./spacing"
import shadows from "./shadows"
import breakpoints from "./breakpoints"

const themeOptions = settings => {
  // ** Vars
  const { skin, mode, direction, themeColor } = settings

  // ** Create New object before removing user component overrides and typography objects from userThemeOptions
  const userThemeConfig = Object.assign({}, UserThemeOptions())
  const userFontFamily = userThemeConfig.typography?.fontFamily

  // ** Remove component overrides and typography objects from userThemeOptions
  delete userThemeConfig.components
  delete userThemeConfig.typography

  const mergedThemeConfig = deepmerge(
    {
      direction,
      palette: palette(mode, skin),
      typography: {
        fontFamily:
          userFontFamily ||
          [
            "'IBM Plex Sans'",
            "Inter",
            "sans-serif",
            "-apple-system",
            "BlinkMacSystemFont",
            "'Segoe UI'",
            "Roboto",
            "'Helvetica Neue'",
            "Arial",
            "sans-serif",
            "'Apple Color Emoji'",
            "'Segoe UI Emoji'",
            "'Segoe UI Symbol'"
          ].join(",")
      },
      shadows: shadows(mode),
      ...spacing,
      breakpoints: breakpoints(),
      shape: {
        borderRadius: 10
      },
      mixins: {
        toolbar: {
          minHeight: 64
        }
      }
    },
    userThemeConfig
  )

  return deepmerge(mergedThemeConfig, {
    palette: {
      primary: {
        ...mergedThemeConfig.palette[themeColor]
      }
    }
  })
}

export default themeOptions
