import { FormHelperText } from "@mui/material"

const FormFieldMessage = function ({ error, color }) {

  return (
    error ? (
      <FormHelperText sx={{ color: color }} id="">
        {error.message}
      </FormHelperText>
    ) : <></>
  )
}

export default FormFieldMessage
