import { Navigate } from "react-router-dom"
import { useSelector } from "react-redux"
import Uri from "services/Uri"
import BlankLayout from "components/__Shared/BlankLayout"
import AllTrueLogo from "components/__Shared/AllTrueLogo"
import Form from "components/Authentication/SignIn/Form"

// ** Configs
import themeConfig from "configs/themeConfig"

// ** MUI Components
import {
  Box,
  Typography
} from "@mui/material"
import MuiCard from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import { styled } from "@mui/material/styles"


// ** Styled Components
const Card = styled(MuiCard)(({ theme }) => ({
  [theme.breakpoints.up("sm")]: { width: 450 }
}))

const SignIn = function () {

  const ngn = {
    modal: {},
    submit: {
      setDisabled: null,
      setEnabled: null,
    }
  }

  const Signed = useSelector(state => state.Auth.signed)

  return (
    Signed ?
      <Navigate to={Uri.u()} replace={true} />
      :
      <BlankLayout>
        <Box className="content-center">
          <Card sx={{ zIndex: 1 }}>
            <CardContent sx={{ p: theme => `${theme.spacing(13, 7, 6.5)} !important` }}>
              <Box sx={{ mb: 8, display: "flex", alignItems: "center", justifyContent: "center" }}>
                <AllTrueLogo />
              </Box>
              <Box sx={{ mb: 8 }}>
                <Typography variant="h5" sx={{ mb: 1.5, fontWeight: 600, letterSpacing: "0.18px", textAlign: "center" }}>
                  {`Welcome to ${themeConfig.templateName}!`}
                </Typography>
              </Box>
              <Form ngn={ngn}/>
            </CardContent>
          </Card>
        </Box>
      </BlankLayout >
  )
}

export default SignIn
