import { DataGrid } from "@mui/x-data-grid"
import General from "configs/General"

const DataTable = (props) => {
  return (
    <DataGrid {...props}
      autoHeight
      checkboxSelection
      disableSelectionOnClick
      keepNonExistentRowsSelected
      rowsPerPageOptions={General.dataTablePageLimitOptions}
      sx={{ "& .MuiDataGrid-columnHeaders": { borderRadius: 0 } }}
    />
  )
}

export default DataTable