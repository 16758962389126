
import Req from "requests"
import Store from "store"
import { ImportDataSetCSVData, ImportDataSetStep } from "store/actions"

const ParseFile = function (csv_file) {
  this.execute.loading.showLoader()
  this.execute.submitButton.setDisabled()
  const type = this.type
  Req.ParseCSV({ type, csv_file }).then((response) => {
    let csv_data = {
      id: response.data.csv_data_file.id,
      collection: response.data.csv_data,
      headings: response.data.headings,
      db_columns: response.data.table_fields
    }
    Store.dispatch(ImportDataSetCSVData(csv_data))
    Store.dispatch(ImportDataSetStep(1))
    this.execute.loading.hideLoader()
    this.execute.submitButton.setEnabled()
    this.step = 1
  }).catch(function (err) {
    // console.log(err)
    this.execute.loading.hideLoader()
    this.execute.submitButton.setEnabled()
  })
}

export default ParseFile