import { useSelector } from "react-redux"
import ImportFailed from "./ImportFailed"
import ImportSuccess from "./ImportSuccess"

const StepThree = () => {
  const status = useSelector(state => state.ImportData.status)

  switch (status) {
    case false:
      return <ImportFailed />
    case true:
    default:
      return <ImportSuccess />
  }
}
export default StepThree