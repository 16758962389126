import { useEffect, useState } from "react"
import { Grid, Box, Button, Dialog, DialogContent, DialogTitle, Typography, Avatar, Divider } from "@mui/material"

// ** Third Party Imports
import * as yup from "yup"
import { useForm, useFieldArray } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { useSelector } from "react-redux"

// ** Custom Components Imports
import CustomChip from "components/__Shared/Chip"
import { Input, RadioButtons, Datepicker, Select, Switch } from "components/__Shared/FormControls"
import DateFormatYMD from "utils/DateFormatYMD"
import ConfirmActionModal from "components/__Shared/Modals/ConfirmActionModal"


// ** Default Values & Schemas
const defaultValues = {
  fields: [],
  same_for_all: true,
}
const defaultSchema = {
  fields: yup.array().min(1, "Fields must be select at least one").required("required"),
  same_for_all: yup.boolean()
}
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const fieldSchema = {
  first_name: yup.string().required("First name field is required"),
  last_name: yup.string().required("Last name field is required"),
  email: yup.string().email().required("Email field is required"),
  password: yup.string().required("Password field is required"),
  phone: yup.string().matches(phoneRegExp, "Contact number is not valid").required("Contact number field is required"),
  date_of_birth: yup.string().required("Date of Birth field is required"),
  gender: yup.string().required("Gender field is required"),
  address: yup.string().required("Address field is required"),
  roles: yup.array().min(1, "Role must be select at least one").required("required"),
  status: yup.string().required("required")
}

const ModalMultipleUserEdit = function ({ ngn, onEscape }) {

  //** States & Hooks
  const [open, setOpened] = useState(false)
  const [selectedFields, setSelectedFields] = useState([])
  const [sameForAll, setSameForAll] = useState(true)
  const [isError, setIsError] = useState(false)
  const [errObj, setErrObj] = useState({})

  //** Get User"s collections
  const users = useSelector(state => state.Users.collection)
  //** Get Selected User from User"s collections
  const selectedUsers = Object.values(users).filter(u => ngn.selected_rows.includes(u.id))
  //** Init default Schema for Validation
  const [validationSchema, setValidationSchema] = useState(defaultSchema)

  //** Get Role"s collections
  const allRoles = useSelector(state => state.Roles.collection)
  //** Manipulate Role array of object for Dropdown options
  const roleOptions = Object.values(allRoles).map(x => {
    return { label: x.name, value: x.id }
  })

  //** Status Field Dropdown Option */
  const statusOptions =
  [
    {
      label: "Active",
      value: "active"
    },
    {
      label: "Inactive",
      value: "inactive"
    },
    {
      label: "Blocked",
      value: "blocked"
    },
    {
      label: "On-hold",
      value: "on-hold"
    },
  ]

  //** Default User table fields
  const fieldOptions = [
    { value: "first_name", label: "First Name" },
    { value: "last_name", label: "Last Name" },
    { value: "email", label: "Email" },
    { value: "password", label: "Password" },
    { value: "date_of_birth", label: "Date of Birth" },
    { value: "gender", label: "Gender" },
    { value: "address", label: "Address" },
    { value: "phone", label: "Contact Number" },
    { value: "roles", label: "Roles" },
    { value: "status", label: "Status" },
  ]

  // ** React Form Hooks
  const {
    reset,
    setValue,
    watch,
    getValues,
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues,
    mode: "onBlur",
    resolver: yupResolver(yup.object().shape(validationSchema))
  })

  const { fields, replace } = useFieldArray({
    control,
    name: "data"
  })

  useEffect(() => {
    if (open) {
      window.addEventListener("keydown", onEscape)
    } else {
      window.removeEventListener("keydown", onEscape)
    }
  }, [open, onEscape])

  ngn.resetForm = function () {
    reset()
  }

  ngn.setErrors = function(ErrObj) {
    setIsError(true)
    setErrObj(ErrObj)
  }

  ngn.showModal = function () {
    setOpened(true)
  }

  ngn.hideModal = function () {
    setOpened(false)
  }

  const onConfirm = function (data) {
    if (data.same_for_all) {
      for (let f = 0; f < data.fields.length; f++) {
        for (let u = 0; u < data.data.length; u++) {
          data.data[u][data.fields[f]] = data[data.fields[f]]
          if (data.fields[f] === "date_of_birth") {
            data.data[u][data.fields[f]] = DateFormatYMD(data[data.fields[f]])
          }
        }
      }
    } else {
      for (let u = 0; u < data.data.length; u++) {
        if (data.fields.includes("date_of_birth")) {
          data.data[u]["date_of_birth"] = DateFormatYMD(data.data[u]["date_of_birth"])
        }
      }
    }
    ngn.confirm(data)
  }

  const onCancel = function (ev) {
    // console.dir(ev)
    ngn.cancel()
  }

  const updateValidation = (same, fieldsArray) => {
    let usersFields = []
    const validationObject = {}
    for (let u = 0; u < selectedUsers.length; u++) {
      let obj = { id: selectedUsers[u].id }
      for (let f = 0; f < fieldsArray.length; f++) {
        obj[fieldsArray[f]] = selectedUsers[u][fieldsArray[f]] ? selectedUsers[u][fieldsArray[f]] : ""
        if (fieldsArray[f] === "roles") {
          selectedUsers[u][fieldsArray[f]].map(r => r.id)
          obj[fieldsArray[f]] = selectedUsers[u][fieldsArray[f]].map(r => r.id)
        }
        validationObject[fieldsArray[f]] = fieldSchema[fieldsArray[f]]
      }
      usersFields.push(obj)
    }
    let validation = validationSchema
    if (same) {
      validation = { ...validation, ...validationObject }
      const { data, ...rest } = validation
      validation = rest
    } else {
      let dataValidation = yup.array().of(yup.object().shape(validationObject)).required("Required field")
      validation = { ...validation, data: dataValidation }
      validation = { data: validation.data, fields: validation.fields, same_for_all: validation.same_for_all }
    }
    setValidationSchema(validation)
    replace(usersFields)
  }

  // onChange for Fields Dropdown
  const handleChangeFields = (e) => {
    setSelectedFields(e.target.value)
    updateValidation(sameForAll, e.target.value)
  }

  const handelChangeSelectMode = (e) => {
    let value = !(e.target.value === "true")
    setSameForAll(value)
    updateValidation(value, selectedFields)
  }

  // Render Fields if `same_for_all` is false
  const renderFieldForEachUsers = (field_name, index) => {
    let field = fieldOptions.find(x => x.value === field_name)
    let error = null
    if (errors?.data && errors?.data[index]) {
      error = errors?.data[index][field_name]
    }
    let name = `data.${index}.${field.value}`
    return renderField(field_name, name, field.label, error)
  }

  // Render Fields if `same_for_all` is true
  const renderFieldsForAllUsers = (field_name) => {
    let field = fieldOptions.find(x => x.value === field_name)
    let error = null
    if (errors[field_name]) {
      error = errors[field_name]
    }
    return renderField(field_name, field_name, field.label, error)
  }

  // Render Fields
  const renderField = (field_name, name, label, error) => {
    switch (field_name) {
      case "first_name":
      case "last_name":
      case "email":
      case "phone":
        return (
          <Input label={label} name={name} control={control} error={error} />
        )
      case "address":
        return (
          <Input
            multiline={true}
            rows={2}
            label={label}
            name={name}
            control={control}
            error={error}
          />
        )
      case "password":
        return (
          <Input
            type="password"
            label={label}
            name={name}
            control={control}
            error={error}
          />
        )
      case "date_of_birth":
        return (
          <Datepicker
            label={label}
            name={name}
            control={control}
            error={error}
          />
        )
      case "gender":
        return (
          <RadioButtons
            label={label}
            name={name}
            control={control}
            error={error}
            options={[
              { value: "male", label: "Male" },
              { value: "female", label: "Female" },
            ]}
          />
        )
      case "roles":
        return (
          <Select
            label={label}
            name={name}
            defaultValue={getValues(name) ? getValues(name) : []}
            multiple={true}
            setValue={setValue}
            control={control}
            error={error}
            options={roleOptions}
            placeholder="Select Role"
          />
        )
      case "status":
        return (
          <Select
            label={label}
            name={name}
            defaultValue={getValues(name) ? getValues(name) : ""}
            setValue={setValue}
            control={control}
            error={error}
            options={statusOptions}
            placeholder="Select Status"
          />
        )
      default:
        return (
          <Input label={label} name={name} control={control} error={error} />
        )
    }
  }

  return (
    <Dialog scroll="body" fullWidth maxWidth="sm" onClose={onCancel} open={open} disableEscapeKeyDown>
      <DialogTitle sx={{ pt: 12, mx: "auto", textAlign: "center" }}>
        <Typography variant="h4" component="span" sx={{ mb: 2 }}>
          Update User"s Details
        </Typography>
        <Typography variant="body2">Bulk edit will be applied to : <em>all {selectedUsers.length} selected users</em></Typography>
        <ConfirmActionModal />
      </DialogTitle>
      <DialogContent sx={{ pb: 12 }}>
        <Box component="form" noValidate autoComplete="off" sx={{ mt: 0 }} onSubmit={handleSubmit(onConfirm)}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="body2" sx={{ fontWeight: 600 }}>
                1. Selected Users:
              </Typography>
              <Box sx={{ mt: 1, mb: 2 }}>
                {selectedUsers?.map((u, k) =>
                  <CustomChip
                    key={k}
                    avatar={<Avatar src={u.avatar} alt={u.full_name} />}
                    skin="light"
                    size="small"
                    label={u.full_name}
                    sx={{ mr: 2 }}
                  />
                )}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" sx={{ fontWeight: 600 }}>
                2. Currently editing these fields:
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Select
                label="Fields"
                name="fields"
                defaultValue={[]}
                multiple={true}
                setValue={setValue}
                control={control}
                error={errors.fields}
                options={fieldOptions}
                placeholder="Select Fields"
                change={handleChangeFields}
              />
              <Switch change={handelChangeSelectMode} name="same_for_all" label="Same for all selected users?" control={control} error={errors.same_for_all} />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography variant="body2" sx={{ fontWeight: 600 }}>
                3. Update Selected Fields:
              </Typography>
            </Grid>
            {!watch("same_for_all") && fields.map((item, index) => {
              return (
                <Grid item xs={12} sm={12} key={item.id}>
                  <Typography variant="body2" sx={{ fontWeight: 600, fontSize: 12, mb: 5 }}>
                    {index + 1}. {selectedUsers[index].full_name}:
                  </Typography>
                  {selectedFields?.map((f, i) => {
                    return <Box key={i} sx={{ mb: 4 }}>
                      {renderFieldForEachUsers(f, index)}
                    </Box>
                  })}
                  {fields.length !== index + 1 && <Divider sx={{ mb: 0 }} />}
                </Grid>
              )
            })}
            {
              watch("same_for_all") && selectedFields?.map((f, i) => {
                return <Grid item xs={12} sm={12} key={i}>
                  {renderFieldsForAllUsers(f)}
                </Grid>
              })
            }
            { isError ?
              <Grid item xs={12}>
                <Typography variant="body2" color="error">{errObj.code}</Typography>
                <Typography variant="body2" color="error">{errObj.message}</Typography>
              </Grid> : <></>
            }
          </Grid>
          <Box className="demo-space-x" sx={{ "&>:last-child": { mr: 0 }, display: "flex", justifyContent: "center", alignItems: "center", mt: 3 }}>
            <Button size="large" type="submit" variant="contained">
              Update Details
            </Button>
            <Button size="large" variant="outlined" color="secondary" onClick={onCancel}>
              Discard
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default ModalMultipleUserEdit