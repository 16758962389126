import * as actionType from "store/actions/actionTypes"
// --------------------------------------------------------------------------------
// Current User
// --------------------------------------------------------------------------------

export const UserSetData = (data) => {
  return {
    type: actionType.ACTION_USER_SET_DATA,
    payload: {
      data
    }
  }
}

export const UserGlobalReset = () => {
  return {
    type: actionType.ACTION_USER_GLOBAL_RESET
  }
}


// --------------------------------------------------------------------------------
// Auth
// --------------------------------------------------------------------------------

export const AuthSetSigned = () => {
  return {
    type: actionType.ACTION_AUTH_SET_SIGNED
  }
}

export const AuthSetImpersonate = (impersonate) => {
  return {
    type: actionType.ACTION_AUTH_SET_IMPERSONATE,
    payload: impersonate
  }
}

export const AuthSetNotSigned = () => {
  return {
    type: actionType.ACTION_AUTH_SET_NOT_SIGNED
  }
}

export const AuthReset = () => {
  return {
    type: actionType.ACTION_AUTH_RESET
  }
}


// --------------------------------------------------------------------------------
// Requests
// --------------------------------------------------------------------------------

export const RequestsReady = () => {
  return {
    type: actionType.ACTION_REQUESTS_READY
  }
}

export const RequestsGlobalReset = () => {
  return {
    type: actionType.ACTION_REQUESTS_GLOBAL_RESET
  }
}

export const RequestsSetParams = (value) => {
  return {
    type: actionType.ACTION_REQUESTS_SET_PARAMS,
    payload: {
      params: value
    }
  }
}

export const RequestsSetLoader = (value) => {
  return {
    type: actionType.ACTION_REQUESTS_SET_REQUEST,
    payload: {
      func: value
    }
  }
}

export const RequestsSetAction = (func, params) => {
  return {
    type: actionType.ACTION_REQUESTS_SET_ACTION,
    payload: {
      func: func,
      params: params
    }
  }
}


// --------------------------------------------------------------------------------
// Init
// --------------------------------------------------------------------------------

export const InitSetDone = () => {
  return {
    type: actionType.ACTION_INIT_SET_DONE
  }
}

export const InitUnsetDone = () => {
  return {
    type: actionType.ACTION_INIT_UNSET_DONE
  }
}


// ----------------------------------------------------------------------------------------------------
// Shared
// ----------------------------------------------------------------------------------------------------

export const SharedGlobalReset = function (items) {
  return {
    type: actionType.ACTION_SHARED_GLOBAL_RESET
  }
}

export const SharedModalErrorMessageShow = function (message, callback) {
  return {
    type: actionType.ACTION_SHARED_MODALS_ERROR_MESSAGE_SHOW,
    payload: {
      message: message,
      callback: callback
    }
  }
}

export const SharedModalErrorMessageHide = function () {
  return {
    type: actionType.ACTION_SHARED_MODALS_ERROR_MESSAGE_HIDE
  }
}

export const SharedModalConfirmActionShow = function (message, ngn) {
  return {
    type: actionType.ACTION_SHARED_MODALS_CONFIRM_ACTION_SHOW,
    payload: {
      message: message,
      ngn: ngn
    }
  }
}

export const SharedModalConfirmActionHide = function () {
  return {
    type: actionType.ACTION_SHARED_MODALS_CONFIRM_ACTION_HIDE
  }
}

// ----------------------------------------------------------------------------------------------------
// Users
// ----------------------------------------------------------------------------------------------------

export const UsersSetCollection = (collection) => {
  return {
    type: actionType.ACTION_USERS_SET_COLLECTION,
    payload: {
      collection: collection
    }
  }
}

export const UsersEditDetails = (details) => {
  return {
    type: actionType.ACTION_USERS_EDIT_DETAILS,
    payload: {
      details: details
    }
  }
}

export const UsersSetSelectedUser = (user) => {
  return {
    type: actionType.ACTION_USERS_SET_SELECTED_USER,
    payload: user
  }
}

export const UsersSetPermissions = (permissions) => {
  return {
    type: actionType.ACTION_USERS_SET_PERMISSONS,
    payload: {
      permissions: permissions
    }
  }
}

export const UserUpdate = (user) => {
  return {
    type: actionType.ACTION_USERS_EDIT_USER,
    payload: user
  }
}


export const UsersRemoveUser = (id) => {
  return {
    type: actionType.ACTION_USERS_REMOVE_USER,
    payload: {
      id: id
    }
  }
}

export const UsersRemoveMultipleUser = (ids) => {
  return {
    type: actionType.ACTION_USERS_REMOVE_MULTIPLE_USERS,
    payload: {
      ids: ids,
    }
  }
}

export const UsersAddNewUser = (user) => {
  return {
    type: actionType.ACTION_USERS_ADD_NEW_USER,
    payload: {
      user: user
    }
  }
}

export const UsersMultipleUpdateRollBack = (user) => {
  return {
    type: actionType.ACTION_USERS_MULTIPLE_UPDATE_ROLLBACK,
    payload: {
      user: user
    }
  }
}

export const UsersGlobalReset = () => {
  return {
    type: actionType.ACTION_USERS_GLOBAL_RESET
  }
}

export const UserSetMetaData = (meta_data) => {
  return {
    type: actionType.ACTION_USERS_SET_SELECTED_USER_METADATA,
    payload: {
      meta_data
    }
  }
}

// --------------------------------------------------------------------------------
// Import Data
// --------------------------------------------------------------------------------
export const ImportDataSetStep = (step) => {
  return {
    type: actionType.ACTION_SET_IMPORT_DATA_STEP,
    payload: step
  }
}

export const ImportDataGlobalReset = () => {
  return {
    type: actionType.ACTION_IMPORT_DATA_GLOBAL_RESET
  }
}

export const ImportDataSetColumns = (columns) => {
  return {
    type: actionType.ACTION_SET_IMPORT_DATA_COLUMNS,
    payload: columns
  }
}

export const ImportDataSetType = (type) => {
  return {
    type: actionType.ACTION_SET_IMPORT_DATA_TYPE,
    payload: type
  }
}

export const ImportDataSetCSVData = (data) => {
  return {
    type: actionType.ACTION_SET_IMPORT_DATA_CSV_DATA,
    payload: data
  }
}

export const ImportDataSetLoading = (is_loading) => {
  return {
    type: actionType.ACTION_SET_IMPORT_DATA_LOADING,
    payload: is_loading
  }
}


export const ImportDataSetNextButtonDisabled = (is_disabled) => {
  return {
    type: actionType.ACTION_SET_IMPORT_DATA_NEXT_BUTTON_DISABLED,
    payload: is_disabled
  }
}

export const ImportDataSetFinalResult = (result) => {
  return {
    type: actionType.ACTION_SET_IMPORT_DATA_FINAL_RESULT,
    payload: result
  }
}

// --------------------------------------------------------------------------------
// Rollback
// --------------------------------------------------------------------------------

export const RollbackSetCollection = (data) => {
  return {
    type: actionType.ACTION_ROLLBACK_SET_DATA,
    payload: {
      data: data
    }
  }
}

export const RollbackGlobalReset = () => {
  return {
    type: actionType.ACTION_ROLLBACK_GLOBAL_RESET
  }
}

// --------------------------------------------------------------------------------
// Roles
// --------------------------------------------------------------------------------

export const SetRolesCollection = (collection) => {
  return {
    type: actionType.ACTION_ROLES_SET_DATA,
    payload: {
      collection: collection
    }
  }
}

export const RoleUpdate = (role) => {
  return {
    type: actionType.ACTION_ROLES_EDIT_ROLE,
    payload: role
  }
}


export const RolesGlobalReset = () => {
  return {
    type: actionType.ACTION_ROLES_GLOBAL_RESET
  }
}

// --------------------------------------------------------------------------------
// Permissions
// --------------------------------------------------------------------------------

export const SetPermissionCollection = (data) => {
  return {
    type: actionType.ACTION_PERMISSIONS_SET_DATA,
    payload: {
      data: data
    }
  }
}

export const PermissionsGlobalReset = () => {
  return {
    type: actionType.ACTION_PERMISSIONS_GLOBAL_RESET
  }
}

// --------------------------------------------------------------------------------
// Stores
// --------------------------------------------------------------------------------

export const StoresSetCollection = (collection) => {
  return {
    type: actionType.ACTION_STORES_SET_COLLECTION,
    payload: {
      collection: collection
    }
  }
}

export const StoresGlobalReset = () => {
  return {
    type: actionType.ACTION_STORES_GLOBAL_RESET
  }
}

// --------------------------------------------------------------------------------
// DataGrid
// --------------------------------------------------------------------------------
export const DataGridGlobalReset = () => {
  return {
    type: actionType.ACTION_DATAGRID_GLOBAL_RESET
  }
}

export const DataGridSetUserColumns = (columns) => {
  return {
    type: actionType.ACTION_DATAGRID_SET_USER_COLUMNS,
    payload: {
      columns
    }
  }
}