import Catch401 from "middlewares/Catch401"
import Comm from "services/Comm"
import Uri from "services/Uri"

const AddRole = async function ({name, permissions}) {

  let result = {}
  let error = {
    code: 0,
    type: "",
    data: null,
    message: "Role couldn't be added!",
  }

  await Comm.request({
    url: Uri.addRole(),
    method: "post",
    data: {
      name,
      permissions
    }
  }).then((res) => {
    // console.dir(res)
    result = res.data.data
  }).catch(Catch401((err) => {
    // console.dir(err)
    error.code = err.response.status
    error.message = err.response.data.message
    error.data = err.response.data.errors
  }))

  if (error.code > 0) {
    return Promise.reject(error)
  }

  return Promise.resolve(result)
}

export default AddRole
