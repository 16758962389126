const Confirm = function (data) {
  // console.dir(this)

  // Get the values from the form
  // this.addItem.resetAddItemModal()

  // Store changes
  this.addItem.storeChanges(data)
}

export default Confirm
