import Req from "requests"
import Store from "store"
import {
  RoleUpdate
} from "store/actions"

const StoreChanges = function ({ id, name, permissions }) {
  this.editRole.submitButton.setDisabled()
  this.editRole.loader.showLoader()
  Req.UpdateRole({ id, name, permissions })
    .then((response) => {
      Store.dispatch(RoleUpdate(response))
      this.editRole.cancel()
    }).catch((error) => {
      this.editRole.cancel()
      this.editRole.setErrors(error.data)
      this.editRole.submitButton.setEnabled()
    })
}

export default StoreChanges
