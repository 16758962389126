// ** MUI Imports
import { Box, IconButton, Tooltip } from "@mui/material"
import { AbilityContext } from "context/Can"

// ** Icons Import
import {
  AccountConvert,
  AccountEye,
  AccountEdit,
  Delete,
  Email,
} from "mdi-material-ui"

import { useContext, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import Uri from "services/Uri"

const UserActions = ({ ngn }) => {
  const [selected, setSelected] = useState(ngn.collection.selected_rows)
  const users = useSelector((state) => state.Users.collection)

  // ** Hooks
  const ability = useContext(AbilityContext)
  const navigate = useNavigate()

  ngn.collection.setSelectedUser = (users) => {
    setSelected(users)
  }

  const handleEdit = (selected) => {
    if (selected.length === 1) {
      ngn.editUser.selected_rows[0] = selected[0]
      const selectedUser = users.filter((u) => ngn.editUser.selected_rows.includes(u.id))[0]
      selectedUser.date_of_birth = new Date(selectedUser.date_of_birth)
      selectedUser.role = selectedUser.roles.map((role) => role.id)
      ngn.editUser.showModal(selectedUser)
    } else {
      ngn.collection.onMultipleEdit()
    }
  }

  const handleDelete = (selected) => {
    if (selected.length > 1) {
      ngn.collection.onMultipleDelete(selected)
    } else {
      const id = selected[0]
      ngn.collection.onDeleteConfirm({ id })
    }
  }

  const handleView = (id) => {
    navigate(Uri.uUserId({ id: id }))
  }
  return (
    <Box sx={{ pr: 5, pl: 5 }}>
      {selected.length === 1 ? (
        <>
          {ability?.can("read", "impersonate") ? (
            <Tooltip title="Impersonate User" placement="top" sx={{ mr: 1 }}>
              <IconButton
                color="secondary"
                aria-label="add"
                onClick={() => ngn.collection.onImpersonate(navigate)}
              >
                <AccountConvert />
              </IconButton>
            </Tooltip>
          ) : null}
          {ability?.can("read", "users") ? (
            <Tooltip title="View" placement="top" sx={{ mr: 1 }}>
              <IconButton
                color="warning"
                aria-label="add"
                size="medium"
                onClick={() => handleView(selected[0])}
              >
                <AccountEye />
              </IconButton>
            </Tooltip>
          ) : null}
        </>
      ) : (
        <></>
      )}
      {selected.length >= 1 ? (
        <>
          {ability?.can("update", "users") ? (
            <Tooltip title="Edit" placement="top" sx={{ mr: 1 }}>
              <IconButton
                color="success"
                aria-label="add"
                size="medium"
                onClick={() => handleEdit(selected)}
              >
                <AccountEdit />
              </IconButton>
            </Tooltip>
          ) : null}
          {ability?.can("read", "users") ? (
            <Tooltip title="Mail" placement="top" sx={{ mr: 1 }}>
              <IconButton
                color="info"
                aria-label="add"
                size="medium"
                onClick={ngn.collection.onMail}
              >
                <Email />
              </IconButton>
            </Tooltip>
          ) : null}
          {ability?.can("delete", "users") ? (
            <Tooltip title="Delete" placement="top">
              <IconButton
                color="error"
                aria-label="add"
                size="medium"
                onClick={() => handleDelete(selected)}
              >
                <Delete />
              </IconButton>
            </Tooltip>
          ) : null}
        </>
      ) : (
        <></>
      )}
    </Box>
  )
}
export default UserActions
