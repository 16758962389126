import { Grid, Box, Button, Dialog, DialogContent, DialogTitle, Typography, Divider, CircularProgress } from "@mui/material"
import { useEffect, useState } from "react"

// ** Third Party Imports
import * as yup from "yup"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

import { useSelector } from "react-redux"

import { Input, RadioButtons, Datepicker, Select } from "components/__Shared/FormControls"
import DateFormatYMD from "utils/DateFormatYMD"
import Submit from "components/__Shared/Input/Submit"

// ** Default Values
const defaultValues = {
  first_name: "",
  last_name: "",
  email: "",
  password: "",
  confirm_password: "",
  phone: "",
  date_of_birth: "",
  gender: "",
  address: "",
  role: []
}
const showErrors = (field, valueLen, min) => {
  if (valueLen === 0) {
    return `${field} field is required`
  } else if (valueLen > 0 && valueLen < min) {
    return `${field} must be at least ${min} characters`
  } else {
    return ""
  }
}
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const schema = yup.object().shape({
  first_name: yup.string().min(2, obj => showErrors("First name", obj.value.length, obj.min)),
  last_name: yup.string().min(2, obj => showErrors("Last name", obj.value.length, obj.min)),
  email: yup.string().email().required(obj => showErrors("Email", obj.value.length)),
  password: yup.string().min(5, obj => showErrors("Password", obj.value.length, obj.min)).required(),
  confirm_password: yup.string().min(5, obj => showErrors("Confirm password", obj.value.length, obj.min))
    .oneOf([yup.ref("password")], "Both passwords do not match.").required(),
  phone: yup.string().matches(phoneRegExp, "Contact number is not valid").required(obj => showErrors("Contact number", obj.value.length)),
  role: yup.array().min(1, "Role must be select at least one").required("required")
})

const ModalAddUser = function ({ ngn, onEscape }) {

  const [open, setOpened] = useState(false)
  const [loading, setLoading] = useState(false)
  const allRoles = useSelector(state => state.Roles.collection)
  const roleOptions = Object.values(allRoles).map(x => {
    return { label: x.name, value: x.id }
  })

  // ** Hooks
  const {
    reset,
    setValue,
    setError,
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues,
    mode: "onBlur",
    resolver: yupResolver(schema)
  })

  useEffect(() => {
    if (open) {
      window.addEventListener("keydown", onEscape)
    } else {
      window.removeEventListener("keydown", onEscape)
    }
  }, [open, onEscape])

  ngn.resetForm = function () {
    reset()
    ngn.submitButton.reset()
  }

  ngn.setErrors = function (error) {
    if (error.code > 0) {
      const { type, message, data } = error
      data.forEach((f) => {
        setError(f, {
          type: type,
          message: message[`${f}`],
        })
      })
      ngn.submitButton.setDisabled()
    }
  }

  ngn.showModal = function () {
    setOpened(true)
  }

  ngn.hideModal = function () {
    setOpened(false)
  }

  ngn.loader.showLoader = function () {
    setLoading(true)
  }

  ngn.loader.hideLoader = function () {
    setLoading(false)
  }

  const onConfirm = function (data) {
    data.date_of_birth = data.date_of_birth ? DateFormatYMD(data.date_of_birth) :  ""
    ngn.confirm(data)
  }

  const onCancel = function (ev) {
    // console.dir(ev)
    ngn.cancel()
  }

  return (
    <Dialog
      scroll="body"
      fullWidth
      maxWidth="sm"
      onClose={onCancel}
      open={open}
      disableEscapeKeyDown
    >
      <DialogTitle sx={{ pt: 12, mx: "auto", textAlign: "center" }}>
        <Typography variant="h4" component="span" sx={{ mb: 2 }}>
          Add New User
        </Typography>
        <Typography variant="body2">
          Add new user with an unique email address.
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ pb: 12 }}>
        {loading ? (
          <Box
            sx={{
              justifyContent: "center",
              alignContent: "center",
              display: "flex",
              mt: 3,
            }}
          >
            <CircularProgress sx={{ ml: 3, mr: 3 }} size={40} />
          </Box>
        ) : (
          <Box
            component="form"
            noValidate
            autoComplete="off"
            sx={{ mt: 4 }}
            onSubmit={handleSubmit(onConfirm)}
          >
            <Grid container spacing={5}>
              <Grid item xs={12}>
                <Typography variant="body2" sx={{ fontWeight: 600 }}>
                  1. Account Details
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Input
                  label="Email"
                  name="email"
                  placeholder="Enter Email"
                  control={control}
                  error={errors.email}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Input
                  type="password"
                  label="Password"
                  name="password"
                  placeholder="Enter Password"
                  control={control}
                  error={errors.password}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Input
                  type="password"
                  label="Confirm Password"
                  placeholder="Enter Confirm Password"
                  name="confirm_password"
                  control={control}
                  error={errors.confirm_password}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Select
                  label="Roles"
                  name="role"
                  defaultValue={[]}
                  multiple={true}
                  setValue={setValue}
                  control={control}
                  error={errors.role}
                  options={roleOptions}
                  placeholder="Select Role"
                />
              </Grid>
              <Grid item xs={12}>
                <Divider sx={{ mb: 0 }} />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" sx={{ fontWeight: 600 }}>
                  2. Personal Info
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Input
                  label="First Name"
                  placeholder="Enter First Name"
                  name="first_name"
                  control={control}
                  error={errors.first_name}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Input
                  label="Last Name"
                  placeholder="Enter Last Name"
                  name="last_name"
                  control={control}
                  error={errors.last_name}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Datepicker
                  label="Date of Birth"
                  name="date_of_birth"
                  control={control}
                  error={errors.date_of_birth}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <RadioButtons
                  label="Gender"
                  name="gender"
                  control={control}
                  error={errors.gender}
                  options={[
                    { value: "male", label: "Male" },
                    { value: "female", label: "Female" },
                  ]}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Input
                  label="Contact Number"
                  placeholder="Enter Contact Number"
                  name="phone"
                  control={control}
                  error={errors.phone}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Input
                  multiline={true}
                  rows={2}
                  label="Address"
                  placeholder="Enter Address"
                  name="address"
                  control={control}
                  error={errors.address}
                />
              </Grid>
            </Grid>
            <Box
              className="demo-space-x"
              sx={{
                "&>:last-child": { mr: 0 },
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mt: 3,
              }}
            >
              <Submit ngn={ngn.submitButton} title="Create User" />
              <Button
                size="large"
                variant="outlined"
                color="secondary"
                onClick={onCancel}
              >
                Discard
              </Button>
            </Box>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default ModalAddUser