import { Box, Button, Card, CardContent, Divider, Grid, Step, StepLabel, Stepper, Typography } from "@mui/material"

// ** Styled Components
import StepperWrapper from "components/UserArea/__Shared/StepperWrapper"
import StepperCustomDot from "components/UserArea/ImportAndRollback/ImportData/__Shared/StepperCustomDot"
import StepThree from "./StepThree"
import StepTwo from "./StepTwo"
import StepOne from "./StepOne"

import ImportEngin from "engine/ImportAndRollback/ImportData"

import PageHeader from "components/UserArea/__Shared/PageHeader"
import { useSelector } from "react-redux"
import Store from "store"
import { ImportDataGlobalReset } from "store/actions"
import { useEffect } from "react"

const steps = [
  {
    title: "Select Import Type"
  },
  {
    title: "Columns Mapping"
  },
  {
    title: "Finish the import"
  }
]


const ImportData = () => {
  // ** States
  //const [activeStep, setActiveStep] = useState(0)
  const activeStep = useSelector(state => state.ImportData.step)

  const getStepContent = step => {
    switch (step) {
      case 0:
        return <StepOne ngn={ImportEngin} />
      case 1:
        return <StepTwo ngn={ImportEngin} />
      case 2:
        return <StepThree ngn={ImportEngin} />
      default:
        return <StepOne ngn={ImportEngin} />
    }
  }

  useEffect(() => {
    Store.dispatch(ImportDataGlobalReset())
  }, [])

  const renderContent = () => {
    if (activeStep === steps.length) {
      return (
        <>
          <Typography>All steps are completed!</Typography>
          <Box sx={{ mt: 4, display: "flex", justifyContent: "flex-end" }}>
            <Button size="large" variant="contained">
              Reset
            </Button>
          </Box>
        </>
      )
    } else {
      return getStepContent(activeStep)
    }
  }

  return (
    <Grid>
      <PageHeader
        title={<Typography variant="h5">Import Data</Typography>}
        subtitle={
          <>
            <Typography variant="body2">
              Use this tool to import data using CSV file. Upload your CSV file by selecting Import Type field. The results of the upload will be available at last step.
            </Typography>
            <Typography variant="body2" fontStyle="italic">
              Note: Your list of the imported data should be in CSV format. If you have an Excel file, please save it as CSV first.
            </Typography>
          </>
        }
      />
      <Card sx={{ mt: 6 }}>
        <CardContent>
          <StepperWrapper>
            <Stepper activeStep={activeStep}>
              {steps.map((step, index) => {
                const labelProps = {}

                return (
                  <Step key={index}>
                    <StepLabel {...labelProps} StepIconComponent={StepperCustomDot}>
                      <div className="step-label">
                        <Typography className="step-number">0{index + 1}</Typography>
                        <div>
                          <Typography className="step-title">{step.title}</Typography>
                          {/* <Typography className="step-subtitle">{step.subtitle}</Typography> */}
                        </div>
                      </div>
                    </StepLabel>
                  </Step>
                )
              })}
            </Stepper>
          </StepperWrapper>
        </CardContent>
        <Divider sx={{ m: 0 }} />
        <CardContent>{renderContent()}</CardContent>
      </Card>
    </Grid>
  )
}
export default ImportData