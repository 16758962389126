const StringFn = function (str) {
  this.result = str
}
// Add Hyphen from String
StringFn.prototype.addHyphen = function () {
  this.result = this.result.replace(/ /g, "-")
  return this
}
// Create Slug from String
StringFn.prototype.createSlug = function () {
  this.result = this.result.replace(/ /g, "_").replace(/[^\w-]+/g, "")
  return this
}
// Remove Hyphen from String
StringFn.prototype.removeHyphen = function () {
  this.result = this.result.replace(/-/g, " ")
  return this
}

// Remove Underscore from String
StringFn.prototype.removeUnderscore = function () {
  this.result = this.result.replace(/_/g, " ")
  return this
}

// Capitalize first letter of all words from String
StringFn.prototype.capitalize = function () {
  this.result = this.result
    .toLowerCase()
    .split(" ")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ")
  return this
}

// Capitalize first letter
StringFn.prototype.capitalizeFirstLetter = function () {
  this.result = this.result.charAt(0).toUpperCase() + this.result.slice(1)
  return this
}

// Lowercase all letters from String
StringFn.prototype.lowercase = function () {
  this.result = this.result.toLowerCase()
  return this
}
export default StringFn