import { Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle, Grid, Typography } from "@mui/material"
import { useEffect, useState } from "react"

// ** Third Party Imports
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { Input, Select } from "components/__Shared/FormControls"
import Submit from "components/__Shared/Input/Submit"

const ModalAdd = function ({ ngn, onEscape, title, fields }) {

  // ** States
  const [open, setOpened] = useState(false)
  const [loading, setLoading] = useState(false)
  //** Init default Schema for Validation
  const [validationSchema, setValidationSchema] = useState({})

  // ** Hooks
  const {
    reset,
    setValue,
    control,
    setError,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: {},
    mode: "onBlur",
    resolver: yupResolver(validationSchema)
  })

  useEffect(() => {
    if (open) {
      window.addEventListener("keydown", onEscape)
    } else {
      window.removeEventListener("keydown", onEscape)
    }
  }, [open, onEscape])

  ngn.resetForm = function () {
    reset()
  }

  ngn.setErrors = function (errors) {
    if(Object.keys(errors).length > 0){
      Object.keys(errors).forEach((e)=>{
        setError(e, {type:"manual", message: errors[e]})
      })
    }
  }

  ngn.modal.show = function () {
    init()
    setOpened(true)
  }

  const init = () => {
    let fieldValidation = {}
    for (let i = 0; i < fields.length; i++) {
      fieldValidation[fields[i].field] = fields[i].schema
    }
    setValidationSchema(yup.object().shape(fieldValidation))
  }

  ngn.modal.hide = function () {
    setOpened(false)
  }

  const onConfirm = function (data) {
    ngn.confirm(data)
  }

  ngn.loader.showLoader = function () {
    setLoading(true)
  }

  ngn.loader.hideLoader = function () {
    setLoading(false)
  }


  const onCancel = function (ev) {
    // console.dir(ev)
    ngn.cancel()
  }


  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      onClose={onCancel}
      open={open}
      disableEscapeKeyDown
    >
      <DialogTitle sx={{ pt: 12, mx: "auto", textAlign: "center" }}>
        <Typography variant="h4" component="span" sx={{ mb: 2 }}>
          Add {title}
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ p: { xs: 6, sm: 12 } }}>
        {loading ? (
          <Box
            sx={{
              justifyContent: "center",
              alignContent: "center",
              display: "flex",
              mt: 3,
            }}
          >
            <CircularProgress sx={{ ml: 3, mr: 3 }} size={40} />
          </Box>
        ) : (
          <Box
            component="form"
            noValidate
            autoComplete="off"
            sx={{ mt: 4 }}
            onSubmit={handleSubmit(onConfirm)}
          >
            <Grid container spacing={5}>
              {fields.map((item, index) => {
                return (
                  <Grid item xs={12} sm={12} key={item.field}>
                    {item.fieldType === "string" && (
                      <Input
                        label={item.headerName}
                        name={item.field}
                        control={control}
                        error={errors[item.field]}
                      />
                    )}
                    {item.fieldType === "select" && (
                      <Select
                        label={item.headerName}
                        name={item.field}
                        defaultValue={""}
                        setValue={setValue}
                        control={control}
                        error={errors[item.field]}
                        options={item.options}
                        placeholder="Select"
                      />
                    )}
                  </Grid>
                )
              })}
            </Grid>
            <Box
              className="demo-space-x"
              sx={{
                "&>:last-child": { mr: 0 },
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Submit ngn={ngn.submitButton} title="Submit" />
              <Button
                size="large"
                variant="outlined"
                color="secondary"
                onClick={onCancel}
              >
                Discard
              </Button>
            </Box>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  )
}
export default ModalAdd