import Log from "utils/Log"
import Store from "store"
import {
  RequestsReady,
  AuthSetImpersonate,
  UserSetData,
  InitSetDone,
  AuthSetSigned,
  SetPermissionCollection,
  SetRolesCollection,
} from "store/actions"
import Catch401 from "middlewares/Catch401"
import LeaveImpersonate from "requests/LeaveImpersonate"
import GetUser from "requests/GetUser"
import Auth from "services/Auth"
import Togglers from "store/togglers"
import Uri from "services/Uri"
import Comm from "services/Comm"
import GetRoles from "requests/GetRoles"
import GetPermissions from "requests/GetPermissions"
const LeaveImpersonateUser = async function (params) {
  Log.req("LeaveImpersonateUser()")

  // NOTES
  // 1. The requests sequence here is important; Do not re-arrange requests if you are not at least 280% sure!
  let error = {
    code: 0,
    message: "",
  }

  // --------------------------------------------------------------------------------
  // Initial Synchronous requests
  // --------------------------------------------------------------------------------
  // *** Leave Impersonate User account
  await LeaveImpersonate()
    .then((res) => {
      // console.dir(res)
      //Auth.setUserID(user_id)
    })
    .catch((err) => {
      // console.dir(err)
      error = err
      if (err.code !== 401) {
        // ...
      }
    })

  if (error.code > 0) {
    return
  }

  await Comm.request({
    url: Uri.session(),
    method: "get",
  })
    .then(function (res) {
      // console.log(res)
      if (res.data?.data?.user_id) {
        Auth.setUserID(res.data.data.user_id)
        Store.dispatch(AuthSetSigned())
        if (res.data.data.impersonator_id) {
          Store.dispatch(AuthSetImpersonate(true))
        } else {
          Store.dispatch(AuthSetImpersonate(false))
        }
      }
    })
    .catch(
      Catch401((error) => {
        // console.log(error)
      })
    )
    .then(() => {
      Store.dispatch(InitSetDone())
    })

  //Called After Leaving Impersonate
  // *** User Personal Info
  await GetUser({ id: Auth.getUserID() })
    .then((res) => {
      // console.dir(res)
      Store.dispatch(UserSetData(res.data))
      Togglers.UserPermissionsByCategory()
    })
    .catch((err) => {
      // console.dir(err)
      error = err
      if (err.code !== 401) {
        // ...
      }
    })

  await GetRoles({})
    .then((res) => {
      Store.dispatch(SetRolesCollection(res.collection))
    })
    .catch((err) => {
      // console.dir(err)
      error = err
      if (err.code !== 401) {
        // ...
      }
    })

  // *** Permissions Info
  await GetPermissions({})
    .then((res) => {
      const permissions = Togglers.PermissionsByCategory(res.collection)
      Store.dispatch(SetPermissionCollection(permissions))
    })
    .catch((err) => {
      // console.dir(err)
      error = err
      if (err.code !== 401) {
        // ...
      }
    })

  if (error.code > 0) {
    return
  }

  // --------------------------------------------------------------------------------
  // *** Post Setup
  // Good to go...
  Store.dispatch(RequestsReady())
  params.navigate(Uri.uDashboard(), { replace: true })
}
export default LeaveImpersonateUser
