import Req from "requests"
import Store from "store"
import { SharedModalErrorMessageShow, StoresSetCollection } from "store/actions"

const GetStoresCollection = function () {
  const page = this.collection.page
  const sort = this.collection.sort
  const limit = this.collection.limit
  const filter = this.collection.filter
  const search_text = this.collection.search_text
  const show_deleted = this.collection.show_deleted

  this.collection.fetching = true
  Req.GetStores({ page, sort, limit, filter, search_text, show_deleted })
    .then((response) => {
      Store.dispatch(StoresSetCollection(response.collection))
      this.collection.total = response.total_records
      this.collection.fetching = false
    })
    .catch((error) => {
      Store.dispatch(SharedModalErrorMessageShow(error.message))
      this.collection.fetching = false
    })
}

export default GetStoresCollection