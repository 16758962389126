// ** MUI Imports
import { Button, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material"
import { styled } from "@mui/material/styles"
import { useSelector } from "react-redux"

import { ArrowRightCircle, Download } from "mdi-material-ui"
import CircularLoader from "components/__Shared/CircularLoader"

const MuiListItemText = styled(ListItemText)(({ theme }) => ({
  "&.MuiListItemText-root": {
    display: "flex",
    alignItems: "center",
    "& .MuiListItemText-primary": {
      textTransform: "capitalize",
      fontStyle: "italic"
    },
    "& .MuiListItemText-secondary": {
      marginLeft: ".5rem",
      color: theme.palette.error.main
    },
  }
}))

const FieldList = ({ngn}) => {
  const type = useSelector(state => state.ImportData.type)
  const columns = useSelector(state => state.ImportData.columns)
  const convertToName = (name) => {
    return name.replace(/_/g, " ")
  }
  var download = function (content, fileName, mimeType) {
    var a = document.createElement("a")
    mimeType = mimeType || "application/octet-stream"

    if (navigator.msSaveBlob) { // IE10
      navigator.msSaveBlob(new Blob([content], {
        type: mimeType
      }), fileName)
    } else if (URL && "download" in a) { //html5 A[download]
      a.href = URL.createObjectURL(new Blob([content], {
        type: mimeType
      }))
      a.setAttribute("download", fileName)
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    } else {
      window.location.href = "data:application/octet-stream," + encodeURIComponent(content) // only this mime type is supported
    }
  }
  const handleDownloadCSVModel = () => {
    if (columns) {
      const rows = [Object.keys(columns)]
      let csvContent = rows.map(e => e.join(",")).join("\n")
      download(csvContent, `${type}_data_model.csv`, "data:text/csv;charset=utf-8")
    }
  }
  return (
    columns ?
      <>
        <Typography sx={{ fontWeight: 600 }}>List of Fields</Typography>
        <List dense component="nav" aria-label="main mailbox">
          {
            Object.keys(columns).map(field_name => (
              <ListItem key={field_name} disablePadding>
                <ListItemIcon>
                  <ArrowRightCircle fontSize="12" />
                </ListItemIcon>
                <MuiListItemText primary={convertToName(field_name)} secondary={columns[field_name] !== null ? "*required" : ""} />
              </ListItem>
            ))
          }
        </List>
        <Button onClick={handleDownloadCSVModel} variant="outlined" startIcon={<Download />}>Download CSV Model</Button>
      </>
      : <CircularLoader ngn={ngn.execute.loading}/>
  )
}

export default FieldList