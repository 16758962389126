import Req from "requests"
import Store from "store"
import { SetPermissionCollection, SharedModalErrorMessageShow } from "store/actions"
import Togglers from "store/togglers"
import StringFn from "utils/StringFn"

const GetPermissions = function () {
  const search = new StringFn(this.collection.search_text).addHyphen().lowercase().result
  const sort = this.collection.sort

  this.collection.fetching = true
  Req.GetPermissions({ search, sort })
    .then((response) => {
      const permissions = Togglers.PermissionsByCategory(response.collection)
      Store.dispatch(SetPermissionCollection(permissions))
      this.collection.fetching = false
    }).catch((error) => {
      Store.dispatch(SharedModalErrorMessageShow(error.message))
      this.collection.fetching = false
    })
}

export default GetPermissions