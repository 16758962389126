import Req from "requests"
import Store from "store"
import {
  SharedModalConfirmActionHide,
  SharedModalErrorMessageShow,
  UsersRemoveMultipleUser,
} from "store/actions"

const DeleteUsers = function () {
  const ids  = this.collection.multipleDelete.selected
  this.collection.fetching = true
  Req.DeleteUsers({ids})
    .then(() => {
      Store.dispatch(UsersRemoveMultipleUser(ids))
      this.collection.selected_rows = []
      Store.dispatch(SharedModalConfirmActionHide())
      this.collection.fetching = false
    })
    .catch((error) => {
      Store.dispatch(SharedModalConfirmActionHide())
      Store.dispatch(SharedModalErrorMessageShow(error.message))
      this.collection.fetching = false
    })
}
export default DeleteUsers
