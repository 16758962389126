import { useCallback, useEffect, useState } from "react"
// ** MUI Imports
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableRow from "@mui/material/TableRow"
import TableHead from "@mui/material/TableHead"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import { SharedModalConfirmActionHide, SharedModalConfirmActionShow, SharedModalErrorMessageShow } from "store/actions"
import Store from "store"
import { Box, Button, Card, CircularProgress, IconButton } from "@mui/material"
import { SquareEditOutline, TrashCanOutline } from "mdi-material-ui"
import ModalEditSetting from "components/UserArea/ControlPanel/__Shared/ModalEditSetting"
import ModalAddSetting from "components/UserArea/ControlPanel/__Shared/ModalAddSetting"

import Comm from "services/Comm"
import Catch401 from "middlewares/Catch401"
import ConfirmActionModal from "components/__Shared/Modals/ConfirmActionModal"

const DataTable = ({ title = "", source, fields, canEdit = false, canDelete = false, canAdd = false, pageHeader = null }) => {
  const [rows, setRows] = useState([])
  const [loading, setLoading] = useState(false)
  const [selectedRow, setSelectedRow] = useState(null)
  const [editOpen, setEditOpen] = useState(false)
  const columns = fields.map(c => c.value)
  const [addOpen, setAddOpen] = useState(false)
  const [editErrors, setEditErrors] = useState(null)
  const [addErrors, setAddErrors] = useState({})

  const loadData = useCallback(() => {
    setLoading(true)
    Comm.request({
      url: source,
      method: "get"
    }).then((res) => {
      setLoading(false)
      setRows(res.data.data)
    }).catch(Catch401((err) => {
      setLoading(false)
      Store.dispatch(SharedModalErrorMessageShow(err.response.data.message))
    }))
  }, [source])

  useEffect(() => {
    loadData()
  }, [loadData])

  const handleEditItem = (row) => {
    setEditOpen(true)
    setSelectedRow(row)
  }

  const handleClickDeleteItem = (row) => {
    let callback = {
      selected: row,
      onSubmit: onSubmitDelete
    }
    Store.dispatch(
      SharedModalConfirmActionShow(
        "Item will be removed. Are you sure?",
        callback
      )
    )
  }

  const onSubmitDelete = (data) => {
    setLoading(true)
    Comm.request({
      url: `${source}/${data.id}`,
      method: "delete",
      data: data
    }).then(() => {
      setLoading(false)
      let newData = rows.filter(x => x.id !== data.id)
      setRows(newData)
      Store.dispatch(SharedModalConfirmActionHide())
    }).catch(Catch401((err) => {
      setLoading(false)
      Store.dispatch(SharedModalErrorMessageShow(err.response.data.message))
    }))
  }

  const onSubmitEdit = (data) => {
    setLoading(true)
    Comm.request({
      url: `${source}/${selectedRow.id}`,
      method: "patch",
      data: data
    }).then(() => {
      setLoading(false)
      let newItem = { ...selectedRow, ...data }
      let newData = rows.map(r => r.id === newItem.id ? { ...r, ...newItem } : r)
      setRows(newData)
      setEditOpen(false)
    }).catch(Catch401((err) => {
      setLoading(false)
      setEditErrors(err.response.data.errors)
    }))
  }

  const onSubmitAdd = (data) => {
    setLoading(true)
    Comm.request({
      url: `${source}/create`,
      method: "post",
      data: data
    }).then(() => {
      loadData()
      setAddOpen(false)
      setLoading(false)
    }).catch(Catch401((err) => {
      setLoading(false)
      const field = Object.keys(err.response.data.errors)
      const type = ""
      const message = err.response.data.errors
      setAddErrors({field, type, message})
    }))
  }

  return (
    <>
      <ModalEditSetting editErrors={editErrors} loading={loading} title={title} item={selectedRow} open={editOpen} close={() => setEditOpen(false)} submit={onSubmitEdit} fields={fields} />
      <ModalAddSetting addErrors={addErrors} loading={loading} title={title} source="/settings/experience-points" open={addOpen} submit={onSubmitAdd} close={() => setAddOpen(false)} fields={fields} />
      <ConfirmActionModal />
      <Box sx={{ mb: 0, display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "space-between" }}>
        <Box sx={{ width: "80%" }}>
          {pageHeader}
        </Box>
        {
          canAdd && <Button variant="contained" onClick={() => setAddOpen(true)}>Add new</Button>
        }
      </Box>
      {
        !loading ? <Card sx={{ mt: 4 }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow sx={{backgroundColor: "rgb(245, 245, 247)"}}>
                  {fields.map((h, i) => (
                    <TableCell key={i}>{h.label}</TableCell>
                  ))}
                  {
                    canEdit || canDelete ? <TableCell align="center">Action</TableCell> : <></>
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => (
                  <TableRow key={row.id} sx={{ "&:last-of-type  td, &:last-of-type  th": { border: 0 } }}>
                    {columns.map((c, i) => (
                      <TableCell key={i}>{row[c]}</TableCell>
                    ))}
                    {
                      canEdit || canDelete ?
                        <TableCell align="center">
                          {
                            canEdit && <IconButton size="small" color="info" onClick={() => handleEditItem(row)}>
                              <SquareEditOutline />
                            </IconButton>
                          }
                          {
                            canDelete && <IconButton size="small" color="error" onClick={() => handleClickDeleteItem(row)}>
                              <TrashCanOutline />
                            </IconButton>
                          }
                        </TableCell>
                        : <></>
                    }
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
          : <Box sx={{ p: 4, display: "flex", justifyContent: "center", alignItems: "center" }}>
            <CircularProgress sx={{ ml: 3, mr: 3 }} size={40} />
          </Box>
      }
    </>
  )
}

export default DataTable
