import Comm from "services/Comm"
import Catch401 from "middlewares/Catch401"
import Uri from "services/Uri"

const AddUser = async function ({
  first_name,
  last_name,
  email,
  password,
  phone,
  date_of_birth,
  gender,
  address,
  role,
}) {
  let result = {}
  let error = {
    code: 0,
    type: "",
    data: {},
    message: "User couldn't be added",
  }
  await Comm.request({
    url: Uri.addUser(),
    method: "post",
    data: {
      first_name,
      last_name,
      email,
      password,
      phone,
      date_of_birth,
      gender,
      address,
      role,
    },
  })
    .then((res) => {
      result = res.data.data
    })
    .catch(
      Catch401((err) => {
        error.code = err.response.status
        error.message = err.response.data.message
        error.data = err.response.data.errors
        // console.log(err)
      })
    )
  if (error.code > 0) {
    return Promise.reject(error)
  }
  return Promise.resolve(result)
}
export default AddUser