import Req from "requests"
import Store from "store"
import { SharedModalErrorMessageShow, UserSetMetaData } from "store/actions"

const StoreChanges = async function (data) {
  const user_meta_data = Store.getState().Users.selected.meta_data
  let editArr = []
  let createArr = []
  // Split the submited array into new and existing array
  for (let u = 0; u < data.length; u++) {
    console.log(data[u])
    if (!data[u].id) {
      createArr.push(data[u])
    } else {
      let check = user_meta_data.find(m => m.id === data[u].id)
      let tempObj = JSON.parse(JSON.stringify(data[u]))
      if (check) {
        const org_options = JSON.parse(check.options)
        const new_options = data[u].options
        let is_same = (new_options.length === org_options.length) && new_options.every(function (element, index) {
          return element === org_options[index]
        })
        if (
          check.type !== data[u].type ||
          check.is_mandatory !== data[u].is_mandatory ||
          (!is_same) ||
          check.meta_title !== data[u].meta_title
        ) {
          if (data[u].type === "select" && data[u].options) {
            if (org_options !== null ) { // compare select options
              tempObj.options = org_options
            } else {
              tempObj.options = data[u].options
            }
          } else {
            tempObj.options = null
          }
          editArr = [...editArr, tempObj]
        }
      }
    }
  }
  // Create new Meta Data
  if (createArr.length > 0) {
    await Req.AddUserMeta({ data: createArr })
      .then((response) => {
        const usersMeta = [...user_meta_data, ...response]
        Store.dispatch(UserSetMetaData(usersMeta))
      })
      .catch((error) => {
        Store.dispatch(SharedModalErrorMessageShow(error.message))
      })
  }
  // Update existing Meta Data
  if (editArr.length > 0) {
    await Req.UpdateUserMeta({ data: editArr })
      .then((response) => {
        let new_data = []
        for (let x = 0; x < response.length; x++) {
          if (response[x].options) {
            response[x].options = JSON.stringify(response[x].options)
          }
          new_data = user_meta_data.map(user => user.id === response[x].id ? { ...user, ...response[x] } : user)
        }
        Store.dispatch(UserSetMetaData(new_data))
      })
      .catch((error) => {
        Store.dispatch(SharedModalErrorMessageShow(error.message))
      })
  }
}
export default StoreChanges
