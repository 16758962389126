import Comm from "services/Comm"
import Uri from "services/Uri"
import Catch401 from "middlewares/Catch401"

const GetStores = async function ({ page, sort, limit, filter, search_text, show_deleted }) {
  let result = {}
  let error = {
    code: 0,
    message: "",
  }
  let collection = []
  let total_records = 0
  let current_page = 1
  let stores

  stores = await Comm.request({
    url: Uri.stores(),
    method: "post",
    data: {
      page,
      sort,
      limit,
      filter,
      search_text,
      show_deleted
    },
  })
    .then((res) => {
      current_page = res.data?.meta?.current_page
      total_records = res.data?.meta?.total
      return res.data.data
    })
    .catch(
      Catch401((err) => {
        error.code = err.response.status
        error.message = err.response.data.message
      }, error)
    )
  if (error.code) {
    return Promise.reject(error)
  }

  collection = [...collection, ...stores]
  result.collection = collection
  result.total_records = total_records
  result.current_page = current_page

  return Promise.resolve(result)
}
export default GetStores