import { Box, Button, Grid, Typography } from "@mui/material"
import Card from "@mui/material/Card"
import ngn from "engine/Stores"
import { useContext, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import PageHeader from "components/UserArea/__Shared/PageHeader/"
import StoresColumns from "components/UserArea/Stores/__Shared/StoreColumns"
import TableHeaderToolbar from "components/UserArea/Stores/__Shared/TableHeaderToolbar"
import General from "configs/General"
import { DataGrid } from "@mui/x-data-grid"
import ConfirmActionModal from "components/__Shared/Modals/ConfirmActionModal"
import { AbilityContext } from "context/Can"

import ModalAddStore from "components/UserArea/Stores/ModalAddStore"

const Stores = () => {
  const [page, setPage] = useState(0)

  const ability = useContext(AbilityContext)

  // ** Engine
  ngn.collection.setCurrentPage = (page) => {
    setPage(page)
  }

  // ** Hooks
  const storesData = useSelector((state) => state.Stores)

  useEffect(() => {
    ngn.collection.fetchData()
  }, [])
  const CustomStoresToolBar = () => {
    return <TableHeaderToolbar ngn={ngn} />
  }
  return (
    <Grid container spacing={6}>
      <ConfirmActionModal />
      <ModalAddStore ngn={ngn.addStore} onEscape={ngn.addStore.onEscape} />
      <Grid item xs={12}>
        <Box
          sx={{
            mb: 8,
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ width: "50%" }}>
            <PageHeader
              title={<Typography variant="h5">Stores Management</Typography>}
              subtitle={
                <Typography variant="body2">
                  Manage Stores, Verify Store Ownership, etc.
                </Typography>
              }
            />
          </Box>
          {ability?.can("create", "users") ? (
            <Button variant="contained" onClick={() => ngn.addStore.showModal()}>
              Add Store
            </Button>
          ) : null}
        </Box>
        <Card>
          <DataGrid
            autoHeight
            checkboxSelection
            disableSelectionOnClick
            keepNonExistentRowsSelected
            rowsPerPageOptions={General.dataTablePageLimitOptions}
            rows={Object.values(storesData.collection)}
            columns={StoresColumns}
            rowCount={ngn.collection.total}
            pagination
            page={page}
            pageSize={ngn.collection.limit}
            sortingMode="server"
            paginationMode="server"
            onPageChange={ngn.collection.onChangePage}
            onSortModelChange={ngn.collection.onSort}
            filterMode="server"
            onFilterModelChange={ngn.collection.onFilter}
            onPageSizeChange={ngn.collection.onChangePageLimit}
            onSelectionModelChange={ngn.collection.onSelect}
            sx={{ "& .MuiDataGrid-columnHeaders": { borderRadius: 0 } }}
            components={{ Toolbar: CustomStoresToolBar }}
          />
        </Card>
      </Grid>
    </Grid>
  )
}
export default Stores
