import Catch401 from "middlewares/Catch401"
import Comm from "services/Comm"
import Uri from "services/Uri"

const DeleteUsers = async function ({ids}) {
  let result = {}
  let error = {
    code: 0,
    message: "Users Couldn't be Deleted",
  }

  await Comm.request({
    url: Uri.deleteMultipleUsers(),
    method: "post",
    data: {
      ids
    },
  })
    .then((res) => {
      console.log(res)
    })
    .catch(
      Catch401((err) => {
        error.code = err.response.status
        error.message = err.response.status.message
      }),
      error
    )

  if (error.code) {
    Promise.reject(error)
  }

  return Promise.resolve(result)
}

export default DeleteUsers
