import Catch401 from "middlewares/Catch401"
import Comm from "services/Comm"
import Uri from "services/Uri"

const MultipleUserUpdate = async function ({data, fields}) {

  let result = {}
  let error = {
    code: 0,
    type: "required",
    message: "Users couldn't be updated!"
  }

  await Comm.request({
    url: Uri.multiUserUpdate(),
    method: "post",
    data: {
      data,
      fields
    }
  }).then((res) => {
    // console.dir(res)
    result = res.data.data
  }).catch(Catch401((err) => {
    // console.dir(err)
    error.code = err.response.status
    error.message = err.response.data.message
  }))

  if (error.code > 0) {
    return Promise.reject(error)
  }

  return Promise.resolve(result)
}

export default MultipleUserUpdate
