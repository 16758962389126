import GlobalReset from "store/togglers/GlobalReset"
import UserPermissionsByCategory from "store/togglers/UserPermissionsByCategory"
import PermissionsByCategory from "store/togglers/PermissionsByCategory"
import RenameColumnImportData from "store/togglers/RenameColumnImportData"
import RevertRenameColumnImportData from  "store/togglers/RevertRenameColumnImportData"
import ImpersonateUserReset from "store/togglers/ImpersonateUserReset"
const Togglers = {
  GlobalReset,
  UserPermissionsByCategory,
  PermissionsByCategory,
  RenameColumnImportData,
  RevertRenameColumnImportData,
  ImpersonateUserReset
}

export default Togglers
