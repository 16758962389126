import { Navigate } from "react-router-dom"
import { useSelector } from "react-redux"
import Uri from "services/Uri"
import BlankLayout from "components/__Shared/BlankLayout"
import AllTrueLogo from "components/__Shared/AllTrueLogo"
import Form from "components/Authentication/ResetPassword/Form"

// ** MUI Components
import {
  Box,
  Typography
} from "@mui/material"
import MuiCard from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import { styled } from "@mui/material/styles"
import useQuery from "hooks/useQuery"
import ResetPasswordToken from "utils/ResetPasswordToken"

// ** Styled Components
const Card = styled(MuiCard)(({ theme }) => ({
  [theme.breakpoints.up("sm")]: { width: 450 }
}))

const PasswordResets = function () {

  const ngn = {
    modal: {},
    submit: {
      setDisabled: null,
      setEnabled: null,
    }
  }

  const Signed = useSelector(state => state.Auth.signed)

  const query = useQuery()

  const token = query.get("token")
  if (typeof token !== "string" || token.length === 0 || !ResetPasswordToken.isValid(token)) {
    return <Navigate to={Uri.home()} replace={true} />
  }

  return (
    Signed ?
      <Navigate to={Uri.u()} replace={true} />
      :
      <BlankLayout>
        <Box className="content-center">
          <Card sx={{ zIndex: 1 }}>
            <CardContent sx={{ p: theme => `${theme.spacing(13, 7, 6.5)} !important` }}>
              <Box sx={{ mb: 8, display: "flex", alignItems: "center", justifyContent: "center" }}>
                <AllTrueLogo />
              </Box>
              <Box sx={{ mb: 5 }}>
                <Typography variant="h5" sx={{ mb: 1.5, fontWeight: 600, letterSpacing: "0.18px", textAlign: "center" }}>
                  Create new password
                </Typography>
                <Typography variant="body2">
                  Securely access your account by creating a new log in password
                </Typography>
              </Box>
              <Form ngn={ngn} requestToken={token}/>
            </CardContent>
          </Card>
        </Box>
      </BlankLayout >
  )
}

export default PasswordResets
