import Store from "store"
import { SharedModalConfirmActionShow } from "store/actions"

const onConfirmDelete = function(selected) {
  this.collection.delete.selected = selected
  Store.dispatch(
    SharedModalConfirmActionShow(
      `Store No. ${selected.id} will be removed. Are you sure?`,
      this.collection.delete
    )
  )
}
export default onConfirmDelete