import { useState, useEffect } from "react"
import {
  Grid,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  CircularProgress,
} from "@mui/material"

// ** Third Party Imports
import * as yup from "yup"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { Input } from "components/__Shared/FormControls"
import { useMemo } from "react"
import Submit from "components/__Shared/Input/Submit"
import ngn from "engine/DataGridTable"

const ModalEditSetting = function ({
  title,
  loading,
  open = false,
  close,
  fields = [],
  item,
  submit,
  editErrors,
}) {
  //** Init default Schema for Validation
  const [validationSchema, setValidationSchema] = useState({})

  // ** React Form Hooks
  const {
    reset,
    control,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: useMemo(() => {
      return item
    }, [item]),
    mode: "onBlur",
    resolver: yupResolver(yup.object().shape(validationSchema)),
  })

  useEffect(() => {
    if (open) {
      let validation = {}
      let values = {}
      for (let f = 0; f < fields.length; f++) {
        validation[fields[f].value] = yup
          .string()
          .required(`${fields[f].label} field is required`)
        if (fields[f].type === "number") {
          validation[fields[f].value] = yup
            .number()
            .typeError(`${fields[f].label} field must be a number`)
            .required(`${fields[f].label} field is required`)
        }
        // default values
        values[fields[f].value] = item[fields[f].value].toString()
        if (
          values[fields[f].value] === null ||
          typeof values[fields[f].value] === "undefined"
        ) {
          values[fields[f].value] = ""
        }
      }
      reset(values)
      setValidationSchema(validation)

      // Set Errors
      if (editErrors) {
        Object.keys(editErrors).forEach((f) => {
          setError(f, { type: "manual", message: editErrors[f][0] })
        })
      }
    }
  }, [open, fields, item, reset, editErrors, setError])

  const onConfirm = function (data) {
    submit(data)
  }

  const onCancel = () => {
    reset()
    setValidationSchema({})
    close()
  }

  return (
    <Dialog
      scroll="body"
      fullWidth
      maxWidth="sm"
      onClose={onCancel}
      open={open}
      disableEscapeKeyDown
    >
      <DialogTitle sx={{ pt: 12, mx: "auto", textAlign: "center" }}>
        <Typography variant="h4" component="span" sx={{ mb: 2 }}>
          Update {title}
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ pb: 12 }}>
        {loading ? (
          <Box
            sx={{
              justifyContent: "center",
              alignContent: "center",
              display: "flex",
              mt: 3,
            }}
          >
            <CircularProgress sx={{ ml: 3, mr: 3 }} size={40} />
          </Box>
        ) : (
          <Box
            component="form"
            noValidate
            autoComplete="off"
            sx={{ mt: 4 }}
            onSubmit={handleSubmit(onConfirm)}
          >
            <Grid container spacing={5}>
              {fields.map((f, i) => {
                return (
                  <Grid item xs={12} sm={12} key={f.value}>
                    <Input
                      label={f.label}
                      name={f.value}
                      control={control}
                      error={errors[f.value]}
                    />
                  </Grid>
                )
              })}
            </Grid>
            <Box
              className="demo-space-x"
              sx={{
                "&>:last-child": { mr: 0 },
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mt: 3,
              }}
            >
              <Submit ngn={ngn.editItem.submitButton} title="Submit" />
              <Button
                size="large"
                variant="outlined"
                color="secondary"
                onClick={onCancel}
              >
                Discard
              </Button>
            </Box>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default ModalEditSetting
