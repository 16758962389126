import { Box, Button, IconButton, Typography } from "@mui/material"
import { SquareEditOutline, TrashCanOutline } from "mdi-material-ui"
import ngn from "engine/RolesAndPermission/Roles"

// permission based column
const RolesColumns = [
  {
    field: "id",
    headerName: "ID",
    flex: 0.1,
    renderCell: ({ row }) => {
      const { id } = row
      return (
        <Typography variant="body1" noWrap>
          {id}
        </Typography>
      )
    },
  },
  {
    field: "name",
    headerName: "Role",
    flex: 0.4,
    renderCell: ({ row }) => {
      const { name } = row
      return (
        <Typography variant="body1" noWrap>
          {name}
        </Typography>
      )
    },
  },
  {
    field: "no_permissions",
    headerName: "Permissions",
    sortable: false,
    flex: 0.4,
    renderCell: ({ row }) => {
      const total_permissions = row.permissions.length
      return (
        total_permissions > 0 ?
          <Button size="small" variant="outlined" onClick={() => {
            ngn.editRole.selected = row
            ngn.editRole.showModal()
          }}>
            See {row.permissions.length} permissions
          </Button>
          : <Button size="small" color="warning" variant="outlined" disabled={true}>
            No Permission
          </Button>
      )
    },
  },
  {
    flex: 0.1,
    minWidth: 115,
    sortable: false,
    field: "actions",
    headerName: "Actions",
    renderCell: ({ row }) => {
      const { id, name } = row
      return (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton color="info" onClick={() => {
            ngn.editRole.selected = row
            ngn.editRole.showModal()
          }}>
            <SquareEditOutline />
          </IconButton>
          <IconButton color="error" onClick={() => ngn.collection.onDeleteConfirm({ id, name })}>
            <TrashCanOutline  />
          </IconButton>
        </Box>
      )
    }
  }
]

export default RolesColumns