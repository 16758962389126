// ** React Imports
import { useState, useEffect, useContext } from "react"

// ** MUI Imports
import Card from "@mui/material/Card"
import Grid from "@mui/material/Grid"

import ngn from "engine/Users"

import DataTable from "../__Shared/DataTable"

// ** Store Imports
import { useSelector } from "react-redux"

import PageHeader from "components/UserArea/__Shared/PageHeader"

import TableColumns from "components/UserArea/Users/__Shared/Tables/TableColumns"

import TableHeaderToolbar from "components/UserArea/Users/__Shared/Tables/TableHeaderToolbar"
import { Box, Button, Typography } from "@mui/material"
import { AbilityContext } from "context/Can"
import ConfirmActionModal from "components/__Shared/Modals/ConfirmActionModal"

import ModalAddUser from "components/UserArea/Users/ModalAddUser"
import ModalEditUser from "components/UserArea/Users/ModalEditUser"
import ModalMultipleUserEdit from "components/UserArea/Users/ModalMultipleUserEdit"
import Memo from "services/Memo"
import { DataGridSetUserColumns } from "store/actions"
import Store from "store"

const Users = () => {
  // ** State
  const [page, setPage] = useState(0)

  // ** Engine
  ngn.collection.setCurrentPage = (page) => {
    setPage(page)
  }

  // ** Hooks
  const usersData = useSelector(state => state.Users)
  const settingDataGrid = useSelector((state) => state.DataGrid)
  const userColumns = settingDataGrid.users.columns
  const ability = useContext(AbilityContext)

  useEffect(() => {
    if (userColumns === null) {
      let visibleColumns = { __check__: true }
      TableColumns.forEach((col) => {
        let newPair = { [`${col.field}`]: true }
        visibleColumns = { ...visibleColumns, ...newPair }
      })
      Store.dispatch(DataGridSetUserColumns(visibleColumns))
      ngn.collection.visibleColumns = visibleColumns
    } else {
      ngn.collection.visibleColumns = userColumns
    }
  }, [userColumns])

  useEffect(() => {
    ngn.collection.fetchData()
  }, [])

  const CustomToolbar = () => {
    return (
      <TableHeaderToolbar ngn={ngn} />
    )
  }

  const onColumnVisiblityChange = (newModel) => {
    ngn.collection.onColumnVisiblityChange(newModel)
    Store.dispatch(DataGridSetUserColumns(newModel))
    Memo.tables.users.columns.store(JSON.stringify(newModel))
  }

  return (
    <Grid container spacing={6}>
      <ModalAddUser ngn={ngn.addUser} onEscape={ngn.addUser.onEscape} />
      <ModalEditUser ngn={ngn.editUser} onEscape={ngn.editUser.onEscape} />
      <ModalMultipleUserEdit ngn={ngn.multipleUserEdit} onEscape={ngn.multipleUserEdit.onEscape} />
      <ConfirmActionModal />
      <Grid item xs={12}>
        <Box sx={{ mb: 8, display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "space-between" }}>
          <Box sx={{ width: "50%" }}>
            <PageHeader
              title={<Typography variant="h5">User Management</Typography>}
              subtitle={
                <Typography variant="body2">
                  Find user"s account and their associate roles.
                </Typography>
              }
            />
          </Box>
          {ability?.can("create", "users") ? (
            <Button variant="contained" onClick={() => ngn.addUser.showModal()}>
              Add User
            </Button>
          ) : null}
        </Box>
        <Card>
          <DataTable
            autoHeight
            columnVisibilityModel={ngn.collection.visibleColumns}
            onColumnVisibilityModelChange={(newModel) => {
              onColumnVisiblityChange(newModel)
            }}
            rows={usersData.collection}
            rowCount={ngn.collection.total}
            loading={ngn.collection.fetching}
            pagination
            page={page}
            pageSize={ngn.collection.limit}
            sortingMode="server"
            paginationMode="server"
            onPageChange={ngn.collection.onChangePage}
            onSortModelChange={ngn.collection.onSort}
            filterMode="server"
            onFilterModelChange={ngn.collection.onFilter}
            onPageSizeChange={ngn.collection.onChangePageLimit}
            onSelectionModelChange={ngn.collection.onSelect}
            columns={TableColumns}
            components={{ Toolbar: CustomToolbar }}
          />
        </Card>
      </Grid>
    </Grid>
  )
}
Users.acl = {
  action: "read",
  subject: "users"
}
export default Users
