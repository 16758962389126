import Comm from "services/Comm"
import Uri from "services/Uri"
import Catch401 from "middlewares/Catch401"

const GetRollbackImports = async function ({
  page = 1,
  limit,
  sort = [],
  filter = [],
  search_text = "",
}) {
  let data = {}
  let error = {
    code: 0,
    message: "",
  }

  await Comm.request({
    url: Uri.rollback(),
    method: "post",
    data: {
      limit,
      sort,
      filter,
      search_text,
      page,
    },
  })
    .then((res) => {
      data.data = res.data.data
      data.total = res.data.meta.total
      return res.data
    })
    .catch(
      Catch401((err) => {
        error.code = err.response.status
        error.message = error.response.data.message
      }, error)
    )
  if (error.code) {
    return Promise.reject(error)
  }

  return Promise.resolve(data)
}

export default GetRollbackImports