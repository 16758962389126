import { Box, Button, Typography } from "@mui/material"
import { styled } from "@mui/material/styles"
import BlankLayout from "components/__Shared/BlankLayout"
import { Link } from "react-router-dom"
import Uri from "services/Uri"

// ** Styled Components
const BoxWrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    width: "90vw"
  }
}))

const NotAuthorized = function () {
  return (
    <BlankLayout>
      <Box className="content-center">
        <Box sx={{ p: 5, display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center" }}>
          <BoxWrapper>
            <Typography variant="h1" sx={{ mb: 2.5 }}>
              401
            </Typography>
            <Typography variant="h5" sx={{ mb: 2.5, fontSize: "1.5rem !important" }}>
              You are not authorized! 🔐
            </Typography>
            <Typography variant="body2">You don&prime;t have permission to access this page.</Typography>
          </BoxWrapper>
          <Button to={Uri.uDashboard()} component={Link} variant="contained" sx={{ mt: 5 }}>
            Back to Home
          </Button>
        </Box>
      </Box>
    </BlankLayout>
  )
}

export default NotAuthorized
