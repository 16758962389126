// ** React Imports
import { useState, Fragment } from "react"


// ** MUI Imports
import Box from "@mui/material/Box"
import Menu from "@mui/material/Menu"
import Badge from "@mui/material/Badge"
import Avatar from "@mui/material/Avatar"
import Divider from "@mui/material/Divider"
import MenuItem from "@mui/material/MenuItem"
import { styled } from "@mui/material/styles"
import Typography from "@mui/material/Typography"

// ** Icons Imports
import LogoutVariant from "mdi-material-ui/LogoutVariant"

// ** Context
import { useNavigate } from "react-router-dom"
import Uri from "services/Uri"
import { useSelector } from "react-redux"

// ** Styled Components
const BadgeContentSpan = styled("span")(({ theme }) => ({
  width: 8,
  height: 8,
  borderRadius: "50%",
  backgroundColor: theme.palette.success.main,
  boxShadow: `0 0 0 2px ${theme.palette.background.paper}`
}))

const UserDropdown = props => {
  // ** Props
  const { settings } = props

  // ** States
  const [anchorEl, setAnchorEl] = useState(null)

  const navigate = useNavigate()

  // ** Vars
  const { direction } = settings

  const handleDropdownOpen = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleDropdownClose = url => {
    if (url) {
      navigate(url)
    }
    setAnchorEl(null)
  }

  const user = useSelector(store => store.User)

  return (
    <Fragment>
      <Avatar
        alt={`${user.first_name} ${user.last_name}`}
        onClick={handleDropdownOpen}
        sx={{ width: 40, height: 40 }}
        src="/images/alltrue-default-avatar.svg"
      />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => handleDropdownClose()}
        sx={{ "& .MuiMenu-paper": { width: 230, mt: 4 } }}
        anchorOrigin={{ vertical: "bottom", horizontal: direction === "ltr" ? "right" : "left" }}
        transformOrigin={{ vertical: "top", horizontal: direction === "ltr" ? "right" : "left" }}
      >
        <Box sx={{ pt: 2, pb: 3, px: 4 }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Badge
              overlap="circular"
              badgeContent={<BadgeContentSpan />}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right"
              }}
            >
              <Avatar alt={`${user.first_name} ${user.last_name}`} src={user.avatar} sx={{ width: "2.5rem", height: "2.5rem" }} />
            </Badge>
            <Box sx={{ display: "flex", ml: 3, alignItems: "flex-start", flexDirection: "column" }}>
              <Typography sx={{ fontWeight: 600 }}>{`${user.first_name} ${user.last_name}`}</Typography>
              <Typography variant="body2" sx={{ fontSize: "0.8rem", color: "text.disabled" }}>
                Administrator
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider />
        <MenuItem sx={{ py: 2 }} onClick={() => handleDropdownClose(Uri.signOut())}>
          <LogoutVariant sx={{ mr: 2, fontSize: "1.375rem", color: "text.secondary" }} />
          Logout
        </MenuItem>
      </Menu>
    </Fragment>
  )
}

export default UserDropdown
